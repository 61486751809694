import React from "react";
import Footer from "../BackEnd/components/Footer/Footer";
import Dashboard from "../BackEnd/components/Header-Components/Dashboard";
import Integrations from "../BackEnd/components/Header-Components/Integrations";
import Management from "../BackEnd/components/Header-Components/Management";
import Payments from "../BackEnd/components/Header-Components/Payments";
import Reports from "../BackEnd/components/Header-Components/Reports";
import Header from "../BackEnd/components/Header/Header";

import { Route, Routes } from "react-router-dom";
import CustomerProgramDashboard from "../BackEnd/Customer-part/Customer-Program/Customer-Program-dashboard/CustomerProgramDashboard";
import CustomerProgramAdd from "../BackEnd/Customer-part/Customer-Program/Customer-program-add/CustomerProgramAdd";
import CustomerReportDashboard from "../BackEnd/Customer-part/Customer-Reports/CustomerReportsDashboard";
import CustomerSubmissions from "../BackEnd/Customer-part/Customer-Submissions/CustomerSubmissions";
import CustomerUserAdd from "../BackEnd/Customer-part/Customer-Users/CustomerUserAdd";
import CustomerUserEdit from "../BackEnd/Customer-part/Customer-Users/CustomerUserEdit";
import CustomerUsers from "../BackEnd/Customer-part/Customer-Users/CustomerUsers";
import CustomerViewUser from "../BackEnd/Customer-part/Customer-Users/CustomerViewUser";
import CustomerDashboard from "../BackEnd/Customer-part/Dashboard/customerDashboard/CustomerDashboard";
import CustomerAddMoney from "../BackEnd/Customer-part/Payments/CustomerAddMoney";
import CustomerKyc from "../BackEnd/Customer-part/Payments/CustomerKyc";
import CustomerTransaction from "../BackEnd/Customer-part/Payments/CustomerTransaction";
import CustomerWalletDashboard from "../BackEnd/Customer-part/Payments/CustomerWalletDashboard";
import CustomerResetpassword from "../BackEnd/Customer-part/resetpassword/CustomerResetpassword";
import CustomerBasic from "../BackEnd/Customer-part/signin/CustomerBasic";
import AdminMainProgramTab from "../BackEnd/ProgramTab/AdminMainProgramTab";
import AdminProgramDetails from "../BackEnd/ProgramTab/AdminProgramDetails";
import AdminProgramInsights from "../BackEnd/ProgramTab/AdminProgramInsights";
import AdminProgramReports from "../BackEnd/ProgramTab/AdminProgramReports";
import AdminProgramResearcher from "../BackEnd/ProgramTab/AdminProgramResearcher";
import AdminProgramRewards from "../BackEnd/ProgramTab/AdminProgramRewards";
import AdminProgramSettings from "../BackEnd/ProgramTab/AdminProgramSettings";
import AdminProgramSubmission from "../BackEnd/ProgramTab/AdminProgramSubmission";
import AdminProgramSummary from "../BackEnd/ProgramTab/AdminProgramSummary";
import Addskills from "../BackEnd/common/Addskills";
import Basicinfo from "../BackEnd/common/Basicinfo";
import Paymentinfo from "../BackEnd/common/Paymentinfo";
import ProgramTracking from "../BackEnd/common/ProgramTracking";
import Social from "../BackEnd/common/Social";
import ViewSubmission from "../BackEnd/dashboard/submission/ViewSubmission";
import Resetpassword from "../BackEnd/resetpassword/Resetpassword";
import Userlockscreen from "../BackEnd/userlockscreen/Userlockscreen";

import RazorpayPayment from "../BackEnd/Customer-part/Payments/RazorpayPayment";
import CustomerVTxTaxonomy from "../BackEnd/Customer-part/master-module/VTxTaxonomy/CustomerVTxTaxonomy";
import KycProfile from "../BackEnd/common/KycProfile";
import ProgramReport from "../BackEnd/common/ReportsTypes/ProgramReport";
import SubmisisonReport from "../BackEnd/common/ReportsTypes/SubmissionReport";
import Jira from "../BackEnd/common/integrations/Jira/JIra";
import JiraProject from "../BackEnd/common/integrations/Jira/JIraProjects";
import Slack from "../BackEnd/common/integrations/Slack/Slack";
import AddSubCustomer from "../BackEnd/common/supportCustomer/AddSubCustomer";
import EditSubCustomer from "../BackEnd/common/supportCustomer/EditSubCustomer";
import SubCustomerDashboard from "../BackEnd/common/supportCustomer/SubCustomerDashboard";
import ViewSubCustomer from "../BackEnd/common/supportCustomer/ViewSubCustomer";
import MasterModule from "../BackEnd/components/Header-Components/MasterModule";
import Notifications from "../BackEnd/components/Notifications";
import EditProgram from "../BackEnd/dashboard/program/EditProgram";

const CustomerLayout = (props) => {
  return (
    <>
      <Header returnhome="/customer/home">
        <Dashboard Dashboardlink="/customer/home" />
        <Management usertype="customer" />
        <Payments usertype="customer" />
        <Integrations usertype="customer" />
        <Reports usertype="customer" />
        <MasterModule usertype="customer" />
      </Header>
      <div className="bg-gray-100">
        <main className="position-relative mt-0" style={{ height: "auto" }}>
          <Routes>
            <Route path="/notifications" element={<Notifications />} />
            <Route path="/" element={<CustomerBasic />} />
            <Route path="/home" element={<CustomerDashboard />} />
            <Route path="/lockscreen" element={<Userlockscreen />} />
            <Route path="/resetpassword" element={<Resetpassword />} />
            <Route
              path="/program/dashboard"
              element={<CustomerProgramDashboard />}
            />
            <Route path="/program/edit/:id" element={<EditProgram />} />
            <Route path="/program/add" element={<CustomerProgramAdd />} />
            <Route path="/submissions" element={<CustomerSubmissions />} />
            <Route path="/submissions/view/:id" element={<ViewSubmission />} />

            <Route path="/users" element={<CustomerUsers />} />
            <Route path="/user/add" element={<CustomerUserAdd />} />
            <Route
              exact
              path="/user/edit/:userId/:type/"
              element={<CustomerUserEdit />}
            >
              <Route path="basicinfo" element={<Basicinfo />} />
              <Route path="addskill" element={<Addskills />} />
              <Route path="social" element={<Social />} />
              <Route path="paymentinformation" element={<Paymentinfo />} />
              <Route path="kyc" element={<KycProfile />} />
            </Route>

            <Route path="/program/:id/" element={<AdminMainProgramTab />}>
              <Route path="summary" element={<AdminProgramSummary />} />
              <Route path="details" element={<AdminProgramDetails />} />
              <Route path="researcher" element={<AdminProgramResearcher />} />
              <Route path="submission" element={<AdminProgramSubmission />} />
              <Route path="rewards" element={<AdminProgramRewards />} />
              <Route path="insights" element={<AdminProgramInsights />} />
              <Route path="reports" element={<AdminProgramReports />} />
              <Route path="settings" element={<AdminProgramSettings />} />
              <Route path="tracking" element={<ProgramTracking />} />
            </Route>
            <Route path="/user/view/:userId" element={<CustomerViewUser />} />
            <Route
              path="/user/view/:userId/:type"
              element={<CustomerViewUser />}
            />
            <Route
              path="/reports-dashboard"
              element={<CustomerReportDashboard />}
            />
            <Route
              path="/reports-dashboard/program/:id"
              element={<ProgramReport />}
            />
            <Route
              path="/reports-dashboard/submission/:id"
              element={<SubmisisonReport />}
            />
            <Route
              path="/payment/wallet"
              element={<CustomerWalletDashboard />}
            />
            <Route path="/payment/wallet/add" element={<CustomerAddMoney />} />
            <Route
              path="/payment/transaction"
              element={<CustomerTransaction />}
            />
            <Route path="/kyc" element={<CustomerKyc />} />
            <Route path="/reset/password" element={<CustomerResetpassword />} />
            <Route path="/integrations/slack" element={<Slack />} />
            <Route path="/integrations/jira" element={<Jira />} />
            <Route path="/integrations/jira/:cloudName" element={<JiraProject />} />

            <Route path="/supportcustomers" element={<SubCustomerDashboard />} />
            <Route path="/supportcustomers/add" element={<AddSubCustomer />} />
            <Route path="/supportcustomers/view/:userId" element={<ViewSubCustomer />} />
            <Route path="/supportcustomers/edit/:userId" element={<EditSubCustomer />} >
              <Route path="basicinfo" element={<Basicinfo />} />
            </Route>

            <Route path="/razorpay/payment" element={<RazorpayPayment />} />
            <Route path="/vtxtaxonomy/dashboard" element={<CustomerVTxTaxonomy />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </>
  );
};

export default React.memo(CustomerLayout);
