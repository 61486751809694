import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";

import Breadcrumb from "../../components/breadcrumb/Breadcrumb";

import "./SubmissionDashboard.css";
import rescuerLogo from "./img/team-3.jpg";
import useHttp from "../../Hooks/useHttp";
import { toast } from "react-toastify";
import CountSkeletonLoader from "../../components/SkeletonLoader/CountSkeletonLoader";
import {
  lineOverFlowDot,
  flattenedObject,
} from "../../../utils/helperFunctions";
import submissionStatusData from "./SubmissionStatusData.json";
import CircularLoader from "../../CircularLoader/CircularLoader";
import RewardModal from "../../common/modal/RewardModal";
import CommonTable from "../../common/Table/CommonTable";
import AppContext from "../../Context/AppContext";
import HTTPService from "../../../utils/axios";
import moment from "moment";
import { customToast } from "../../../App";
const tableColumn = [
  { label: "submission id" },
  { label: "date" },
  { label: "title" },
  { label: "metrics stats" },
  { label: "researcher details" },
  { label: "payment details" },
  { label: "payment status", align: "center" },
  { label: "processed by", align: "center" },
  { label: "status" },
  { label: "action", align: "start" },
];

const filterColumn = [
  {
    name: "Priority",
    value: "priority",
    filters: ["P1", "P2", "P3", "P4", "P5"],
  },
  {
    name: "Severity",
    value: "severity",
    filters: ["Informational", "Low", "Moderate", "Critical", "Severe"],
  },
  {
    name: "Submission Status",
    value: "submission_status",
    filters: [
      { value: "review", label: "Review" },
      { value: "resolved", label: "Resolved" },
      { value: "unresolved", label: "Unresolved" },
      { value: "rejected", label: "Rejected" },
    ],
  },
];

export default function CustomerSubmissions() {
  const { setLoading, loading, setCircularLoading } = useContext(AppContext);
  const [paymentModal, setPaymentModal] = useState(false);
  const [selectedSubmisison, setSelectedSubmission] = useState();
  const API = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem("token");
  const { GetRequest, DeleteRequest } = useHttp();
  const [submissionData, setSubmissionData] = useState();
  const [programDetails, setProgramDetails] = useState();
  const uid = localStorage.getItem("userid");
  const [changeStatusModal, setChangeStatusModal] = useState(false);
  const [changeStatusVal, setChangeStatusVal] = useState();
  const [filteredDataArray, setFilteredDataArray] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [searchPlaceHolder, setSearchPlaceHolder] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [analytics, setAnalytics] = useState();
  const { invokeApi } = HTTPService();
  const [filterType, setFilterType] = useState();
  const [filterValue, setFilterValue] = useState();

  const fetchProgramDetails = async () => {
    const { data, error } = await GetRequest(
      API + "programs/",
      {},
      {
        Authorization: "Bearer  " + token,
        "Content-Type": "multipart/form-data",
      }
    );
    if (error) {
      customToast.error("Something went wrong!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      console.log(error);
    }
    if (data) {
      setProgramDetails(data);
    }
  };

  const fetchSubmissionData = async (noLoading) => {
    if (!noLoading) setLoading(true);
    let url = `${API}submissions/?page=${currentPage}&page_size=25`;
    if (searchQuery && searchQuery.length > 0) {
      url = url + `&${searchValue}=${searchQuery}`;
    }
    if (filterType && filterValue && filterValue.length > 0) {
      url = url + `&${filterType}=${filterValue}`;
    }
    const { data, error } = await GetRequest(
      url,
      {},
      {
        Authorization: "Bearer  " + token,
        "Content-Type": "multipart/form-data",
      }
    );
    if (error) {
      customToast.error("Something went wrong!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      console.log(error);
      setLoading(false);
    }
    if (data) {
      setSubmissionData(data);
      setTotalPages(data.total_pages);
      fetchProgramDetails();
    }
  };

  useEffect(() => {
    // if (searchQuery && searchQuery.length > 0) {
    fetchSubmissionData();
    // }
  }, [searchQuery, currentPage, filterValue]);

  const getFilterData = () => {
    let newDataArray = [...submissionData?.results];
    // programDetails?.results?.forEach((pgmItm) => {
    //   if (pgmItm.customer === uid && submissionData) {
    //     submissionData?.results?.forEach((item, index) => {
    //       if (
    //         pgmItm.id === item.program_id &&
    //         item.submission_status !== "new" &&
    //         item.submission_status !== "rejected"
    //       ) {
    //         // Push the filtered item into the array
    //         newDataArray.push(item);
    //       }
    //     });
    //   }
    // });
    newDataArray.sort((a, b) => moment(b.created_at) - moment(a.created_at))
    setFilteredDataArray(newDataArray);
    setLoading(false);
  };

  useEffect(() => {
    if (submissionData?.results)
      getFilterData();
  }, [submissionData]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    const timeZone = "IST";

    return (
      <>
        <span>
          {`${year}-${month}-${day}`}
          <div>{` ${hours}:${minutes}:${seconds} ${timeZone}`}</div>
        </span>
      </>
    );
  };

  const Breadcrumbname = {
    home: "/customer/home",
    Management: "",
    Submissions: "",
    Dashboard: "",
  };

  const handleStatus = async (data) => {
    // fetchStatus(id);
    setChangeStatusModal(true);
    setChangeStatusVal(data.submission_status);
    setSelectedSubmission(data);
  };
  const setStatus = async () => {
    setChangeStatusModal(false);
    const param = {
      submission_status: changeStatusVal.toLowerCase(),
    };
    invokeApi({
      method: "PATCH",
      url: `submissions/${selectedSubmisison.id}/`,
      data: param,
    })
      .then((res) => {
        fetchSubmissionData();
        customToast.success("status updated", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      })
      .catch((error) => {
        customToast.error("Something went wrong!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      });
  };
  useEffect(() => {
    fetchSubmissionData();
    fetchAnalytics();
  }, []);

  const fetchAnalytics = async () => {
    invokeApi({
      method: "GET",
      url: `submissions/status-count/`,
    })
      .then((res) => {
        setAnalytics(res);
      })
      .catch((error) => { });
  };
  return (
    <>
      <div className="container-fluid py-4 pb-0">
        <div className="row">
          <div className="col-lg-6 ps-3">
            <Breadcrumb names={Breadcrumbname} />
          </div>
        </div>

        <div className="row">
          {loading ? (
            <CountSkeletonLoader showState={2} />
          ) : (
            <div className="col-lg-12 col-12">
              <div className="row d-flex">
                <div className=" mt-4" style={{ flex: 1 }}>
                  <div className="card">
                    <span className="mask bg-gradient-dark opacity-9 border-radius-xl"></span>
                    <div className="card-body p-3 position-relative">
                      <div className="row">
                        <div className="col-7 text-start">
                          <div className="icon icon-shape bg-white shadow text-center border-radius-md">
                            <i
                              className="ni ni-circle-08 text-dark text-gradient text-lg opacity-10"
                              aria-hidden="true"
                            ></i>
                          </div>
                          <h5 className="text-white font-weight-bolder mb-0 mt-3">
                            {analytics?.total_submissions_status?.new ?? 0}
                          </h5>
                          <span className="cardHeader text-white text-sm">
                            New
                          </span>
                        </div>
                        <div className="col-5">
                          <div className="dropdown text-end mb-5"></div>
                          <h5 className="text-end text-white pt-3 mb-0 mt-2">
                            {analytics?.today_submissions_status_count?.new ??
                              0}
                          </h5>
                          <p className="text-white text-sm text-end font-weight-bolder mt-auto mb-0">
                            Today's
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className=" mt-4" style={{ flex: 1 }}>
                  <div className="card">
                    <span className="mask bg-gradient-dark opacity-9 border-radius-xl"></span>
                    <div className="card-body p-3 position-relative">
                      <div className="row">
                        <div className="col-7 text-start">
                          <div className="icon icon-shape bg-white shadow text-center border-radius-md">
                            <i
                              className="ni ni-circle-08 text-dark text-gradient text-lg opacity-10"
                              aria-hidden="true"
                            ></i>
                          </div>
                          <h5 className="text-white font-weight-bolder mb-0 mt-3">
                            {analytics?.total_submissions_status?.triaged ?? 0}
                          </h5>
                          <span className="cardHeader text-white text-sm">
                            Triaged
                          </span>
                        </div>
                        <div className="col-5">
                          <div className="dropdown text-end mb-5"></div>
                          <h5 className="text-end text-white pt-3 mb-0 mt-2">
                            {analytics?.today_submissions_status_count
                              ?.triaged ?? 0}
                          </h5>
                          <p className="text-white text-sm text-end font-weight-bolder mt-auto mb-0">
                            Today's
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className=" mt-4" style={{ flex: 1 }}>
                  <div className="card">
                    <span className="mask bg-gradient-dark opacity-9 border-radius-xl"></span>
                    <div className="card-body p-3 position-relative">
                      <div className="row">
                        <div className="col-7 text-start">
                          <div className="icon icon-shape bg-white shadow text-center border-radius-md">
                            <i
                              className="ni ni-circle-08 text-dark text-gradient text-lg opacity-10"
                              aria-hidden="true"
                            ></i>
                          </div>
                          <h5 className="text-white font-weight-bolder mb-0 mt-3">
                            {analytics?.total_submissions_status?.review ?? 0}
                          </h5>
                          <span className="cardHeader text-white text-sm">
                            Review
                          </span>
                        </div>
                        <div className="col-5">
                          <div className="dropdown text-end mb-5"></div>
                          <h5 className="text-end text-white pt-3 mb-0 mt-2">
                            {analytics?.today_submissions_status_count
                              ?.review ?? 0}
                          </h5>
                          <p className="text-white text-sm text-end font-weight-bolder mt-auto mb-0">
                            Today's
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className=" mt-4 " style={{ flex: 1 }}>
                  <div className="card">
                    <span className="mask bg-gradient-dark opacity-9 border-radius-xl"></span>
                    <div className="card-body p-3 position-relative">
                      <div className="row">
                        <div className="col-7 text-start">
                          <div className="icon icon-shape bg-white shadow text-center border-radius-md">
                            <i
                              className="ni ni-circle-08 text-dark text-gradient text-lg opacity-10"
                              aria-hidden="true"
                            ></i>
                          </div>
                          <h5 className="text-white font-weight-bolder mb-0 mt-3">
                            {analytics?.total_submissions_status?.unresolved ??
                              0}
                          </h5>
                          <span className="cardHeader text-white text-sm">
                            Unresolved
                          </span>
                        </div>
                        <div className="col-5">
                          <div className="dropdown text-end mb-5"></div>
                          <h5 className="text-end text-white pt-3 mb-0 mt-2">
                            {analytics?.today_submissions_status_count
                              ?.unresolved ?? 0}
                          </h5>
                          <p className="text-white text-sm text-end font-weight-bolder mt-auto mb-0">
                            Today's
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className=" mt-4" style={{ flex: 1 }}>
                  <div className="card">
                    <span className="mask bg-gradient-dark opacity-9 border-radius-xl"></span>
                    <div className="card-body p-3 position-relative">
                      <div className="row">
                        <div className="col-7 text-start">
                          <div className="icon icon-shape bg-white shadow text-center border-radius-md">
                            <i
                              className="ni ni-circle-08 text-dark text-gradient text-lg opacity-10"
                              aria-hidden="true"
                            ></i>
                          </div>
                          <h5 className="text-white font-weight-bolder mb-0 mt-3">
                            {analytics?.total_submissions_status?.resolved ?? 0}
                          </h5>
                          <span className="cardHeader text-white text-sm">
                            Resolved
                          </span>
                        </div>
                        <div className="col-5">
                          <div className="dropdown text-end mb-5"></div>
                          <h5 className="text-end text-white pt-3 mb-0 mt-2">
                            {analytics?.today_submissions_status_count
                              ?.resolved ?? 0}
                          </h5>
                          <p className="text-white text-sm text-end font-weight-bolder mt-auto mb-0">
                            Today's
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className=" mt-4" style={{ flex: 1 }}>
                  <div className="card">
                    <span className="mask bg-gradient-dark opacity-9 border-radius-xl"></span>
                    <div className="card-body p-3 position-relative">
                      <div className="row">
                        <div className="col-7 text-start">
                          <div className="icon icon-shape bg-white shadow text-center border-radius-md">
                            <i
                              className="ni ni-circle-08 text-dark text-gradient text-lg opacity-10"
                              aria-hidden="true"
                            ></i>
                          </div>
                          <h5 className="text-white font-weight-bolder mb-0 mt-3">
                            {analytics?.total_submissions_status?.rejected ?? 0}
                          </h5>
                          <span className="cardHeader text-white text-sm">
                            Rejected
                          </span>
                        </div>
                        <div className="col-5">
                          <div className="dropdown text-end mb-5"></div>
                          <h5 className="text-end text-white pt-3 mb-0 mt-2">
                            {analytics?.today_submissions_status_count
                              ?.rejected ?? 0}
                          </h5>
                          <p className="text-white text-sm text-end font-weight-bolder mt-auto mb-0">
                            Today's
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="row my-4">
          <div className="col-12">
            <CommonTable
              column={tableColumn}
              data={
                (filteredDataArray !== null ||
                  filteredDataArray.length !== 0) &&
                filteredDataArray
              }
              totalPages={totalPages ?? 1}
              filterList={filterColumn}
              filterType={filterType}
              setFilterType={setFilterType}
              filterValue={filterValue}
              setFilterValue={setFilterValue}
              searchBar={true}
              itemPerPg={25}
              searchBy={[
                { name: "Submission Title", value: "submission_title" },
                { name: "Submission ID", value: "submissions_id" },
              ]}
              setTable={1}
              card={7}
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              searchPlaceHolder={searchPlaceHolder}
              setSearchPlaceHolder={setSearchPlaceHolder}
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              totalData={submissionData?.total_records}

            >
              {filteredDataArray &&
                filteredDataArray?.map((item, index) => (
                  <tr key={index}>
                    <td>
                      <Link
                        className="gotoView"
                        style={{ fontSize: "14px" }}
                        to={`/customer/submissions/view/${item.id}`}
                      >
                        {item.submissions_id}
                      </Link>

                      {/* <p
											className="  mb-0 px-2"
											style={{
												color: "#272727",
												fontSize: "12px",
											}}
										>
											{item.submissions_id}
										</p> */}
                    </td>
                    <td>
                      <div style={{ width: "85px" }}>
                        <p
                          className="  mb-0 px-2"
                          style={{
                            color: "#272727",
                            fontSize: "13px",
                          }}
                        >
                          {formatDate(item.created_at)}
                        </p>
                      </div>
                    </td>
                    <td className="text-start">
                      <div style={{ width: "200px" }} className="text-wrap">
                        <p
                          style={{
                            color: "#3F48CC",
                            fontWeight: "600",
                            marginBottom: "5px",
                            // textWrap: "nowrap",
                            fontSize: "14px",
                          }}
                        >
                          {lineOverFlowDot(item.submission_title, 40)}
                        </p>

                        {programDetails?.results.map(
                          (pgmItm, index) =>
                            pgmItm.id === item.program_id && (
                              <p
                                key={index}
                                style={{
                                  color: "#000",
                                  fontWeight: "600",
                                  marginBottom: "5px",
                                  // textWrap: "nowrap",
                                  fontSize: "14px",
                                }}
                              >
                                {lineOverFlowDot(pgmItm.program_title, 30)}
                              </p>
                            )
                        )}

                        <p className="text-secondary text-xs">
                          {lineOverFlowDot(item.detail_description, 50)}
                        </p>
                      </div>
                    </td>
                    <td>
                      <p className="text-sm  mb-0 metricStatElement">
                        <div className="row d-flex flex-column align-items-center">
                          <div className=" d-flex align-items-center">
                            <p className="col-7 mb-0  text-start">Priority</p>
                            <span>:</span>
                            <span style={{ marginLeft: "4px" }}>
                              {item.priority}
                            </span>
                          </div>

                          <div className="d-flex align-items-center">
                            <p className="col-7 mb-0  text-start">Severity</p>
                            <span>:</span>
                            <span style={{ marginLeft: "4px" }}>
                              {item.severity}
                            </span>
                          </div>
                          <div className="d-flex align-items-center">
                            <p className="col-7 mb-0  text-start">VTx</p>
                            <span>:</span>
                            <span style={{ marginLeft: "4px" }}>
                              {item.vtx}
                            </span>
                          </div>
                          {item.cvss_score && (
                            <div className="d-flex align-items-center">
                              <p className="col-7 mb-0  text-start">CVSS</p>
                              <span>:</span>
                              <span style={{ marginLeft: "4px" }}>
                                {item.cvss_score}
                              </span>
                            </div>
                          )}
                        </div>
                      </p>
                    </td>
                    <td>
                      <div className="d-flex flex-column align-items-start">
                        <p className="mb-0" style={{ fontSize: "12px" }}>
                          {item?.user_details?.user_id}
                        </p>
                        <p
                          className="mb-0"
                          style={{
                            color: "#656272",
                            fontSize: "12px",
                          }}
                        >
                          {item?.user_details?.first_name}{" "}
                          {item?.user_details?.last_name}
                        </p>
                      </div>
                    </td>
                    <td className="  text-sm">
                      {item.transaction_details ? (
                        <p className="text-sm  mb-0 metricStatElement">
                          <div className="row d-flex flex-column align-items-center">
                            <div className=" d-flex align-items-center">
                              <p className="col-5 mb-0  text-start">Amount</p>
                              <span>:</span>
                              <span style={{ marginLeft: "4px" }}>
                                ${item?.transaction_details?.amount}
                              </span>
                            </div>

                            <div className="d-flex align-items-center">
                              <p className="col-5 mb-0  text-start">Fees</p>
                              <span>:</span>
                              <span style={{ marginLeft: "4px" }}>
                                ${item?.transaction_details?.processing_fees}
                              </span>
                            </div>
                            <div className="d-flex align-items-center">
                              <p className="col-5 mb-0  text-start">Txn ID</p>
                              <span>:</span>
                              <span style={{ marginLeft: "4px" }}>
                                {item?.transaction_details?.transactions_id}
                              </span>
                            </div>
                            <div className="d-flex align-items-center">
                              <p className="col-5 mb-0  text-start">Date</p>
                              <span>:</span>
                              <span
                                style={{
                                  marginLeft: "4px",
                                  textWrap: "wrap",
                                  textAlign: "left",
                                }}
                              >
                                {formatDate(
                                  item?.transaction_details?.created_at,
                                  1
                                )}
                              </span>
                            </div>
                          </div>
                        </p>
                      ) : (
                        "No Transaction"
                      )}
                    </td>
                    <td className="align-middle text-center text-uppercase">
                      {item.submission_status === "resolved" &&
                        item.transaction_details?.status != "success" && (
                          <div className={`UnPaidBtn cursor-pointer`}>
                            <span>Unpaid</span>
                          </div>
                        )}
                      {item.submission_status === "resolved" &&
                        item.transaction_details?.status == "success" && (
                          <div className={`statusAccepted cursor-pointer`}>
                            <span>Paid</span>
                          </div>
                        )}
                      {item.submission_status !== "resolved" &&
                        item.transaction_details?.status !== "success" && (
                          <div className={` cursor-pointer`}>
                            <span>-</span>
                          </div>
                        )}
                    </td>
                    <td className="align-middle text-center">
                      <div
                        className=""
                        style={{ color: "rgb(101, 98, 114)", fontSize: "12px" }}
                      >
                        {item.assignee ? (
                          <>
                            {" "}
                            <p style={{ fontSize: "12px", margin: 0 }}>
                              {item?.assign_details?.user_id}
                            </p>
                            {item?.assign_details?.first_name}{" "}
                            {item?.assign_details?.last_name}{" "}
                          </>
                        ) : (
                          <>No-Assignee</>
                        )}
                      </div>
                    </td>
                    <td className="align-middle text-center text-uppercase">
                      {submissionStatusData &&
                        submissionStatusData.map(
                          (sts, index) =>
                            item.submission_status === sts.status && (
                              <div
                                key={index}
                                className={`${sts.classname} cursor-pointer`}
                                onClick={() => {
                                  if (
                                    !["new", "triaged", "resolved"].includes(
                                      item.submission_status
                                    )
                                  )
                                    handleStatus(item);
                                }}
                              >
                                <span>{item.submission_status}</span>
                              </div>
                            )
                        )}
                    </td>
                    <td>
                      <div className="d-flex">
                        <Link
                          to={`/customer/submissions/view/${item.id}`}
                          className="btn btn-sm px-3 mt-2"
                        >
                          &#128065;
                        </Link>
                        <button
                          type="button"
                          className="btn btn-sm px-3 mx-2 dropdown-toggle dropdown-toggle-split"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <i
                            className="fa fa-ellipsis-v text-xs"
                            aria-hidden="true"
                          ></i>
                        </button>
                        <ul className="dropdown-menu shadow">
                          <li>
                            <li>
                              <Link
                                className="dropdown-item"
                                to={`/customer/program/${item.program_id}/submission`}
                              >
                                View comment
                              </Link>
                            </li>
                            <button
                              className="dropdown-item"
                              onClick={() => {
                                setPaymentModal(true);
                                setSelectedSubmission(item);
                              }}
                            >
                              Add Note
                            </button>
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                ))}
            </CommonTable>
          </div>
        </div>
      </div>
      {paymentModal && (
        <RewardModal
          isOpen={paymentModal}
          onSubmit={() => {
            fetchSubmissionData(true);
            setPaymentModal(false);
          }}
          onClose={() => setPaymentModal(false)}
          isPayMode={true}
          submission={selectedSubmisison}
        />
      )}

      {changeStatusModal && (
        <div className="changeStatus">
          <div className="changeStatusBody">
            <div className="changeStatusBodyTop">
              <i className="fa-solid fa-circle-exclamation fa-rotate-180"></i>
              <p>Change The Submission Status</p>
            </div>
            <div className="changeStatusBodySecPart">
              <select
                value={changeStatusVal}
                onChange={(e) => setChangeStatusVal(e.target.value)}
              >
                <option value="review" disabled>
                  Review
                </option>
                <option value="resolved">Resolved</option>
                <option value="unresolved">Unresolved </option>
                <option value="rejected">Rejected </option>
              </select>
            </div>
            <div className="changeStatusBodyThirdPart">
              <p>Are You Sure You Want to Change The Status?</p>
            </div>
            <div className="changeStatusBodyLastPart">
              <button
                onClick={() => setChangeStatusModal(false)}
                className="changeStatusBtnClose"
              >
                CLOSE
              </button>
              <button onClick={setStatus} className="changeStatusBtnSubmit">
                SUBMIT
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
