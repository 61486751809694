import React from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";
import mainPng from "./components/images/Main.png"
import cyberFirm from "./components/images/Cyberfirm.png";
import teamWor from "./components/images/Teamwor.png"
import profile1 from "./components/images/Picture1.png"
import profile2 from "./components/images/Picture2.png"
import { Helmet } from "react-helmet";

export default function Aboutus() {
	return (
		<>
			<Helmet>
				<title>About Us | About Bug Busters Labs: Our Mission and Vision</title>
				<meta name="description" content="About Us: Learn more about Bug Busters Labs, our mission, vision, and commitment to providing top-notch cybersecurity solutions." />
			</Helmet>
			<Header />
			<section className="uui-section_layout03">
				<div className="uui-page-padding-15">
					<div className="uui-container-large-12">
						<div className="uui-padding-vertical-xhuge-13">
							<div className="w-layout-grid uui-layout03_component">
								<img
									src={mainPng}
									loading="lazy"
									id="w-node-b537aa51-c461-3d49-70c9-d52dacc6f07b-446eff09"
									sizes="(max-width: 479px) 98vw, (max-width: 767px) 96vw, 44vw"
									alt=""
								/>
								<div className="uui-layout03_content">
									<h2 className="uui-heading-medium-8">Our Mission</h2>
									<div className="uui-space-xsmall-7"></div>
									<div className="uui-text-size-large-7">
										At Bugbusterslabs, our mission is clear: empower
										organizations to protect their digital assets and create a
										safer online ecosystem. We do this through transparency,
										integrity, customer focus, innovation, and continuous
										learning. These core values drive everything we do,
										fostering collaboration, trust, and ethical behavior in the
										cybersecurity community. Our ultimate goal is to provide a
										trusted and innovative bug bounty platform that connects
										ethical hackers, security researchers, and organizations
										worldwide, making the online world more secure for all.
									</div>
								</div>
							</div>
						</div>
						<div className="uui-padding-vertical-xhuge-13">
							<div className="w-layout-grid uui-layout03_component">
								<img
									src={cyberFirm}
									loading="lazy"
									id="w-node-_423b5b12-72da-3a17-b5a0-600f39172271-446eff09"
									sizes="(max-width: 479px) 94vw, (max-width: 767px) 96vw, 44vw"
									alt=""
								/>
								<div className="uui-layout03_content">
									<h2 className="uui-heading-medium-8">Who We Are</h2>
									<div className="uui-space-xsmall-7"></div>
									<div className="uui-text-size-large-7">
										Bugbusterslabs is dedicated to creating a secure digital
										ecosystem by connecting ethical hackers and security
										researchers with organizations worldwide. We foster
										collaboration to identify vulnerabilities, enhance
										cybersecurity, and inspire trust through transparent bug
										bounty programs.
									</div>
								</div>
							</div>
						</div>
						<div className="uui-padding-vertical-xhuge-13">
							<div className="">
								<div className="uui-layout03_content text-center">
									<h2 className="uui-heading-medium-8">
										<strong>Our Team</strong>
									</h2>
									<div className="uui-space-xsmall-7"></div>
									<div className="uui-text-size-large-7">
										Our exceptional team includes leaders, architects,
										developers, security researchers, and support staff, working
										cohesively to deliver outstanding results for our clients
										and partners.
									</div>
								</div>

								<div className="d-flex justify-content-between ourTeams">
									<div
										style={{
											width: "50%",
											display: "flex",
											flexDirection: "column",
											alignItems: "center",
										}}
									>
										<img style={{ height: "255px" }} src={profile1} alt="" />
										<div
											style={{
												paddingRight: "20px",
												paddingLeft: "20px",
												paddingTop: "0px",
											}}
										>
											<p
												className="text-center"
												style={{ color: "#000", fontSize: "18px" }}
											>
												Founder & CEO
											</p>
											<span>
												Amalan Mariajohn is an Experienced Security Professional
												and a Leader with a Growth Mindset. With over 26 years
												of cyber security experience, He has sharpened his
												skills in security testing, R&D, consulting, penetration
												testing, and red team management for global enterprises.
												He held leadership positions at Symantec, CSC, Target,
												ABB, Verizon, and Atlassian before starting a
												cybersecurity start up with his co-founder, Arokia
												peter.
												<br />
												<br />
												MBA Graduate in E-Business Specialties: CISSP, ISO 27001
												LA, Security+, CEH, CHFI, Awarded Patent in US Patent
												Office, US Patent Grants: US 8266693 B1
											</span>
										</div>
									</div>
									<div
										style={{
											width: "50%",
											display: "flex",
											flexDirection: "column",
											alignItems: "center",
										}}
									>
										<img style={{ height: "255px" }} src={profile2} alt="" />
										<div
											style={{
												paddingRight: "20px",
												paddingLeft: "20px",
												paddingTop: "0px",
											}}
										>
											<p
												className="text-center"
												style={{ color: "#000", fontSize: "18px" }}
											>
												Co-Founder & COO
											</p>
											<span>
												Arokia Peter Santhanam is an experienced entrepreneur
												and a leader with a growth mindset. With over 24 years
												of experience in information technology, he has
												sharpened his skills in networking, system management
												and information security for organizations and
												educational institutions. He held a managing director
												position at Carmel Infotech Solutions before starting a
												cybersecurity start-up with his founder, Amalan
												Mariajohn.
												<br />
												<br />
												MCA Graduate Specialties: CEH
											</span>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="uui-padding-vertical-xhuge-13">
							<div className="w-layout-grid uui-layout03_component">
								<img
									src={teamWor}
									loading="lazy"
									id="w-node-fa6eeee3-fbe9-ce4e-9c06-9e20615a85dd-446eff09"
									sizes="(max-width: 479px) 100vw, (max-width: 767px) 96vw, 44vw"
									alt=""
								/>
								<div className="uui-layout03_content">
									<h2 className="uui-heading-medium-8">
										<strong>Our Leadership</strong>
									</h2>
									<div className="uui-space-xsmall-7"></div>
									<div className="uui-text-size-large-7">
										Our company&#x27;s founder Amalan Mariajohn holds a wealth
										of knowledge in cybersecurity over 26 years, particularly in
										offensive security and leadership positions at Fortune 500
										companies.
										<br />
										<br />
										Co-founder ArokiaPeter Santhanam with a focus on system and
										network management and extensive organisational experience.
										Additionally, he possess entrepreneurial expertise gained
										from running an IT organisation.
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className="uui-section_layout13">
				<div className="uui-page-padding-3">
					<div className="uui-container-large-3"></div>
				</div>
			</section>
			<Footer />
		</>
	);
}
