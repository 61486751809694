import React, { useEffect, useState } from "react";
import Breadcrumb from "../../../components/breadcrumb/Breadcrumb";
import { getUserInfo } from "../../../../reducers/authSlice";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import CommonTable from "../../../common/Table/CommonTable";
import HTTPService from "../../../../utils/axios";
import CommonTableSkeletonLoader from "../../../components/SkeletonLoader/CommonTableSkeletonLoader";
import { customToast } from "../../../../App";

export default function CustomerVTxTaxonomy() {
  const { userInfo, permissions } = useSelector(getUserInfo);
  const permission = permissions.find((perm) => perm.module === "VTx Taxonomy");
  const [taxonomyData, setTaxonomyData] = useState();
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [searchPlaceHolder, setSearchPlaceHolder] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const tableColumn = [
    "VTx Code",
    "Main Category",
    "Sub-Category",
    "Variant title",
  ];

  const searchColumn = [
    { name: "VTx Code", value: "vtx_code" },
    // { name: "Category Type", value: "category_type" },
    // { name: "Faq Type", value: "user_type" },
    // { name: "Status", value: "cat_status" },
  ];

  const handleStatus = async (
    id,
    VTxCode,
    MCategory,
    SCategory,
    VariantDetails,
    status
  ) => {
    const param = {
      VTx_Code: VTxCode,
      Main_Category: MCategory,
      category_type: SCategory,
      Variant_Details: VariantDetails,
      is_deleted: false,
      Status: status,
    };
  };

  const Breadcrumbname = {
    home: "/researcher/home",
    "Master Module": "",
    "VTx Taxonomy": "/researcher/vtxtaxonomy",
    Dashboard: "/researcher/vtxtaxonomy",
  };

  const { invokeApi } = HTTPService();
  const getVtxSubmissions = (isLoading = true) => {
    if (isLoading)
      setLoading(true);
    let params = {
      page: currentPage,
      page_size: 25
    }
    if (searchValue && searchValue.length > 0) {
      params[searchValue] = searchQuery
    }
    invokeApi({
      method: "GET",
      url: `submissions-vtx`,
      params
    }).then((data) => {
      setLoading(false);
      setTaxonomyData(data);
    }).catch((error) => {
      setLoading(false);
      customToast.error("Something went wrong!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    })
  };

  const handleExportExcel = () => {
    invokeApi({
      url: '/submissions-vtx/download',
      method: "GET",
    }).then((res) => {
      window.open(res?.file_url, "_blank", "noopener,noreferrer");
    }).catch(error => console.log(error))
  };

  useEffect(() => {
    if (userInfo?.user_data?.id)
      getVtxSubmissions();
  }, [userInfo?.user_data?.id]);
  useEffect(() => {
    if (userInfo?.user_data?.id)
      getVtxSubmissions(false);
  }, [searchQuery, currentPage, searchValue]);
  return (
    <>
      <div className="container-fluid py-4 pb-0">
        <div className="row">
          <div className="col-lg-6 ps-3">
            <Breadcrumb names={Breadcrumbname} />
          </div>
          <div className="col-lg-6">
            <div className="button-row d-flex justify-content-end mt-0 mb-3">
              <button
                onClick={handleExportExcel}
                className="btn bg-gradient-dark me-3 mb-0 text-capitalize"
                id="adduser"
              >
                Download Taxonomy Sheet
              </button>
            </div>
          </div>
        </div>

        <div className="table-overflow row my-4">
          <div className="col-12">
            <div className="table-responsive">
              {loading ? (
                <CommonTableSkeletonLoader card={4} setTable={1} />
              ) : (
                <CommonTable
                  column={tableColumn}
                  data={taxonomyData && taxonomyData?.results}
                  searchBar={true}
                  itemPerPg={25}
                  searchBy={searchColumn && searchColumn}
                  setTable={1}
                  card={7}
                  searchValue={searchValue}
                  setSearchValue={setSearchValue}
                  searchPlaceHolder={searchPlaceHolder}
                  setSearchPlaceHolder={setSearchPlaceHolder}
                  searchQuery={searchQuery}
                  setSearchQuery={setSearchQuery}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  totalPages={taxonomyData?.total_pages ?? 1}
                  totalData={taxonomyData?.total_records}
                >
                  {taxonomyData &&
                    taxonomyData.results.map((item, index) => (
                      <tr key={index}>
                        <td className="cell-breakWord ">
                          <p className="text-sm  mb-0">{item.vtx_code}</p>
                        </td>
                        <td className="text-start">
                          <p className="text-sm  mb-0">{item.main_category}</p>
                        </td>
                        <td className="text-start">
                          <p className="text-sm  mb-0">{item.sub_category}</p>
                        </td>
                        <td className="text-start">
                          <p className="text-sm  mb-0">{item.variant_title}</p>
                        </td>
                      </tr>
                    ))}
                </CommonTable>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
