import React from "react";
import { Link } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { Helmet } from "react-helmet";

export default function Contactus() {
	return (
		<>
			<Helmet>
				<title>Contact Bug Busters Labs | Get in Touch Today</title>
				<meta name="description" content="Contact Us: Get in touch with Bug Busters Labs for inquiries, support, or partnership opportunities through our Contact Us page." />
			</Helmet>
			<Header />
			<section className="uui-section_contact02">
				<div className="uui-page-padding-3">
					<div className="uui-page-padding-6">
						<div className="uui-container-large-6">
							<div className="uui-padding-vertical-xhuge-6">
								<div className="w-layout-grid uui-contact07_component">
									<div className="uui-contact07_content-left">
										<div className="uui-max-width-large-5">
											<div className="uui-heading-subheading-5">Contact us</div>
											<h2 className="uui-heading-medium-5">Get in touch</h2>
											<div className="uui-space-xsmall-5"></div>
											<div className="uui-text-size-large-5">
												Our friendly team would love to hear from you.
											</div>
										</div>
									</div>
									<div className="uui-contact07_content-right">
										<div className="w-layout-grid uui-contact07_contact-list">
											<div
												id="w-node-_6a7c6b23-a33c-3b1a-3872-a57ec893ae14-c893ae05"
												className="uui-contact07_item"
											>
												<div className="uui-contact07_item-icon-wrapper">
													<div className="icon-featured-large">
														<div className="uui-icon-1x1-xsmall-5 w-embed">
															<svg
																width="24"
																height="24"
																viewbox="0 0 24 24"
																fill="none"
																xmlns="http://www.w3.org/2000/svg"
															>
																<path
																	d="M2 7L10.1649 12.7154C10.8261 13.1783 11.1567 13.4097 11.5163 13.4993C11.8339 13.5785 12.1661 13.5785 12.4837 13.4993C12.8433 13.4097 13.1739 13.1783 13.8351 12.7154L22 7M6.8 20H17.2C18.8802 20 19.7202 20 20.362 19.673C20.9265 19.3854 21.3854 18.9265 21.673 18.362C22 17.7202 22 16.8802 22 15.2V8.8C22 7.11984 22 6.27976 21.673 5.63803C21.3854 5.07354 20.9265 4.6146 20.362 4.32698C19.7202 4 18.8802 4 17.2 4H6.8C5.11984 4 4.27976 4 3.63803 4.32698C3.07354 4.6146 2.6146 5.07354 2.32698 5.63803C2 6.27976 2 7.11984 2 8.8V15.2C2 16.8802 2 17.7202 2.32698 18.362C2.6146 18.9265 3.07354 19.3854 3.63803 19.673C4.27976 20 5.11984 20 6.8 20Z"
																	stroke="currentColor"
																	stroke-width="2"
																	stroke-linecap="round"
																	stroke-linejoin="round"
																></path>
															</svg>
														</div>
													</div>
												</div>
												<div className="uui-contact07_item-text-wrapper">
													<h3 className="uui-heading-xxsmall-5">Email</h3>
													<div className="uui-space-xxsmall-5"></div>
													<Link
														to="mailto:support@bugbusterslabs.com"
														className="uui-text-style-link-02-3"
													>
														support@bugbusterslabs.com
													</Link>
												</div>
											</div>
											<div
												id="w-node-_6a7c6b23-a33c-3b1a-3872-a57ec893ae1e-c893ae05"
												className="uui-contact07_item"
											>
												<div className="uui-contact07_item-icon-wrapper">
													<div className="icon-featured-large">
														<div className="uui-icon-1x1-xsmall-5 w-embed">
															<svg
																width="24"
																height="24"
																viewbox="0 0 24 24"
																fill="none"
																xmlns="http://www.w3.org/2000/svg"
															>
																<path
																	d="M12 12.5C13.6569 12.5 15 11.1569 15 9.5C15 7.84315 13.6569 6.5 12 6.5C10.3431 6.5 9 7.84315 9 9.5C9 11.1569 10.3431 12.5 12 12.5Z"
																	stroke="currentColor"
																	stroke-width="2"
																	stroke-linecap="round"
																	stroke-linejoin="round"
																></path>
																<path
																	d="M12 22C14 18 20 15.4183 20 10C20 5.58172 16.4183 2 12 2C7.58172 2 4 5.58172 4 10C4 15.4183 10 18 12 22Z"
																	stroke="currentColor"
																	stroke-width="2"
																	stroke-linecap="round"
																	stroke-linejoin="round"
																></path>
															</svg>
														</div>
													</div>
												</div>
												<div className="uui-contact07_item-text-wrapper">
													<h3 className="uui-heading-xxsmall-5">
														Registered Office (India)
													</h3>
													<div className="uui-space-xxsmall-5"></div>
													<div className="uui-text-style-link-02-3">
														Bugbusterslabs Private Limited
														<br />
														1st Floor, 13, 3rd Cross Street, Kalaimagal Nagar,
														<br />
														Ekkattuthangal, <br />
														Chennai: - 600032
														<br />
														Tamilnadu, India
														<br />
														<br />
														Bugbusterslabs Private Limited
														<br />
														We Work Princeville, Domlur, Princeville, <br />
														Embassy Golf Links Business Park, off Intermediate
														ring road, Domlur, Bangalore, KA 560071
													</div>
												</div>
											</div>
											<div
												id="w-node-_6a7c6b23-a33c-3b1a-3872-a57ec893ae1e-c893ae05"
												className="uui-contact07_item"
											>
												<div className="uui-contact07_item-icon-wrapper">
													<div className="icon-featured-large">
														<div className="uui-icon-1x1-xsmall-5 w-embed">
															<svg
																width="24"
																height="24"
																viewbox="0 0 24 24"
																fill="none"
																xmlns="http://www.w3.org/2000/svg"
															>
																<path
																	d="M12 12.5C13.6569 12.5 15 11.1569 15 9.5C15 7.84315 13.6569 6.5 12 6.5C10.3431 6.5 9 7.84315 9 9.5C9 11.1569 10.3431 12.5 12 12.5Z"
																	stroke="currentColor"
																	stroke-width="2"
																	stroke-linecap="round"
																	stroke-linejoin="round"
																></path>
																<path
																	d="M12 22C14 18 20 15.4183 20 10C20 5.58172 16.4183 2 12 2C7.58172 2 4 5.58172 4 10C4 15.4183 10 18 12 22Z"
																	stroke="currentColor"
																	stroke-width="2"
																	stroke-linecap="round"
																	stroke-linejoin="round"
																></path>
															</svg>
														</div>
													</div>
												</div>
												<div className="uui-contact07_item-text-wrapper">
													<h3 className="uui-heading-xxsmall-5">
														Registered Office (USA)
													</h3>
													<div className="uui-space-xxsmall-5"></div>
													<div className="uui-text-style-link-02-3">
														Bugbusterslabs Inc. <br />
														1111B S Governors Ave STE 20032
														<br />
														Dover, DE 19904.
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="uui-container-small"></div>
				</div>
			</section>
			<Footer />
		</>
	);
}
