import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import React, { useContext, useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getUserInfo } from "../../../reducers/authSlice";
import HTTPService from "../../../utils/axios";
import { acceptedFileTypes, acceptedMediaFileTypes, taxanomyTypes } from "../../../utils/helperFunctions";
import AddModal from "../../common/modal/AddModal";
import Breadcrumb from "../../components/breadcrumb/Breadcrumb";
import EditPrgmSkeletonLoader from "../../components/SkeletonLoader/EditPrgmSkeletonLoader";
import AppContext from "../../Context/AppContext";
import useFetchProgramPackage from "../../HandleApis/Basevalues/FetchProgramPackage";
import useFetchProgramType from "../../HandleApis/Basevalues/FetchProgramType";
import useFetchSeverity from "../../HandleApis/Basevalues/FetchSeverity";
import useFetchCustomer from "../../HandleApis/Customer/FetchCustomer";
import useHttp from "../../Hooks/useHttp";
import "./Program.css";
import { customToast } from "../../../App";

export default function EditProgram() {
  const { invokeApi } = HTTPService();
  const { userInfo } = useSelector(getUserInfo);
  const { severityOpt } = useFetchSeverity();
  const { programTypOpt } = useFetchProgramType();
  const { programPkgOpt } = useFetchProgramPackage();
  const { customerOpt } = useFetchCustomer();
  const uid = localStorage.getItem("userid");
  const { loading, setLoading, setCircularLoading, circularLoading } =
    useContext(AppContext);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [form, setForm] = useState({});
  const [Errors, setErrors] = useState({});
  const [scopeUrlInput, setScopeUrlInput] = useState([]);
  const [outScopeUrlInput, setOutScopeUrlInput] = useState([]);
  const [rewardInput, setRewardInput] = useState([]);
  const [taxOfFiles, setTaxOfFiles] = useState([]);
  const [attachFiles, setAttachFiles] = useState([]);
  const [logoName, setLogoName] = useState("");
  const [prgmAttchFileName, setPrgmAttachFileName] = useState("");
  const [pgrmTagValue, setPgrmTagValue] = useState("");
  const [pgrmTagArr, setPgrmTagArr] = useState([]);
  const [typeOfTestingInput, setTypeOfTestingInput] = useState("");
  const [typeOfTestings, setTypeOfTestings] = useState([]);
  const [languagesFrameworksInput, setLanguagesFrameworksInput] = useState("");
  const [languagesFrameworks, setLanguagesFrameworks] = useState([]);
  const [assetEnvironmentsInput, setAssetEnvironmentsInput] = useState("");
  const [assetEnvironments, setAssetEnvironments] = useState([]);
  const [switchValue, setSwitchValue] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const { PutRequest, GetRequest } = useHttp();
  const token = localStorage.getItem("token");
  const API = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const getSwitchValue = form && form.private_program === "true";
    setSwitchValue(getSwitchValue);
  }, [form]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  function receiveData(data) {
    const scopeUrl = [
      data.scope_items_url2,
      data.scope_items_url3,
      data.scope_items_url4,
      data.scope_items_url5,
    ].filter(Boolean);

    const outScopeUrl = [
      data.out_Of_scope_item_url2,
      data.out_Of_scope_item_url3,
      data.out_Of_scope_item_url4,
      data.out_Of_scope_item_url5,
    ].filter(Boolean);

    const RewardCount = [
      data.p2_reward_amt,
      data.p3_reward_amt,
      data.p4_reward_amt,
      data.p5_reward_amt,
    ].filter(Boolean);

    const taxdoc = [
      data.taxonomy_doc1,
      data.taxonomy_doc2,
      data.taxonomy_doc3,
      data.taxonomy_doc4,
      data.taxonomy_doc5,
    ].filter(Boolean);

    const uploaddoc = [
      data.upload_doc1,
      data.upload_doc2,
      data.upload_doc3,
      data.upload_doc4,
      data.upload_doc5,
    ].filter(Boolean);

    const TotaStrToArr = data.testing_allowed
      ? data.testing_allowed.split(",")
      : [];

    const pgrmTagStrToArr = data.project_tags
      ? data.project_tags.split(",")
      : [];

    const LfStrToArr = data.language_framworks
      ? data.language_framworks.split(",")
      : [];

    const AeStrToArr = data.asset_environments
      ? data.asset_environments.split(",")
      : [];

    setScopeUrlInput(scopeUrl);
    setOutScopeUrlInput(outScopeUrl);
    setRewardInput(RewardCount);
    setTypeOfTestings(TotaStrToArr);
    setPgrmTagArr(pgrmTagStrToArr);
    setLanguagesFrameworks(LfStrToArr);
    setAssetEnvironments(AeStrToArr);

    const startDate = formatDate(data.start_date);
    const endDate = formatDate(data.end_date);

    setTaxOfFiles(taxdoc);
    setAttachFiles(uploaddoc);
    // if (data.program_attachments) {
    setPrgmAttachFileName(data.program_attachments);
    setLogoName(data.logo.split('/')[data.logo.split('/').length - 1])
    // }

    setForm({ ...data, start_date: startDate, end_date: endDate });
  }

  const fetchProgramData = async () => {
    setLoading(true);

    const { data, error } = await GetRequest(
      `${API}programs/${id}/`,
      {},
      {
        Authorization: `Bearer ${token}`,
      }
    );

    if (error) {
      customToast.error("Something went wrong!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    } else {
      setLoading(false);
      receiveData(data);
    }
  };

  useEffect(() => {
    fetchProgramData();
  }, []);

  const handleInputChange = (field, value) => {
    setForm((prevForm) => ({
      ...prevForm,
      [field]: value,
    }));
  };

  const handleAddInput = (e) => {
    e.preventDefault();
    if (scopeUrlInput.length < 4) {
      setScopeUrlInput((prevInputs) => [...prevInputs, ""]);
    }
  };

  const handleOutAddInput = (e) => {
    e.preventDefault();
    if (outScopeUrlInput.length < 4) {
      setOutScopeUrlInput((prevInputs) => [...prevInputs, ""]);
    }
  };

  const handleRewardInput = (e) => {
    e.preventDefault();
    if (rewardInput.length < 4) {
      setRewardInput((prevInputs) => [...prevInputs, ""]);
    }
  };

  const handleDynamicChange = (index, event, setInput) => {
    const newInputs = [...setInput];
    newInputs[index] = event.target.value;
    setInput(newInputs);
  };

  const deleteDynamicInput = (e, index, setInput, input) => {
    e.preventDefault();
    const newInputs = [...input];
    newInputs.splice(index, 1);
    setInput(newInputs);
  };

  const handleFileChange = (event, setFile, maxFiles, maxSize) => {
    const selectedFiles = Array.from(event.target.files);

    for (const file of selectedFiles) {
      if (file.size > maxSize) {
        alert(`File "${file.name}" exceeds the maximum upload size.`);
        return;
      }
    }

    if (setFile.length + selectedFiles.length > maxFiles) {
      alert(`You can only upload a maximum of ${maxFiles} files.`);
      return;
    }

    setFile((prevFiles) => [...prevFiles, ...selectedFiles]);
  };

  const deleteFile = (index, setFile, file) => {
    const newFiles = [...file];
    newFiles[index] = "";
    setFile(newFiles);
  };

  const handleSingleFileChange = (field, event) => {
    handleInputChange(field, event.target.files[0]);
    if (field === "logo") {
      setLogoName(event.target.files[0].name);
    } else if (field === "program_attachments") {
      setPrgmAttachFileName(event.target.files[0].name);
    }
  };

  const addDynamicValue = (e, value, setValue, setArray) => {
    if (e.keyCode === 13 && value) {
      e.preventDefault();
      setArray((prevArray) => [...prevArray, value]);
      setValue("");
    }
  };

  const deleteDynamicValue = (val, setArray) => {
    setArray((prevArray) => prevArray.filter((t) => t !== val));
  };

  const handleSwitchToggle = () => {
    setSwitchValue((prevValue) => !prevValue);
  };

  const checkallValidity = () => {
    const {
      program_type,
      program_package,
      customer,
      program_title,
      project_description,
      logo,
      program_attachments,
      scope_title,
      scope_items_url1,
      out_Of_scope_item_url1,
      severity,
      expected_vulnerability_types,
      p1_reward_amt,
      p2_reward_amt,
      p3_reward_amt,
      maximun_budget,
      start_date,
      end_date,
    } = form;
    const allError = {};

    if (!program_type || program_type === "")
      allError.program_type = "Please select program type";
    if (!program_package || program_package === "")
      allError.program_package = "Please select program packages";
    if (!program_title || program_title === "")
      allError.program_title = "Please select Program Title";
    if (!project_description || project_description === "")
      allError.project_description = "Please select Program Description";
    if (!logo || logo === "") allError.logo = "Please Upload Logo";
    if (!scope_title || scope_title === "")
      allError.scope_title = "Please enter scope title";
    if (!scope_items_url1 || scope_items_url1 === "")
      allError.scope_items_url1 = "Please enter scope url";
    if (!severity || severity === "")
      allError.severity = "Please Select Severity";
    if (!expected_vulnerability_types || expected_vulnerability_types === "")
      allError.expected_vulnerability_types =
        "Please Select Expected Vulnerability Types ";
    if (!p1_reward_amt || p1_reward_amt === "")
      allError.p1_reward_amt = "Please Enter Reward Amount ";
    if (!maximun_budget || maximun_budget === "")
      allError.maximun_budget = "Please Enter Maximum Budget";
    if (!start_date || start_date === "")
      allError.start_date = "Please Select Start Date";
    if (!end_date || end_date === "")
      allError.end_date = "Please Select End Date";
    if (!out_Of_scope_item_url1)
      allError.out_Of_scope_item_url1 = "Please at one scopr url";
    if (!p1_reward_amt) allError.p1_reward_amt = "Please Enter Value";
    if (typeOfTestings.length < 1) allError.typeOfTestings = "Please add atleast one value";
    if (languagesFrameworks.length < 1) allError.languagesFrameworks = "Please add atleast one value";
    if (assetEnvironments.length < 1) allError.assetEnvironments = "Please add atleast one value";
    if (pgrmTagArr.length < 1) allError.pgrmTagArr = "Please enter program tag";
    if (taxOfFiles.length < 1) allError.taxOfFiles = "Please upload at least one document";
    if (attachFiles.length < 1) allError.attachFiles = "Please upload at least one document";
    return allError;
  };

  const prepareDynamicForm = () => {
    let dynamicForm = { ...form };

    dynamicForm = {
      ...dynamicForm,
      taxonomy_doc1: taxOfFiles[0] || "",
      taxonomy_doc2: taxOfFiles[1] || "",
      taxonomy_doc3: taxOfFiles[2] || "",
      taxonomy_doc4: taxOfFiles[3] || "",
      taxonomy_doc5: taxOfFiles[4] || "",
      upload_doc1: attachFiles[0] || "",
      upload_doc2: attachFiles[1] || "",
      upload_doc3: attachFiles[2] || "",
      upload_doc4: attachFiles[3] || "",
      upload_doc5: attachFiles[4] || "",
      scope_items_url2: scopeUrlInput[0] || "",
      scope_items_url3: scopeUrlInput[1] || "",
      scope_items_url4: scopeUrlInput[2] || "",
      scope_items_url5: scopeUrlInput[3] || "",
      out_Of_scope_item_url2: outScopeUrlInput[0] || "",
      out_Of_scope_item_url3: outScopeUrlInput[1] || "",
      out_Of_scope_item_url4: outScopeUrlInput[2] || "",
      out_Of_scope_item_url5: outScopeUrlInput[3] || "",
      p2_reward_amt: rewardInput[0] || "",
      p3_reward_amt: rewardInput[1] || "",
      p4_reward_amt: rewardInput[2] || "",
      p5_reward_amt: rewardInput[3] || "",
      testing_allowed: typeOfTestings.toString(),
      language_framworks: languagesFrameworks.toString(),
      asset_environments: assetEnvironments.toString(),
      // program_status: "approved",
      private_program: switchValue.toString(),
      user_id: uid,
      project_tags: pgrmTagArr.toString(),
    };

    // Remove fields if they are of type string but not an empty string
    [
      "taxonomy_doc1",
      "taxonomy_doc2",
      "taxonomy_doc3",
      "taxonomy_doc4",
      "taxonomy_doc5",
      "upload_doc1",
      "upload_doc2",
      "upload_doc3",
      "upload_doc4",
      "upload_doc5",
      "logo",
      "program_attachments"
    ].forEach((field) => {
      if (typeof dynamicForm[field] === "string" && dynamicForm[field] !== "") {
        delete dynamicForm[field];
      }
    });

    return dynamicForm;
  };

  const finalSubmit = async () => {
    const checkError = checkallValidity();
    if (Object.keys(checkError).length > 0) {
      setErrors(checkError);
    } else {
      const dynamicForm = prepareDynamicForm();
      setCircularLoading(true);
      setOpenAddModal(false);

      const { data, error } = await PutRequest(
        `${API}programs/${id}/`,
        dynamicForm,
        {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        }
      );

      if (error) {
        setCircularLoading(false);
        customToast.error("Something went wrong!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      } else {
        setCircularLoading(false);
        customToast.success("Program updated successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        if (userInfo.role == "customer") {
          navigate("/customer/program/dashboard");
        } else {
          navigate("/admin/program/dashboard");
        }
      }
    }
  };

  const handleFinalClick = async () => {
    const checkError = checkallValidity();
    if (Object.keys(checkError).length > 0) {
      setErrors(checkError);
      setOpenAddModal(false);
      customToast.error("Resolve all errors!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    } else {
      setOpenAddModal(true);
    }
  };

  const Breadcrumbname = {
    Home: "/admin/home",
    Management: "/admin/program/dashboard",
    Programs: "/admin/program/dashboard",
    "Edit Program": "",
  };
  const handleExportExcel = () => {
    invokeApi({
      url: '/submissions-vtx/download',
      method: "GET",
    }).then((res) => {
      window.open(res?.file_url, "_blank", "noopener,noreferrer");
    }).catch(error => console.log(error))
  };
  return (
    <>
      {loading ? (
        <EditPrgmSkeletonLoader layout={1} />
      ) : (
        <>
          <div className="container-fluid py-4">
            <div className="row">
              <div className="col-lg-12 m-auto ps-3">
                <Breadcrumb names={Breadcrumbname} />
              </div>
            </div>
            <div className="card card-body mt-4">
              <div className="row">
                <div className="col-lg-9">
                  <h6 className="mb-0">Edit Program</h6>
                  <hr className="horizontal dark my-3" />
                  <div className="row">
                    <div className="col-lg-6 ">
                      <Form.Group>
                        <Form.Label>
                          Program Type <span style={{ color: "red" }}>*</span>
                        </Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          value={form.program_type}
                          onChange={(e) =>
                            handleInputChange("program_type", e.target.value)
                          }
                          isInvalid={!!Errors.program_type}
                          isValid={!!form.program_type && !Errors.program_type}
                        >
                          <option value="">Select Program Type</option>
                          {programTypOpt &&
                            programTypOpt?.split(",").map((item, index) => (
                              <option key={item} value={item}>
                                {item}
                              </option>
                            ))}
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          {Errors.program_type}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>
                    <div className="col-lg-6 ">
                      <Form.Group>
                        <Form.Label>
                          Program Package{" "}
                          <span style={{ color: "red" }}>*</span>
                        </Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          value={form.program_package}
                          onChange={(e) =>
                            handleInputChange("program_package", e.target.value)
                          }
                          isInvalid={!!Errors.program_package}
                          isValid={
                            !!form.program_package && !Errors.program_package
                          }
                        >
                          <option value="">Select Program Package</option>
                          {programPkgOpt &&
                            programPkgOpt?.split(",").map((item, index) => (
                              <option key={item} value={item}>
                                {item}
                              </option>
                            ))}
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          {Errors.program_package}
                        </Form.Control.Feedback>

                        <p style={{ color: "#7F56D9", fontSize: "12px" }}>
                          * Premium Plan is Valid Upto 6 Months and get Bonus of
                          $500.00
                        </p>
                      </Form.Group>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-12 ">
                      <Form.Group>
                        <Form.Label>
                          Customer Name <span style={{ color: "red" }}>*</span>
                        </Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          value={form.customer}
                          onChange={(e) =>
                            handleInputChange("customer", e.target.value)
                          }
                          isInvalid={!!Errors.customer}
                          isValid={!!form.customer && !Errors.customer}
                          disabled={userInfo?.role === 'customer'}
                        >
                          <option value="">Select Customer Name</option>
                          {customerOpt &&
                            customerOpt.results.map((item, index) => (
                              <option key={item.id} value={item.id}>
                                {item.entity_name} -- {item.user_id} --{" "}
                                {item.first_name} {item.last_name}
                              </option>
                            ))}
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          {Errors.customer}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>
                  </div>

                  <Form.Group className="mt-2">
                    <Form.Label>
                      Program Title <span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <Form.Control
                      className="m-0"
                      type="text"
                      name="programtitle"
                      placeholder="Enter a Program Title"
                      value={form.program_title}
                      onChange={(e) =>
                        handleInputChange("program_title", e.target.value)
                      }
                      isInvalid={!!Errors.program_title}
                      isValid={!!form.program_title && !Errors.program_title}
                    ></Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {Errors.program_title}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="mt-2">
                    <Form.Label>
                      Program Description{" "}
                      <span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <p className="form-text text-muted text-xs ms-1 mt-0">
                      This is how others will learn about the project, so make
                      it good!
                    </p>
                    <CKEditor
                      editor={ClassicEditor}
                      data={form.project_description}
                      onChange={(event, editor) => {
                        handleInputChange(
                          "project_description",
                          editor.getData()
                        );
                      }}
                    />
                    {Errors.project_description && (
                      <div
                        className="invalid-feedback"
                        style={{ display: "block" }}
                      >
                        {Errors.project_description}
                      </div>
                    )}
                  </Form.Group>
                </div>
                <div className="col-lg-3 ">
                  <Form.Group>
                    <Form.Label>
                      Logo <span style={{ color: "red" }}>*</span>
                    </Form.Label>

                    <div className="form-control dotted">
                      <div className="w-100 file-upload d-flex justify-content-center"
                        style={{ position: 'relative' }}
                      >
                        <p
                          className="file-name"
                          style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",

                          }}
                        >
                          {logoName && logoName ? <>{logoName} <i style={{ color: '#000', zIndex: 1000, position: 'absolute', }} onClick={(e) => {
                            e.stopPropagation();
                            setLogoName()
                            setPrgmAttachFileName()
                          }} className="fa-solid fa-trash text-primary"></i></> : <p>Upload Logo</p>}
                        </p>

                        <Form.Control
                          type="file"
                          onChange={(e) => {
                            if (!acceptedMediaFileTypes.includes(e.target.files[0]?.type)) {
                              return customToast.error(`File ${e.target.files[0]?.type} not supported`, {
                                position: toast.POSITION.TOP_RIGHT,
                                autoClose: 1000,
                              })
                            }
                            if (
                              e.target.files[0] &&
                              e.target.files[0].size &&
                              e.target.files[0].size > 5 * 1024 * 1024
                            ) {
                              // Convert to bytes (10MB)
                              return customToast.error(`File "${e.target.files[0].name}" exceeds the maximum upload size of 5MB.`, {
                                position: toast.POSITION.TOP_RIGHT,
                                autoClose: 1000,
                              });

                            }
                            handleSingleFileChange("logo", e)
                          }}
                          isInvalid={!!Errors.logo}
                          isValid={!!form.logo && !Errors.logo}
                          accept=".jpg,.png,.webp"
                        />
                        <Form.Control.Feedback type="invalid">
                          {Errors.logo}
                        </Form.Control.Feedback>
                      </div>
                    </div>
                  </Form.Group>

                  <Form.Group className="mt-3">
                    <Form.Label>Private Program</Form.Label>
                    <p className="form-text text-muted text-xs ms-1 mt-0">
                      If You wish to Hide Program
                    </p>
                    <Form.Check
                      type="switch"
                      id="custom-switch"
                      checked={switchValue}
                      onChange={handleSwitchToggle}
                    />
                  </Form.Group>

                  <Form.Group className="mt-2">
                    <Form.Label>Program Attachments</Form.Label>
                    <div className="form-control dotted">
                      <div className="file-upload w-100 d-flex justify-content-center"
                        style={{ position: 'relative' }}
                      >
                        <p
                          className="file-name"
                          style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {prgmAttchFileName || form.program_attachments ? (
                            prgmAttchFileName || form.program_attachments
                          ) : (
                            <p>Upload Program Attachment</p>
                          )}

                          {(prgmAttchFileName || form.program_attachments) && (
                            <i style={{ zIndex: 1000, position: 'absolute', right: 0 }} onClick={(e) => {
                              e.stopPropagation();
                              setPrgmAttachFileName("");
                              setForm({ ...form, program_attachments: "" });
                            }} className="fa-solid fa-trash text-primary"></i>
                          )}
                        </p>{" "}

                        <Form.Control
                          type="file"
                          onChange={(e) => {
                            if (!acceptedFileTypes.includes(e.target.files[0]?.type)) {
                              customToast.error(`File ${e.target.files[0]?.type} not supported`, {
                                position: toast.POSITION.TOP_RIGHT,
                                autoClose: 1000,
                              })
                              return null
                            }
                            if (
                              e.target.files[0] &&
                              e.target.files[0].size &&
                              e.target.files[0].size > 5 * 1024 * 1024
                            ) {
                              customToast.error(`File "${e.target.files[0]?.name}" exceeds the maximum upload size of 5MB.`, {
                                position: toast.POSITION.TOP_RIGHT,
                                autoClose: 1000,
                              });
                              return null
                            }
                            handleSingleFileChange("program_attachments", e)
                          }}
                          isInvalid={!!Errors.program_attachments}
                          isValid={
                            !!form.program_attachments &&
                            !Errors.program_attachments
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          {Errors.program_attachments}
                        </Form.Control.Feedback>
                      </div>
                    </div>
                    <p className="form-text text-center text-muted text-xs ms-1 mt-2">
                      max-upload size :5mb
                    </p>
                  </Form.Group>
                </div>
              </div>

              <Form.Group className="mt-2">
                <Form.Label>
                  Program Tags<span style={{ color: "red" }}>*</span>
                </Form.Label>

                <div className="form-control  languageFrameworkDiv">
                  {pgrmTagArr.map((item, index) => (
                    <div key={index} className="LfElement">
                      <span>{item}</span>
                      <span
                        onClick={() => deleteDynamicValue(item, setPgrmTagArr)}
                        style={{ marginLeft: "10px" }}
                      >
                        <i className="fa-solid fa-xmark"></i>
                      </span>
                    </div>
                  ))}
                  <input
                    type="text"
                    placeholder="Add Program Tag"
                    value={pgrmTagValue}
                    onChange={(e) => setPgrmTagValue(e.target.value)}
                    onKeyDown={(e) =>
                      addDynamicValue(
                        e,
                        pgrmTagValue,
                        setPgrmTagValue,
                        setPgrmTagArr
                      )
                    }
                  />
                </div>
                {Errors.pgrmTagArr && (
                  <div
                    className="invalid-feedback"
                    style={{ display: "block" }}
                  >
                    {Errors.pgrmTagArr}
                  </div>
                )}
              </Form.Group>

              <Form.Group className="mt-2">
                <Form.Label>Scope Title <span style={{ color: "red" }}>*</span></Form.Label>
                <Form.Control
                  className="m-0"
                  type="text"
                  name="scopetitle"
                  placeholder="Enter Scope Title"
                  value={form.scope_title}
                  onChange={(e) =>
                    handleInputChange("scope_title", e.target.value)
                  }
                  isInvalid={!!Errors.scope_title}
                  isValid={!!form.scope_title && !Errors.scope_title}
                ></Form.Control>
                <Form.Control.Feedback type="invalid">
                  {Errors.scope_title}
                </Form.Control.Feedback>
              </Form.Group>
              <div className="row ">
                <div className="col-12">
                  <Form.Group className="mt-2">
                    <Form.Label>Scope Items</Form.Label>
                    <div className=" d-flex flex-column">
                      <div className="d-flex">
                        <div style={{ width: "70%" }}>
                          <Form.Control
                            placeholder="Enter Scope Item URL"
                            type="text"
                            value={form.scope_items_url1}
                            onChange={(e) =>
                              handleInputChange(
                                "scope_items_url1",
                                e.target.value
                              )
                            }
                            isInvalid={!!Errors.scope_items_url1}
                            isValid={
                              !!form.scope_items_url1 &&
                              !Errors.scope_items_url1
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            {Errors.scope_items_url1}
                          </Form.Control.Feedback>
                        </div>
                        <div>
                          <button
                            onClick={handleAddInput}
                            style={{ borderRadius: "8px" }}
                            className="mb-0 mt-0 ms-3 loginbtn btn btn-primary"
                          >
                            Add +
                          </button>
                        </div>
                      </div>
                      {scopeUrlInput.map((input, index) => (
                        <div key={index} className="d-flex  mt-3">
                          <div style={{ width: "70%" }}>
                            <Form.Control
                              className="form-control"
                              placeholder="Enter Scope Item URL"
                              type="text"
                              value={input}
                              onChange={(e) =>
                                handleDynamicChange(index, e, setScopeUrlInput)
                              }
                              isInvalid={
                                !!Errors[`scope_items_url${index + 2}`]
                              }
                              isValid={
                                !!scopeUrlInput[index] &&
                                !Errors[`scope_items_url${index + 2}`]
                              }
                            />
                            <Form.Control.Feedback type="invalid">
                              {Errors[`scope_items_url${index + 2}`]}
                            </Form.Control.Feedback>
                          </div>
                          <div>
                            <button
                              onClick={(e) =>
                                deleteDynamicInput(
                                  e,
                                  index,
                                  setScopeUrlInput,
                                  scopeUrlInput
                                )
                              }
                              style={{ borderRadius: "8px" }}
                              className="mb-0 mt-0 ms-3 loginbtn btn btn-primary"
                            >
                              X
                            </button>
                          </div>
                        </div>
                      ))}
                    </div>
                  </Form.Group>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <Form.Group className="mt-2">
                    <Form.Label>
                      Out Of Scope Items<span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <div className=" d-flex flex-column">
                      <div className="d-flex">
                        <div style={{ width: "70%" }}>
                          <Form.Control
                            className="form-control"
                            placeholder="Enter Out of Scope Item URL"
                            type="text"
                            value={form.out_Of_scope_item_url1}
                            onChange={(e) =>
                              handleInputChange(
                                "out_Of_scope_item_url1",
                                e.target.value
                              )
                            }
                            isInvalid={!!Errors.out_Of_scope_item_url1}
                            isValid={
                              !!form.out_Of_scope_item_url1 &&
                              !Errors.out_Of_scope_item_url1
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            {Errors.out_Of_scope_item_url1}
                          </Form.Control.Feedback>
                        </div>
                        <div>
                          <button
                            onClick={handleOutAddInput}
                            style={{ borderRadius: "8px" }}
                            className="mb-0 mt-0 ms-3 loginbtn btn btn-primary"
                          >
                            Add +
                          </button>
                        </div>
                      </div>
                      {outScopeUrlInput.map((input, index) => (
                        <div key={index} className="d-flex mt-3">
                          <div style={{ width: "70%" }}>
                            <Form.Control
                              className="form-control"
                              placeholder="Enter Out of Scope Item URL"
                              type="text"
                              value={input}
                              onChange={(e) =>
                                handleDynamicChange(
                                  index,
                                  e,
                                  setOutScopeUrlInput
                                )
                              }
                              isInvalid={
                                !!Errors[`out_Of_scope_item_url${index + 2}`]
                              }
                              isValid={
                                !!outScopeUrlInput[index] &&
                                !Errors[`out_Of_scope_item_url${index + 2}`]
                              }
                            />
                            <Form.Control.Feedback type="invalid">
                              {Errors[`out_Of_scope_item_url${index + 2}`]}
                            </Form.Control.Feedback>
                          </div>
                          <div>
                            <button
                              onClick={(e) =>
                                deleteDynamicInput(
                                  e,
                                  index,
                                  setOutScopeUrlInput,
                                  outScopeUrlInput
                                )
                              }
                              style={{ borderRadius: "8px" }}
                              className="mb-0 mt-0 ms-3 loginbtn btn btn-primary"
                            >
                              X
                            </button>
                          </div>
                        </div>
                      ))}
                    </div>
                  </Form.Group>
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-lg-6 col-12">
                  <Form.Group className="mt-2">
                    <Form.Label>
                      Severity <span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <Form.Control
                      as="select"
                      className="form-select"
                      name="choices-gender"
                      id="choices-gender"
                      value={form.severity}
                      onChange={(e) =>
                        handleInputChange("severity", e.target.value)
                      }
                      isInvalid={!!Errors.severity}
                      isValid={!!form.severity && !Errors.severity}
                    >
                      <option value="">Select Severity</option>
                      {severityOpt &&
                        severityOpt?.split(",").map((item, index) => (
                          <option key={index} value={item}>
                            {item}
                          </option>
                        ))}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {Errors.severity}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
                <div className="col-lg-6 col-12">
                  <Form.Group className="mt-2">
                    <Form.Label>Expected Vulnerability Types</Form.Label>
                    <Form.Control
                      className="m-0"
                      type="text"
                      name="ExpectedVulnerabilityTypes"
                      placeholder="Enter Expected Vulnerability Types"
                      value={form.expected_vulnerability_types}
                      onChange={(e) =>
                        handleInputChange(
                          "expected_vulnerability_types",
                          e.target.value
                        )
                      }
                      isInvalid={!!Errors.expected_vulnerability_types}
                      isValid={
                        !!form.expected_vulnerability_types &&
                        !Errors.expected_vulnerability_types
                      }
                    ></Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {Errors.expected_vulnerability_types}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
              </div>
              <div className="row mt-3">
                <Form.Label>
                  Rewards<span style={{ color: "red" }}>*</span>
                </Form.Label>
                <div className="col-lg-1">
                  <Form.Group className="mt-0">
                    <Form.Control
                      readOnly
                      className="m-0 inpPriority"
                      type="text"
                      name="p1"
                      value="P1"
                    ></Form.Control>
                  </Form.Group>
                </div>
                <div className="col-lg-4">
                  <Form.Group className="mt-0">
                    <Form.Control
                      className="m-0"
                      type="number"
                      name="p1"
                      placeholder="Enter Reward Amount"
                      value={form.p1_reward_amt}
                      onChange={(e) =>
                        handleInputChange("p1_reward_amt", e.target.value)
                      }
                      isInvalid={!!Errors.p1_reward_amt}
                      isValid={!!form.p1_reward_amt && !Errors.p1_reward_amt}
                    ></Form.Control>{" "}
                    {Errors.p1_reward_amt && (
                      <div
                        className="invalid-feedback"
                        style={{ display: "block" }}
                      >
                        {Errors.p1_reward_amt}
                      </div>
                    )}
                  </Form.Group>
                </div>
                <div className="col-lg-1">
                  <button
                    onClick={handleRewardInput}
                    style={{ borderRadius: "8px", textWrap: "nowrap" }}
                    className="mb-0 mt-0 ms-3 loginbtn btn btn-primary"
                  >
                    Add +
                  </button>
                </div>
              </div>

              <div className="row">
                {rewardInput.map((input, index) => (
                  <div key={index} className="d-flex">
                    <div className="col-lg-1 pe-4">
                      <Form.Group className="mt-5">
                        <Form.Control
                          readOnly
                          className="m-0 inpPriority"
                          type="text"
                          name="p3"
                          value={`P${index + 2}`}
                        ></Form.Control>
                      </Form.Group>
                    </div>
                    <div className="col-lg-4">
                      <Form.Group className="mt-5">
                        <Form.Control
                          className="m-0"
                          type="number"
                          name="rewardamount3"
                          placeholder="Enter Reward Amount"
                          value={input}
                          onChange={(e) =>
                            handleDynamicChange(index, e, setRewardInput)
                          }
                          isInvalid={!!Errors[`rewardInput${index + 1}`]}
                          isValid={
                            !!rewardInput[index] &&
                            !Errors[`rewardInput${index + 1}`]
                          }
                        ></Form.Control>
                        <Form.Control.Feedback type="invalid">
                          {Errors[`rewardInput${index + 1}`]}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>
                    <div className="col-lg-1 mt-5">
                      <button
                        onClick={(e) =>
                          deleteDynamicInput(
                            e,
                            index,
                            setRewardInput,
                            rewardInput
                          )
                        }
                        style={{ borderRadius: "8px" }}
                        className="mb-0 mt-0 ms-3 loginbtn btn btn-primary"
                      >
                        X
                      </button>
                    </div>
                  </div>
                ))}

                <div className="col-lg-5">
                  <Form.Group className="mt-3">
                    <Form.Label>
                      Maximun Budget (USD){" "}
                      <span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <Form.Control
                      className="m-0"
                      type="number"
                      name="rewardamount"
                      placeholder="Enter Reward Amount"
                      value={form.maximun_budget}
                      onChange={(e) =>
                        handleInputChange("maximun_budget", e.target.value)
                      }
                      isInvalid={!!Errors.maximun_budget}
                      isValid={!!form.maximun_budget && !Errors.maximun_budget}
                    ></Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {Errors.maximun_budget}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <Form.Group className="mt-3">
                    <Form.Label>
                      Start Date <span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <Form.Control
                      className="m-0"
                      type="date"
                      name="startdate"
                      value={form.start_date}
                      onChange={(e) =>
                        handleInputChange("start_date", e.target.value)
                      }
                      isInvalid={!!Errors.start_date}
                      isValid={!!form.start_date && !Errors.start_date}
                    />
                    <Form.Control.Feedback type="invalid">
                      {Errors.start_date}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
                <div className="col-lg-6">
                  <Form.Group className="mt-3">
                    <Form.Label>
                      End Date <span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <Form.Control
                      className="m-0"
                      type="date"
                      name="enddate"
                      value={form.end_date}
                      onChange={(e) =>
                        handleInputChange("end_date", e.target.value)
                      }
                      isInvalid={!!Errors.end_date}
                      isValid={!!form.end_date && !Errors.end_date}
                    />
                    <Form.Control.Feedback type="invalid">
                      {Errors.end_date}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
              </div>
              <div className="row mb-3 mt-4">
                <Form.Group as={Col} md="12">
                  <Form.Label
                    className="form-label"
                    style={{ fontSize: "14px" }}
                  >
                    Types of Testing Allowed
                    <span style={{ color: "red" }}>*</span>
                  </Form.Label>

                  <div className="form-control  languageFrameworkDiv">
                    {typeOfTestings.map((item, index) => (
                      <div key={index} className="LfElement">
                        <span>{item}</span>
                        <span
                          onClick={() => deleteDynamicValue(item, setTypeOfTestings)}
                          style={{ marginLeft: "10px" }}
                        >
                          <i className="fa-solid fa-xmark"></i>
                        </span>
                      </div>
                    ))}
                    <input
                      type="text"
                      placeholder="Add Type of Testing Allowed"
                      value={typeOfTestingInput}
                      onChange={(e) => setTypeOfTestingInput(e.target.value)}
                      onKeyDown={(e) =>
                        addDynamicValue(
                          e,
                          typeOfTestingInput,
                          setTypeOfTestingInput,
                          setTypeOfTestings
                        )
                      }
                      isInvalid={!!Errors.totaValue}
                      isValid={!!typeOfTestingInput && !Errors.totaValue}
                    />
                  </div>
                  {Errors.totaValue && (
                    <div
                      className="invalid-feedback"
                      style={{ display: "block" }}
                    >
                      {Errors.totaValue}
                    </div>
                  )}
                </Form.Group>
              </div>
              <div className="row mb-3 mt-4">
                <div className="col-6">
                  <Form.Group>
                    <Form.Label
                      className="form-label"
                      style={{ fontSize: "14px" }}
                    >
                      Languages & Frameworks
                      <span style={{ color: "red" }}>*</span>
                    </Form.Label>

                    <div className="form-control  languageFrameworkDiv">
                      {languagesFrameworks.map((item, index) => (
                        <div key={index} className="LfElement">
                          <span>{item}</span>
                          <span
                            onClick={() => deleteDynamicValue(item, setLanguagesFrameworks)}
                            style={{ marginLeft: "10px" }}
                          >
                            <i className="fa-solid fa-xmark"></i>
                          </span>
                        </div>
                      ))}
                      <input
                        type="text"
                        placeholder="Add Language or Framework"
                        value={languagesFrameworksInput}
                        onChange={(e) => setLanguagesFrameworksInput(e.target.value)}
                        onKeyDown={(e) =>
                          addDynamicValue(e, languagesFrameworksInput, setLanguagesFrameworksInput, setLanguagesFrameworks)
                        }
                        isInvalid={!!Errors.lfArray}
                        isValid={!!languagesFrameworks && !Errors.lfArray}
                      />
                    </div>
                    {Errors.lfArray && (
                      <div
                        className="invalid-feedback"
                        style={{ display: "block" }}
                      >
                        {Errors.lfArray}
                      </div>
                    )}
                  </Form.Group>
                </div>

                <div className="col-6">
                  <Form.Group>
                    <Form.Label
                      className="form-label "
                      style={{ fontSize: "14px" }}
                    >
                      Asset Environments<span style={{ color: "red" }}>*</span>
                    </Form.Label>

                    <div className="form-control  languageFrameworkDiv">
                      {assetEnvironments.map((item, index) => (
                        <div key={index} className="LfElement">
                          <span>{item}</span>
                          <span
                            onClick={() => deleteDynamicValue(item, setAssetEnvironments)}
                            style={{ marginLeft: "10px" }}
                          >
                            <i className="fa-solid fa-xmark"></i>
                          </span>
                        </div>
                      ))}
                      <input
                        type="text"
                        placeholder="Add Asset Environments"
                        value={assetEnvironmentsInput}
                        onChange={(e) => setAssetEnvironmentsInput(e.target.value)}
                        onKeyDown={(e) =>
                          addDynamicValue(e, assetEnvironmentsInput, setAssetEnvironmentsInput, setAssetEnvironments)
                        }
                        isInvalid={!!Errors.aeArray}
                        isValid={!!assetEnvironments && !Errors.aeArray}
                      />
                    </div>
                    {Errors.aeArray && (
                      <div
                        className="invalid-feedback"
                        style={{ display: "block" }}
                      >
                        {Errors.aeArray}
                      </div>
                    )}
                  </Form.Group>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6">
                  <Form.Group className="mt-3">
                    <div className="d-flex justify-content-between">
                      <Form.Label>Taxonomy Documents</Form.Label>
                      <p className="form-text text-muted text-xs">
                        Drop Files Here to Upload
                      </p>
                    </div>
                    <div className="form-control dotted">
                      <div className="file-upload">
                        <p
                          className="file-name"
                          style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          Click to upload files.
                        </p>
                        <Form.Control
                          type="file"
                          multiple
                          onChange={(e) => {
                            if (!taxanomyTypes.includes(e.target.files[0]?.type)) {
                              return customToast.error(`File ${e.target.files[0]?.type} not supported`, {
                                position: toast.POSITION.TOP_RIGHT,
                                autoClose: 1000,
                              })
                            }
                            handleFileChange(e, setTaxOfFiles, 5, 5 * 1024 * 1024)
                          }
                          }
                          accept=".xls, .xlsx, .doc, .docx"
                          isInvalid={!!Errors.taxOfFiles}
                          isValid={taxOfFiles.length > 1}
                        />
                        <Form.Control.Feedback type="invalid">
                          {Errors.taxOfFiles}
                        </Form.Control.Feedback>
                      </div>
                    </div>
                    <p className="form-text text-muted text-xs ms-1 mt-2">
                      * file formats allowed (xls, xlsx, doc, docx)
                    </p>
                    <button
                      onClick={handleExportExcel}
                      className="btn bg-gradient-dark me-3 mb-0 text-capitalize mb-2"
                      id="adduser"
                    >
                      Download Taxonomy Sheet
                    </button>
                    <div>
                      {taxOfFiles.length > 0 && (
                        <ul className="file-list">
                          {taxOfFiles.map(
                            (file, index) =>
                              file !== "" && (
                                <li key={index} className="file-item">
                                  <span className="file-name">
                                    {file.name || file}
                                  </span>
                                  {file !== "" && (
                                    <i
                                      onClick={() =>
                                        deleteFile(
                                          index,
                                          setTaxOfFiles,
                                          taxOfFiles
                                        )
                                      }
                                      style={{ borderRadius: "8px", cursor: 'pointer' }}
                                      className="fa-solid fa-trash text-primary"
                                    >
                                    </i>
                                  )}
                                </li>
                              )
                          )}
                        </ul>
                      )}
                    </div>
                  </Form.Group>
                </div>

                <div className="col-lg-6">
                  <Form.Group className="mt-3">
                    <div className="d-flex justify-content-between">
                      <Form.Label>Upload Documents</Form.Label>
                      <p className="form-text text-muted text-xs">
                        Max Upload Size : 10 MB
                      </p>
                    </div>
                    <div className="form-control dotted">
                      <div className="file-upload">
                        <p
                          className="file-name"
                          style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          Click to upload files.
                        </p>
                        <Form.Control
                          type="file"
                          multiple
                          onChange={(e) => {
                            if (!acceptedFileTypes.includes(e.target.files[0]?.type)) {
                              return customToast.error(`File ${e.target.files[0]?.type} not supported`, {
                                position: toast.POSITION.TOP_RIGHT,
                                autoClose: 1000,
                              })
                            }
                            handleFileChange(
                              e,
                              setAttachFiles,
                              5,
                              10 * 1024 * 1024
                            )
                          }
                          }
                          isInvalid={!!Errors.attachFiles}
                          isValid={attachFiles.length > 1}
                          accept=".jpg, .png, .webp, .pdf, .xls, .xlsx, doc, docx, .csv, .ppt"
                        />
                        <Form.Control.Feedback type="invalid">
                          {Errors.taxOfFiles}
                        </Form.Control.Feedback>
                      </div>
                    </div>

                    <p className="form-text text-muted text-xs ms-1 mt-2">
                      * file formats allowed (.jpg, .png, webp, pdf, xls,xlsx,
                      doc, docx, .csv, .ppt)
                    </p>
                    <div>
                      {attachFiles.length > 0 && (
                        <ul className="file-list">
                          {attachFiles.map(
                            (file, index) =>
                              file !== "" && (
                                <li key={index} className="file-item">
                                  <span className="file-name">
                                    {file.name || file}
                                  </span>
                                  {file !== "" && (
                                    <i
                                      onClick={() =>
                                        deleteFile(
                                          index,
                                          setAttachFiles,
                                          attachFiles
                                        )
                                      }
                                      style={{ borderRadius: "8px", cursor: 'pointer' }}
                                      className="fa-solid fa-trash text-primary"
                                    >
                                    </i>
                                  )}
                                </li>
                              )
                          )}
                        </ul>
                      )}
                    </div>
                  </Form.Group>
                </div>
              </div>

              <div className="d-flex justify-content-end mt-4">
                <button
                  type="button"
                  name="button"
                  className="btn bg-gradient-dark m-0"
                  onClick={() => navigate(-1)}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  name="button"
                  className="btn loginbtn m-0 ms-2 "
                  onClick={handleFinalClick}
                >
                  Update Program
                </button>
              </div>
            </div>
          </div>
          <AddModal
            cancelBtn={"Cancel"}
            submitBtn={"SUBMIT"}
            createAddTopic={"Edit Program"}
            confirmText={"Edit The Program"}
            isOpen={openAddModal}
            onSubmit={finalSubmit}
            onClose={() => setOpenAddModal(false)}
          />
        </>
      )}
    </>
  );
}
