import React, { useEffect, useState } from "react";
import PaymentStImg from "../../assets/img/user-default.png";
import { toast } from "react-toastify";
import HTTPService from "../../../utils/axios";
import { useSelector } from "react-redux";
import { getUserInfo } from "../../../reducers/authSlice";
import { getObjectKeys } from "../../../utils/helperFunctions";
import { customToast } from "../../../App";
import CircularLoader from "../../CircularLoader/CircularLoader";
const priorityKey = {
  p1: "p1_reward_amt",
  p2: "p2_reward_amt",
  p3: "p3_reward_amt",
  p4: "p4_reward_amt",
  p5: "p5_reward_amt",
};
export default function RewardModal({
  isOpen,
  onClose,
  onSubmit,
  submission,
  isPayMode,
  showPay,
}) {
  const { invokeApi } = HTTPService();
  const { userInfo, userAccData } = useSelector(getUserInfo);
  const [rewardData, setRewardData] = useState();
  const [program, setProgram] = useState();
  const [formData, setFormData] = useState({});
  const [isEditable, setIsEditable] = useState(isPayMode);
  const [loading, setLoading] = useState();
  const fetchData = (noLoading) => {
    if (!noLoading) setLoading(true);
    invokeApi({
      method: "GET",
      url: "rewards",
      params: { submission_id: submission.id },
    })
      .then((res) => {
        setLoading(false);
        if (res.results && res.results.length > 0) {
          setRewardData(res.results[0]);
          setFormData(res.results[0]);
          setIsEditable(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        customToast.error("Something went wrong!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      });
  };
  const fetchProgram = (noLoading) => {
    if (!noLoading) setLoading(true);
    invokeApi({
      method: "GET",
      url: `programs/${submission.program_id}`,
    })
      .then((res) => {
        setProgram(res);
      })
      .catch((error) => {
        customToast.error("Something went wrong!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      });
  };
  const fetchRewardPoints = async (noLoading) => {
    if (!noLoading) setLoading(true);
    invokeApi({
      method: "GET",
      url: "basevalues/",
      params: { title_code: "rewards_points" },
    })
      .then((res) => {
        if (res.results.length > 0) {
          let point = res.results[0]?.value_options?.split(",");
          let reward = point.find((el) => el.includes(submission.priority));
          handleChangeFormData("rewards_points", reward.split("=")[1]);
        }
      })
      .catch((error) => {
        customToast.error("Something went wrong!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      });
  };

  useEffect(() => {
    if (submission?.id) {
      fetchData();
      fetchProgram();
    }
    if (userInfo.role === "customer") {
      setFormData((prev) => {
        return {
          ...prev,
          wallet_id: userAccData?.wallet_id,
          user: userAccData?.is_support_user ? userAccData?.parent_user : submission?.user_details?.id,
          txn_id: "",
          created_by: userAccData?.is_support_user ? userAccData?.parent_user : userInfo?.user_data?.id,
          note_status: "pending",
          confirmed_priority: submission?.priority,
          submission_id: submission.id,
        };
      });
      fetchRewardPoints();
    }
  }, [submission]);

  const handleChangeFormData = (field, value) => {
    setFormData((prev) => {
      return {
        ...prev,
        [field]: value,
      };
    });
  };
  const handleSubmit = () => {
    let data = { ...formData };
    invokeApi({
      method: "POST",
      url: "rewards",
      data,
    })
      .then((res) => {
        onSubmit();
        customToast.success("Action performed successfully!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      })
      .catch((error) => {
        customToast.error("Something went wrong!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      });
  };
  const handlePay = () => {
    let data = {
      reward_id: rewardData.id,
      customer_id: rewardData.created_by,
      researcher_id: submission.user,
      amount: rewardData?.rewards_amount,
      program_id: submission.program_id,
      submission_id: submission.id,
    };
    invokeApi({
      method: "POST",
      url: "transactions/pay",
      data,
    })
      .then((res) => {
        customToast.success("Payment done", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        if (onSubmit) onSubmit();
      })
      .catch((error) => {
        console.log(error.data)
        const errorString = error?.data?.error?.toString() ?? undefined
        customToast.error(errorString ?? "Something went wrong!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      });
  };
  return (
    <>
      {loading && <CircularLoader size={100} />}
      {isOpen && (
        <div className="modal PaymentModal">
          <div className="PaymentModal-content">
            <div className="PaymentModal-Border">
              <div className="PaymentModal-logo">
                <img src={PaymentStImg} alt="Logo" />
              </div>
              <div className=" PaymentModal-header">
                <h4>Add Reward</h4>
                <div>
                  Pay{" "}
                  <b>
                    {submission?.user_details?.first_name}{" "}
                    {submission?.user_details?.last_name} (
                    {submission?.user_details?.user_id}){" "}
                  </b>{" "}
                  for their Hard Work
                </div>
              </div>
              <div className="modal-body pt-0">
                <form>
                  <div className="PaymentModal-group">
                    <label htmlFor="rewardName">Confirmed severity</label>
                    <div className="CSdiv ">
                      <span className="p-1 rounded">
                        {submission?.priority}
                      </span>
                      {" - "}
                      {submission?.severity}
                    </div>
                  </div>
                  <div className="PaymentModal-group">
                    <label htmlFor="rewardPoints">
                      Reward amount ($
                      {program &&
                        program[
                        priorityKey[submission?.priority.toLowerCase()]
                        ]}
                      )
                    </label>
                    <input
                      type="number"
                      id="rewardPoints"
                      name="rewards_amount"
                      value={formData?.rewards_amount}
                      onChange={(e) =>
                        handleChangeFormData("rewards_amount", e.target.value)
                      }
                      disabled={!isEditable}
                    />
                  </div>
                  <div className="PaymentModal-group">
                    <label htmlFor="rewardDescription">
                      Note to Researcher &#40;optional&#41;{" "}
                    </label>
                    <textarea
                      id="rewardDescription"
                      name="note"
                      value={formData?.note}
                      onChange={(e) =>
                        handleChangeFormData("note", e.target.value)
                      }
                      disabled={!isEditable}
                    ></textarea>
                  </div>
                  <div className="forFooterBorder">
                    <p style={{ fontSize: "0.9rem" }}>
                      <b>
                        {submission?.user_details?.first_name}{" "}
                        {submission?.user_details?.last_name}
                      </b>{" "}
                      will be notified of this <br /> reward and payment will be
                      scheduled <br />
                      immediately
                    </p>
                  </div>

                  <div className="PaymentModal-group PaymentModal-btn">
                    <button
                      type="button"
                      className="PaymentModal-Cbutton"
                      onClick={onClose}
                    >
                      <b>Cancel</b>
                    </button>
                    {(userInfo.role != "admin" && userInfo?.role != 'sub_admin') && isEditable && (
                      <button
                        type="button"
                        onClick={handleSubmit}
                        className="PaymentModal-Pbutton"
                      >
                        <b>Pay</b>
                      </button>
                    )}

                    {((userInfo.role == "admin" || userInfo?.role == 'sub_admin') && rewardData?.note_status == "pending") && (
                      <button
                        type="button"
                        onClick={handlePay}
                        className="PaymentModal-Pbutton"
                      >
                        <b>Pay</b>
                      </button>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div >
        </div >
      )
      }
    </>
  );
}
