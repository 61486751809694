import React from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";
import darkWebImg from "./components/images/DarkWeb.png"
import { Helmet } from "react-helmet";

export default function DarkWebMonitoring() {
	return (
		<>
			<Helmet>
				<title>Proactive Dark Web Monitoring | Protect Business From Data Breaches</title>
				<meta name="description" content="Stay ahead of threats with our dark web monitoring services. Secure your data from data leaks and breaches. Secure your sensitive information." />
				<script type="application/ld+json">
					{JSON.stringify({
						"@context": "https://schema.org/",
						"@type": "Product",
						"url": "https://www.bugbusterslabs.com/dark-web-monitoring",
						"name": "Dark Web Monitoring",
						"description": "Stay ahead of threats with our dark web monitoring services. Secure your data from data leaks and breaches. Secure your sensitive information.",
						"review": {
							"@type": "Review",
							"reviewRating": {
								"@type": "Rating",
								"ratingValue": 4,
								"bestRating": 5
							},
							"author": {
								"@type": "Person",
								"name": "Lyon"
							}
						},
						"aggregateRating": {
							"@type": "AggregateRating",
							"ratingValue": 4.5,
							"reviewCount": 638
						}
					})}
				</script>
			</Helmet>
			<Header />
			<div class="uui-page-padding-3">
				<header class="uui-section_heroheader13-3">
					<div class="uui-page-padding-9">
						<header class="uui-section_heroheader05">
							<div class="uui-page-padding-17">
								<div class="uui-container-large-14">
									<div class="uui-padding-vertical-xhuge-15">
										<div class="w-layout-grid uui-heroheader05_component">
											<div class="uui-heroheader05_content">
												<div class="uui-space-small-11"></div>
												<h1 class="uui-heading-xlarge-9">
													Dark Web Monitoring Solutions
												</h1>
												<div class="uui-space-small-11"></div>
												<div class="uui-text-size-xlarge-9">
													Cybersecurity services that
													<span class="text-span-4">
														scan, analyze, and track
													</span>
													the dark web.
												</div>
												<div class="uui-space-large-8"></div>
											</div>
											<img
												src={darkWebImg}
												loading="lazy"
												id="w-node-eaad19bd-ed2c-6afb-d93b-b56ad5435669-25ef45b8"
												sizes="(max-width: 479px) 100vw, (max-width: 767px) 87vw, (max-width: 991px) 81vw, 32vw"
												alt=""
											/>
										</div>
									</div>
								</div>
							</div>
						</header>
						<div class="uui-container-large-8"></div>
					</div>
				</header>
				<header class="uui-section_heroheader01">
					<div class="uui-page-padding-3">
						<div class="uui-container-large-3"></div>
					</div>
				</header>
				<div class="uui-container-large-3"></div>
				<section class="uui-section_layout01">
					<div class="uui-page-padding-5">
						<div class="uui-container-large-5"></div>
					</div>
				</section>
				<section class="uui-section_layout01-2">
					<div class="uui-page-padding-17">
						<section class="uui-section_layout08-2">
							<div class="uui-page-padding-20">
								<div class="uui-container-large-17">
									<div class="uui-padding-vertical-xhuge-18">
										<div class="w-layout-grid uui-layout08_component-2">
											<div class="uui-layout08_content-2">
												<div class="uui-heading-subheading-12">Purpose</div>
												<div class="uui-layout08_item-list-2">
													<div
														id="w-node-_750b3ddf-98fb-4a5a-d18d-6884c2c462e2-25ef45b8"
														class="uui-layout08_item-2"
													>
														<div class="uui-layout08_item-icon-wrapper-2">
															<div class="uui-icon-1x1-xsmall-11 w-embed">
																<svg
																	width="currentWidth"
																	height="currentHeight"
																	viewbox="0 0 28 28"
																	fill="none"
																	xmlns="http://www.w3.org/2000/svg"
																>
																	<path
																		d="M0 14C0 6.26801 6.26801 0 14 0V0C21.732 0 28 6.26801 28 14V14C28 21.732 21.732 28 14 28V28C6.26801 28 0 21.732 0 14V14Z"
																		fill="#F4EBFF"
																	></path>
																	<path
																		fill-rule="evenodd"
																		clip-rule="evenodd"
																		d="M19.9457 8.62169L11.5923 16.6834L9.37568 14.315C8.96734 13.93 8.32568 13.9067 7.85901 14.2334C7.40401 14.5717 7.27568 15.1667 7.55568 15.645L10.1807 19.915C10.4373 20.3117 10.8807 20.5567 11.3823 20.5567C11.8607 20.5567 12.3157 20.3117 12.5723 19.915C12.9923 19.3667 21.0073 9.81169 21.0073 9.81169C22.0573 8.73836 20.7857 7.79336 19.9457 8.61002V8.62169Z"
																		fill="#9E77ED"
																	></path>
																</svg>
															</div>
														</div>
														<div class="uui-layout08_item-text-wrapper-2">
															<div class="uui-text-size-large-11">
																<strong>Detect Data Breaches: </strong>Uncover
																leaked or stolen confidential data for action.
															</div>
														</div>
													</div>
													<div
														id="w-node-_750b3ddf-98fb-4a5a-d18d-6884c2c462e8-25ef45b8"
														class="uui-layout08_item-2"
													>
														<div class="uui-layout08_item-icon-wrapper-2">
															<div class="uui-icon-1x1-xsmall-11 w-embed">
																<svg
																	width="currentWidth"
																	height="currentHeight"
																	viewbox="0 0 28 28"
																	fill="none"
																	xmlns="http://www.w3.org/2000/svg"
																>
																	<path
																		d="M0 14C0 6.26801 6.26801 0 14 0V0C21.732 0 28 6.26801 28 14V14C28 21.732 21.732 28 14 28V28C6.26801 28 0 21.732 0 14V14Z"
																		fill="#F4EBFF"
																	></path>
																	<path
																		fill-rule="evenodd"
																		clip-rule="evenodd"
																		d="M19.9457 8.62169L11.5923 16.6834L9.37568 14.315C8.96734 13.93 8.32568 13.9067 7.85901 14.2334C7.40401 14.5717 7.27568 15.1667 7.55568 15.645L10.1807 19.915C10.4373 20.3117 10.8807 20.5567 11.3823 20.5567C11.8607 20.5567 12.3157 20.3117 12.5723 19.915C12.9923 19.3667 21.0073 9.81169 21.0073 9.81169C22.0573 8.73836 20.7857 7.79336 19.9457 8.61002V8.62169Z"
																		fill="#9E77ED"
																	></path>
																</svg>
															</div>
														</div>
														<div class="uui-layout08_item-text-wrapper-2">
															<div class="uui-text-size-large-11">
																<strong>Prevent Identity Theft: </strong>Alert
																against personal info exposure to thwart fraud.
															</div>
														</div>
													</div>
													<div
														id="w-node-_750b3ddf-98fb-4a5a-d18d-6884c2c462ee-25ef45b8"
														class="uui-layout08_item-2"
													>
														<div class="uui-layout08_item-icon-wrapper-2">
															<div class="uui-icon-1x1-xsmall-11 w-embed">
																<svg
																	width="currentWidth"
																	height="currentHeight"
																	viewbox="0 0 28 28"
																	fill="none"
																	xmlns="http://www.w3.org/2000/svg"
																>
																	<path
																		d="M0 14C0 6.26801 6.26801 0 14 0V0C21.732 0 28 6.26801 28 14V14C28 21.732 21.732 28 14 28V28C6.26801 28 0 21.732 0 14V14Z"
																		fill="#F4EBFF"
																	></path>
																	<path
																		fill-rule="evenodd"
																		clip-rule="evenodd"
																		d="M19.9457 8.62169L11.5923 16.6834L9.37568 14.315C8.96734 13.93 8.32568 13.9067 7.85901 14.2334C7.40401 14.5717 7.27568 15.1667 7.55568 15.645L10.1807 19.915C10.4373 20.3117 10.8807 20.5567 11.3823 20.5567C11.8607 20.5567 12.3157 20.3117 12.5723 19.915C12.9923 19.3667 21.0073 9.81169 21.0073 9.81169C22.0573 8.73836 20.7857 7.79336 19.9457 8.61002V8.62169Z"
																		fill="#9E77ED"
																	></path>
																</svg>
															</div>
														</div>
														<div class="uui-layout08_item-text-wrapper-2">
															<div class="uui-text-size-large-11">
																<strong>
																	Intellectual Property Protection:
																</strong>
																Safeguard proprietary assets.
															</div>
														</div>
													</div>
													<div
														id="w-node-_28b6b126-6e94-90c1-4411-ef49dcc1e0ef-25ef45b8"
														class="uui-layout08_item-2"
													>
														<div class="uui-layout08_item-icon-wrapper-2">
															<div class="uui-icon-1x1-xsmall-11 w-embed">
																<svg
																	width="currentWidth"
																	height="currentHeight"
																	viewbox="0 0 28 28"
																	fill="none"
																	xmlns="http://www.w3.org/2000/svg"
																>
																	<path
																		d="M0 14C0 6.26801 6.26801 0 14 0V0C21.732 0 28 6.26801 28 14V14C28 21.732 21.732 28 14 28V28C6.26801 28 0 21.732 0 14V14Z"
																		fill="#F4EBFF"
																	></path>
																	<path
																		fill-rule="evenodd"
																		clip-rule="evenodd"
																		d="M19.9457 8.62169L11.5923 16.6834L9.37568 14.315C8.96734 13.93 8.32568 13.9067 7.85901 14.2334C7.40401 14.5717 7.27568 15.1667 7.55568 15.645L10.1807 19.915C10.4373 20.3117 10.8807 20.5567 11.3823 20.5567C11.8607 20.5567 12.3157 20.3117 12.5723 19.915C12.9923 19.3667 21.0073 9.81169 21.0073 9.81169C22.0573 8.73836 20.7857 7.79336 19.9457 8.61002V8.62169Z"
																		fill="#9E77ED"
																	></path>
																</svg>
															</div>
														</div>
														<div class="uui-layout08_item-text-wrapper-2">
															<div class="uui-text-size-large-11">
																<strong>
																	Compliance and Risk Management:
																</strong>
																Support data protection compliance and risk
																mitigation.
															</div>
														</div>
													</div>
												</div>
											</div>
											<div class="uui-layout08_content-2">
												<div class="uui-heading-subheading-12">Benefits</div>
												<div class="uui-layout08_item-list-2">
													<div
														id="w-node-_21805c69-7ff6-4cd4-c442-7485c2e929f9-25ef45b8"
														class="uui-layout08_item-2"
													>
														<div class="uui-layout08_item-icon-wrapper-2">
															<div class="uui-icon-1x1-xsmall-11 w-embed">
																<svg
																	width="currentWidth"
																	height="currentHeight"
																	viewbox="0 0 28 28"
																	fill="none"
																	xmlns="http://www.w3.org/2000/svg"
																>
																	<path
																		d="M0 14C0 6.26801 6.26801 0 14 0V0C21.732 0 28 6.26801 28 14V14C28 21.732 21.732 28 14 28V28C6.26801 28 0 21.732 0 14V14Z"
																		fill="#F4EBFF"
																	></path>
																	<path
																		fill-rule="evenodd"
																		clip-rule="evenodd"
																		d="M19.9457 8.62169L11.5923 16.6834L9.37568 14.315C8.96734 13.93 8.32568 13.9067 7.85901 14.2334C7.40401 14.5717 7.27568 15.1667 7.55568 15.645L10.1807 19.915C10.4373 20.3117 10.8807 20.5567 11.3823 20.5567C11.8607 20.5567 12.3157 20.3117 12.5723 19.915C12.9923 19.3667 21.0073 9.81169 21.0073 9.81169C22.0573 8.73836 20.7857 7.79336 19.9457 8.61002V8.62169Z"
																		fill="#9E77ED"
																	></path>
																</svg>
															</div>
														</div>
														<div class="uui-layout08_item-text-wrapper-2">
															<div class="uui-text-size-large-11">
																<strong>Proactive Security: </strong>AI-driven
																preemptive threat identification.
															</div>
														</div>
													</div>
													<div
														id="w-node-_21805c69-7ff6-4cd4-c442-7485c2e92a01-25ef45b8"
														class="uui-layout08_item-2"
													>
														<div class="uui-layout08_item-icon-wrapper-2">
															<div class="uui-icon-1x1-xsmall-11 w-embed">
																<svg
																	width="currentWidth"
																	height="currentHeight"
																	viewbox="0 0 28 28"
																	fill="none"
																	xmlns="http://www.w3.org/2000/svg"
																>
																	<path
																		d="M0 14C0 6.26801 6.26801 0 14 0V0C21.732 0 28 6.26801 28 14V14C28 21.732 21.732 28 14 28V28C6.26801 28 0 21.732 0 14V14Z"
																		fill="#F4EBFF"
																	></path>
																	<path
																		fill-rule="evenodd"
																		clip-rule="evenodd"
																		d="M19.9457 8.62169L11.5923 16.6834L9.37568 14.315C8.96734 13.93 8.32568 13.9067 7.85901 14.2334C7.40401 14.5717 7.27568 15.1667 7.55568 15.645L10.1807 19.915C10.4373 20.3117 10.8807 20.5567 11.3823 20.5567C11.8607 20.5567 12.3157 20.3117 12.5723 19.915C12.9923 19.3667 21.0073 9.81169 21.0073 9.81169C22.0573 8.73836 20.7857 7.79336 19.9457 8.61002V8.62169Z"
																		fill="#9E77ED"
																	></path>
																</svg>
															</div>
														</div>
														<div class="uui-layout08_item-text-wrapper-2">
															<div class="uui-text-size-large-11">
																<strong>Comprehensive Coverage: </strong>Monitor
																the anonymous web for a full security view.
															</div>
														</div>
													</div>
													<div
														id="w-node-_21805c69-7ff6-4cd4-c442-7485c2e92a09-25ef45b8"
														class="uui-layout08_item-2"
													>
														<div class="uui-layout08_item-icon-wrapper-2">
															<div class="uui-icon-1x1-xsmall-11 w-embed">
																<svg
																	width="currentWidth"
																	height="currentHeight"
																	viewbox="0 0 28 28"
																	fill="none"
																	xmlns="http://www.w3.org/2000/svg"
																>
																	<path
																		d="M0 14C0 6.26801 6.26801 0 14 0V0C21.732 0 28 6.26801 28 14V14C28 21.732 21.732 28 14 28V28C6.26801 28 0 21.732 0 14V14Z"
																		fill="#F4EBFF"
																	></path>
																	<path
																		fill-rule="evenodd"
																		clip-rule="evenodd"
																		d="M19.9457 8.62169L11.5923 16.6834L9.37568 14.315C8.96734 13.93 8.32568 13.9067 7.85901 14.2334C7.40401 14.5717 7.27568 15.1667 7.55568 15.645L10.1807 19.915C10.4373 20.3117 10.8807 20.5567 11.3823 20.5567C11.8607 20.5567 12.3157 20.3117 12.5723 19.915C12.9923 19.3667 21.0073 9.81169 21.0073 9.81169C22.0573 8.73836 20.7857 7.79336 19.9457 8.61002V8.62169Z"
																		fill="#9E77ED"
																	></path>
																</svg>
															</div>
														</div>
														<div class="uui-layout08_item-text-wrapper-2">
															<div class="uui-text-size-large-11">
																<strong>Cost-Efficiency: </strong>Prevent
																breaches and safeguard reputation.
															</div>
														</div>
													</div>
													<div
														id="w-node-_21805c69-7ff6-4cd4-c442-7485c2e92a11-25ef45b8"
														class="uui-layout08_item-2"
													>
														<div class="uui-layout08_item-icon-wrapper-2">
															<div class="uui-icon-1x1-xsmall-11 w-embed">
																<svg
																	width="currentWidth"
																	height="currentHeight"
																	viewbox="0 0 28 28"
																	fill="none"
																	xmlns="http://www.w3.org/2000/svg"
																>
																	<path
																		d="M0 14C0 6.26801 6.26801 0 14 0V0C21.732 0 28 6.26801 28 14V14C28 21.732 21.732 28 14 28V28C6.26801 28 0 21.732 0 14V14Z"
																		fill="#F4EBFF"
																	></path>
																	<path
																		fill-rule="evenodd"
																		clip-rule="evenodd"
																		d="M19.9457 8.62169L11.5923 16.6834L9.37568 14.315C8.96734 13.93 8.32568 13.9067 7.85901 14.2334C7.40401 14.5717 7.27568 15.1667 7.55568 15.645L10.1807 19.915C10.4373 20.3117 10.8807 20.5567 11.3823 20.5567C11.8607 20.5567 12.3157 20.3117 12.5723 19.915C12.9923 19.3667 21.0073 9.81169 21.0073 9.81169C22.0573 8.73836 20.7857 7.79336 19.9457 8.61002V8.62169Z"
																		fill="#9E77ED"
																	></path>
																</svg>
															</div>
														</div>
														<div class="uui-layout08_item-text-wrapper-2">
															<div class="uui-text-size-large-11">
																<strong>Expert Support: </strong>Access to
																cybersecurity experts for insights and incident
																response.
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</section>
						<div class="uui-container-large-14"></div>
					</div>
				</section>
			</div>
			<Footer />
		</>
	);
}
