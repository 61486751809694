import React from 'react'
import Header from './components/Header'
import Footer from './components/Footer'
import Signup from './Signup'
import { Helmet } from 'react-helmet'

const CustomerSignup = () => {
	return (
		<>
			<Helmet>
				<title>Customer Singup | AI Sentinel VDP Customer Login - Bug Busters Labs</title>
			</Helmet>
			<Header />
			<Signup loginLink="/customer/login" />
			<Footer />
		</>
	);
}

export default CustomerSignup
