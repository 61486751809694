import React from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";
import "./components/css/resource.css";
import customerDocs from "./components/images/Grad.png";
import researcherDocs from "./components/images/ppl.png";
import apiDocs from "./components/images/Tech.png";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

export default function Resources() {
  return (
    <>
      <Helmet>
        <title>Resources: Bug Busters Labs | Essential Cybersecurity Resources</title>
        <meta name="description" content="Resources & Access a wealth of cybersecurity resources at Bug Busters Labs, including guides, documentation, and tools to enhance your security efforts." />
      </Helmet>
      <Header />
      <section style={{ overflow: "hidden" }}>
        <div className="row mt-5 mb-4">
          <div>
            <h3 className="text-center">Welcome 👋</h3>
            <span className="text-center d-block">
              Bugbusterslabs User and API documentation
            </span>
          </div>
        </div>
        <div className="row mb-8">
          <div className="d-flex justify-content-center">
            <div
              className="resourceMainSearch"
              style={{ position: "relative" }}
            >
              <input
                className="searchInp"
                type="text"
                placeholder="Search documentation..."
              />
              <div className="searchIcn">
                <i className="fa-solid fa-magnifying-glass"></i>
              </div>
            </div>
          </div>
        </div>

        <div className="row mb-5">
          <div className="mb-4">
            <h5 className="text-center" style={{ color: "#000" }}>
              Get Started
            </h5>
            <span className="text-center d-block">
              Click on API, Customer, or Researcher Docs to get started
            </span>
          </div>
          <div className="row d-flex justify-content-center">
            <div className="resourceTypePart" style={{ display: "flex" }}>
              <div className=" col-4  " style={{ padding: "22px" }}>
                <Link to="/resources/docs/customer">
                  <div className="allDoc">
                    <div className="docImg">
                      <img src={customerDocs} alt="" />
                    </div>
                    <div className="mb-3">
                      <h6 style={{ color: "#000", textAlign: "center" }}>
                        Customer Docs
                      </h6>
                    </div>
                    <div className="docDetails">
                      <span style={{ textAlign: "center" }}>
                        Learn how to use our platform and get the most out of
                        your program
                      </span>
                    </div>
                  </div>
                </Link>
              </div>
              <div className=" col-4  " style={{ padding: "22px" }}>
                <Link to="/resources/docs/researcher">
                  <div className="allDoc">
                    <div className="docImg">
                      <img src={researcherDocs} alt="" />
                    </div>
                    <div className="mb-3">
                      <h6 style={{ color: "#000", textAlign: "center" }}>
                        Researcher Docs
                      </h6>
                    </div>
                    <div className="docDetails">
                      <span style={{ textAlign: "center" }}>
                        Learn how to use our platform and get the most out of
                        your program
                      </span>
                    </div>
                  </div>
                </Link>
              </div>
              <div className=" col-4  " style={{ padding: "22px" }}>
                <Link to="/resources/docs/apis">
                  <div className="allDoc">
                    <div className="docImg">
                      <img src={apiDocs} alt="" />
                    </div>
                    <div className="mb-3">
                      <h6 style={{ color: "#000", textAlign: "center" }}>
                        API Docs
                      </h6>
                    </div>
                    <div className="docDetails">
                      <span style={{ textAlign: "center" }}>
                        Learn how to use our platform and get the most out of
                        your program
                      </span>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
