import React, { useEffect, useRef, useState } from "react";
import jsPDF from "jspdf";
import "./AgreementModalStyle.css";
import { useLocation, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { getUserInfo } from "../../../../reducers/authSlice";
import HTTPService from "../../../../utils/axios";
import { toast } from "react-toastify";
import { getObjectKeys } from "../../../../utils/helperFunctions";
import moment from "moment";
import { customToast } from "../../../../App";

export default function AgreementModal({
  isOpen,
  onClose,
  onSubmit,
  setAgreementModal,
  setSafeHarbor,
  organizationName,
  customerName,
  customerId,
  programId,
  programTitle,
  entityName,
}) {
  let today = moment().format("DD-MM-YYYY");
  const { userInfo } = useSelector(getUserInfo);
  const [userdata, setUserData] = useState();
  const { id } = useParams();
  const uid = localStorage.getItem("userid");
  const { invokeApi } = HTTPService();
  const location = useLocation();

  const [checkedValue, setCheckedValue] = useState(false);
  const fetchUser = async () => {
    if (location.pathname.startsWith("/admin")) {
      let url =
        userInfo?.role === "sub_admin" ? `sub_admin/${uid}/` : `user/${uid}/`;
      invokeApi({
        method: "GET",
        url,
      })
        .then((data) => {
          setUserData(data);
        })
        .catch((error) => {
          customToast.error("Something went wrong!", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          return null;
        });
    } else if (location.pathname.startsWith("/customer")) {
      invokeApi({
        method: "GET",
        url: `customer/${uid}/`,
      })
        .then((data) => {
          setUserData(data);
        })
        .catch((error) => {
          customToast.error("Something went wrong!", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          return null;
        });
    } else if (location.pathname.startsWith("/researcher")) {
      invokeApi({
        method: "GET",
        url: `researcher/${uid}/`,
      })
        .then((data) => {
          setUserData(data);
          console.log("researcher details", userdata);
        })
        .catch((error) => {
          customToast.error("Something went wrong!", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          return null;
        });
    }

    return null;
  };

  useEffect(() => {
    if (getObjectKeys(userInfo).length > 0 && userInfo.role) fetchUser();
  }, [userInfo]);

  // const checkDocument = async () => {
  //   invokeApi({
  //     method: "GET",
  //     url: `/safe-harbor/${id}`,
  //   })
  //     .then((data) => {
  //       console.log("researcher details", data);
  //     })
  //     .catch((error) => {
  //       customToast.error("Something went wrong!", {
  //         position: toast.POSITION.TOP_RIGHT,
  //         autoClose: 1000,
  //       });
  //       return null;
  //     });
  // };
  // useEffect(() => {
  //   checkDocument();
  // }, []);

  const agreementText = [
    {
      topic: "Introduction:",
      detail: [
        `This Safe Harbour Agreement ('Agreement') sets forth the terms and conditions governing the responsible disclosure of security vulnerabilities as part of ${organizationName && organizationName
        }'s Bug Bounty Program ('Program'). By participating in the Program, you ('Researcher') agree to abide by its terms. `,
      ],
    },
    {
      topic: "1. Scope:",
      detail: [
        `1.1 The Program encourages responsible disclosure of security vulnerabilities in ${organizationName && organizationName
        }'s systems, applications, and services. `,
        `1.2 Researcher may only test systems and applications explicitly included in the Program's scope. Any testing outside the defined scope is strictly prohibited.`,
      ],
    },
    {
      topic: "2. Responsible Disclosure:",
      detail: [
        `2.1 Researcher agrees to report all discovered vulnerabilities promptly and responsibly to ${organizationName && organizationName
        } through the designated reporting channels. `,
        `2.2 The researcher shall refrain from disclosing any details of the vulnerability to third parties until ${organizationName && organizationName
        } has had a reasonable opportunity to address the issue. `,
        `2.3 The researcher shall give ${organizationName && organizationName
        } reasonable time to remediate the reported vulnerabilities before publicly disclosing any information regarding them. `,
      ],
    },
    {
      topic: "3. Safe Harbour: ",
      detail: [
        `3.1 ${organizationName && organizationName
        } commits not to pursue legal action against the Researcher for discovering and reporting vulnerabilities in good faith and compliance with the terms of this Agreement. `,
        `3.2 Researcher acknowledges that any unauthorized or malicious activities, including but not limited to exploitation or disclosure of vulnerabilities for personal gain, may result in legal action by ${organizationName && organizationName
        }.`,
      ],
    },
    {
      topic: "4. Acknowledgment and Recognition: ",
      detail: [
        `4.1 ${organizationName && organizationName
        } may publicly acknowledge and recognize the Researcher's contributions to the Program, including listing the Researcher's name on a hall of fame page or providing certificates or badges. `,
        `4.2 The researcher agrees that any acknowledgment or recognition provided by ${organizationName && organizationName
        } does not constitute an endorsement of the Researcher's activities outside the scope of the Program. `,
      ],
    },
    {
      topic: "5. Confidentiality: ",
      detail: [
        `5.1 Researcher shall treat all information obtained during participation in the Program as confidential and shall not disclose such information to any third party without the prior written consent of ${organizationName && organizationName
        }.`,
      ],
    },
    {
      topic: "6. Compliance with Laws: ",
      detail: [
        `6.1 The researcher agrees to comply with all applicable laws, regulations, and ethical standards regarding participation in the Program.`,
      ],
    },
    {
      topic: "7. Governing Law and Jurisdiction: ",
      detail: [
        `7.1 This Agreement shall be governed by and construed by the laws of Tamilnadu, India, without regard to its conflict of law principles.`,
        `7.2 Any dispute arising out of or in connection with this Agreement shall be subject to the exclusive jurisdiction of the courts of Tamilnadu, India.`,
      ],
    },
    {
      topic: "8. Termination: ",
      detail: [
        `8.1 ${organizationName && organizationName
        } reserves the right to terminate the Researcher's participation in the Program at any time for any reason, with or without cause. `,
        `8.2 Upon termination of participation, the Researcher shall cease all testing activities and return or destroy any confidential information obtained during participation in the Program.`,
      ],
    },
    {
      topic: "9. Modification: ",
      detail: [
        `9.1 ${organizationName && organizationName
        } reserves the right to modify or amend this Agreement. Any changes will be effective immediately upon posting the revised Agreement on ${organizationName && organizationName
        }'s website. `,
      ],
    },
    {
      topic: "10. Entire Agreement: ",
      detail: [
        `10.1 This Agreement constitutes the entire agreement between the parties concerning the subject matter hereof and supersedes all prior and contemporaneous agreements and understandings, whether written or oral.`,
      ],
    },
  ];
  const pdfRef = useRef(null);
  const downloadPdf = () => {
    const input = pdfRef.current;
    const pdf = new jsPDF("p", "mm", "a4", true);

    pdf.html(input, {
      callback: async function (pdf) {
        const pdfBlob = pdf.output("blob");

        const formData = new FormData();
        formData.append("document_path", pdfBlob, "agreement.pdf");
        formData.append("program_id", id);
        formData.append("user_id", uid);

        try {
          await invokeApi({
            method: "POST",
            url: "/safe-harbor/",
            data: formData,
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
          customToast.success("PDF uploaded successfully!", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          setAgreementModal(false);
          setSafeHarbor(true);
          pdf.save("agreement.pdf");
        } catch (error) {
          customToast.error("Failed to upload PDF!", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
        }
      },
      x: 2,
      y: 2,
      html2canvas: {
        scale: 0.15,
      },
    });
  };

  return (
    <>
      {isOpen && (
        <div className="changeStatus">
          <div
            id="agreement-content"
            className="changeStatusBody"
            style={{ width: "68%", overflowY: "scroll", height: "85vh" }}
          >
            <div ref={pdfRef} style={{ marginTop: "60px" }}>
              <div className="text-center" style={{ marginTop: "30px" }}>
                <h3>Program Safe Harbour Agreement</h3>
                <h3>Bugbusterslabs Bug Bounty Platform</h3>
              </div>
              <div className="mx-5 mt-4">
                {agreementText.map((item) => (
                  <div key={item.topic}>
                    <p className="agreementHeading">{item.topic}</p>
                    <p className="agreementPara">
                      {item.detail.map((details, index) => (
                        <div key={index}>{details}</div>
                      ))}
                    </p>
                  </div>
                ))}
                <p className="agreementPara">
                  By participating in the Bug Bounty Program, Researcher
                  acknowledges that they have read, understood, and agree to be
                  bound by the terms and conditions of this Safe Harbour
                  Agreement.
                </p>
                <div>
                  <div
                    className="row"
                    style={{
                      color: "#000",
                      fontWeight: "600",
                      fontSize: "20px",
                    }}
                  >
                    <div className="d-flex">
                      <div className="col-3">Program ID</div>
                      <div className="col-1">:</div>
                      <div className="col-7">{programId}</div>
                    </div>
                    <div className="d-flex">
                      <div className="col-3">Program Title</div>
                      <div className="col-1">:</div>
                      <div className="col-7">{programTitle}</div>
                    </div>
                    <div className="d-flex">
                      <div className="col-3">Entity Name </div>
                      <div className="col-1">:</div>
                      <div className="col-7">
                        {organizationName && organizationName}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row mt-4" style={{ color: "#000" }}>
                  <span>Signed:</span>
                  <div className="col-6">
                    <div>
                      <p className="mb-0">{customerName && customerName}</p>
                      <p>{customerId && customerId}</p>
                    </div>
                  </div>
                  {
                    userInfo?.role === 'researcher' && <>
                      <div className="col-6 text-end">
                        <div>
                          <p className="mb-0">
                            {userdata &&
                              userdata.first_name &&
                              userdata.last_name &&
                              `${userdata.first_name} ${userdata.last_name}`}
                          </p>
                          <p>{userdata && userdata.user_id}</p>
                        </div>
                      </div>
                      <span className="text-end">{today}</span>
                    </>
                  }
                </div>

                {userInfo && userInfo.role === "researcher" && (
                  <div className="row mt-4">
                    <div>
                      <input
                        type="checkbox"
                        checked={checkedValue && true}
                        onChange={() => setCheckedValue(!checkedValue)}
                      />
                      <span className="text-center">
                        {" "}
                        I,{" "}
                        {userdata &&
                          userdata.first_name &&
                          userdata.last_name &&
                          `${userdata.first_name} ${userdata.last_name}`}
                        , hereby acknowledge and agree to abide by the terms and
                        conditions outlined in the program agreement. I
                        understand the responsibilities and expectations
                        associated with participation in the program, and I
                        commit to fulfilling them to the best of my ability. By
                        signing this declaration, I affirm my understanding of
                        the agreement and my willingness to comply with its
                        provisions.
                      </span>
                    </div>
                  </div>
                )}
              </div>

              {
                userInfo?.role !== 'researcher' &&
                <h6 className="text-center">
                  "The system-generated agreement does not require any further signatures."</h6>
              }
            </div>
            <div className="changeStatusBodyLastPart">
              <button className="changeStatusBtnClose text-white" onClick={onClose}>
                Close
              </button>
              {
                userInfo?.role === 'researcher' &&
                <button
                  disabled={checkedValue === true ? false : true}
                  onClick={downloadPdf}
                  className="changeStatusBtnSubmit"
                >
                  Submit
                </button>}

            </div>
          </div>
        </div>
      )}
    </>
  );
}
