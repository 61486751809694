import React, { useState } from "react";
import { Link } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import bugbounty from "./components/images/BugBounty_1.png";
import processImg from "./components/images/Process_Bounty.png";
import { Helmet } from "react-helmet";

// import "./components/css/BugBounty.css";

export default function BugBounty() {
	return (
		<>
			<Helmet>
				<title>Bug Bounty | BugBusters Labs AI Traige Security Bounty Programs </title>
				<meta name="description" content="BugBusters Labs Bug bounty is the most secure system with ethical hackers. whom find and report vulnerabilities or bugs to application developers." />
				<script type="application/ld+json">
					{JSON.stringify({
						"@context": "https://schema.org/",
						"@type": "Product",
						"url": "https://www.bugbusterslabs.com/bug-bounty",
						"name": "Bug Bounty Platform",
						"description": "BugBusters Labs Bug bounty is the most secure system with ethical hackers, who find and report vulnerabilities or bugs to application developers.",
						"review": {
							"@type": "Review",
							"reviewRating": {
								"@type": "Rating",
								"ratingValue": 4,
								"bestRating": 5
							},
							"author": {
								"@type": "Person",
								"name": "Michel Kong"
							}
						},
						"aggregateRating": {
							"@type": "AggregateRating",
							"ratingValue": 4.5,
							"reviewCount": 389
						}
					})}
				</script>
			</Helmet>
			<Header />
			<header className="uui-section_heroheader13-3">
				<div className="uui-page-padding-8">
					<header className="uui-section_heroheader05">
						<div className="uui-page-padding-17">
							<div className="uui-container-large-14">
								<div className="uui-padding-vertical-xhuge-15">
									<div className="w-layout-grid uui-heroheader05_component">
										<div className="uui-heroheader05_content">
											<div className="uui-space-small-11"></div>
											<h1 className="uui-heading-xlarge-9">
												Bug Bounty Platform
											</h1>
											<div className="uui-space-small-11"></div>
											<div className="uui-text-size-xlarge-9">
												<strong className="bold-text">Secure</strong> with a{" "}
												<span className="text-span-3">
													<strong>Purpose</strong>
												</span>
												: Join Our Bug Bounty Adventure!
											</div>
										</div>
										<img
											src={bugbounty}
											loading="lazy"
											id="w-node-_42bbbbbc-579c-618f-9b09-50596882b682-6882b676"
											sizes="(max-width: 479px) 100vw, (max-width: 767px) 92vw, (max-width: 991px) 87vw, 37vw"
											alt="Illustration depicting Bug Bounty"
										/>
									</div>
								</div>
							</div>
						</div>
					</header>
				</div>
			</header>
			<section className="uui-section_layout37">
				<div className="uui-page-padding-12">
					<div className="uui-container-large-9">
						<div className="uui-padding-vertical-xhuge-10">
							<div className="uui-text-align-center-7">
								<div className="uui-max-width-large-8 align-center">
									<h2 className="uui-heading-medium-6">Process</h2>
									<div className="uui-space-xsmall-6"></div>
									<div className="uui-space-medium-3"></div>
								</div>
							</div>
							<img
								src={processImg}
								loading="lazy"
								sizes="(max-width: 479px) 93vw, (max-width: 767px) 96vw, 94vw"
								alt=""
							/>
						</div>
					</div>
				</div>
			</section>
			<div className="uui-page-padding-3">
				<div className="uui-container-large-3"></div>
				<section className="uui-section_layout68">
					<div className="uui-page-padding-3">
						<div className="uui-container-large-3">
							<div
								className="uui-padding-vertical-xhuge-3"
								style={{ paddingTop: "0px", paddingBottom: "4rem" }}
							>
								<div className="w-layout-grid uui-layout68_component">
									<div
										id="w-node-b62c15c1-2f66-1401-60c5-2750f3d8a630-4bc1c432"
										className="uui-layout68_content"
									>
										<div className="w-layout-grid uui-layout68_item-list">
											<div
												id="w-node-b62c15c1-2f66-1401-60c5-2750f3d8a632-4bc1c432"
												className="uui-layout68_item"
											>
												<div className="uui-layout68_item-text-wrapper">
													<h3 className="uui-heading-xsmall">
														<strong> Faster and Accurate Triaging</strong>
													</h3>
													<div className="uui-space-xxsmall-3"></div>
													<div className="uui-text-size-medium-3">
														Leveraging AI-driven triage systems for enhanced
														efficiency, faster response times, and consistent
														case prioritization. This results in resource
														optimization, ensuring swift resolution of critical
														issues and elevating overall customer satisfaction.
													</div>
													<div className="uui-space-xsmall-2"></div>
												</div>
											</div>
											<div
												id="w-node-b62c15c1-2f66-1401-60c5-2750f3d8a642-4bc1c432"
												className="uui-layout68_item"
											>
												<div className="uui-layout68_item-text-wrapper">
													<h3 className="uui-heading-xsmall">
														<strong>Tailored Vulnerability Rating</strong>
													</h3>
													<div className="uui-space-xxsmall-3"></div>
													<div className="uui-text-size-medium-3">
														We employ a customized system to categorize and rate
														vulnerabilities based on the unique context of each
														customer&#x27;s business. This tailored approach
														empowers more effective vulnerability management and
														resource allocation.
													</div>
													<div className="uui-space-xsmall-2"></div>
												</div>
											</div>
											<div
												id="w-node-b62c15c1-2f66-1401-60c5-2750f3d8a652-4bc1c432"
												className="uui-layout68_item"
											>
												<div className="uui-layout68_item-text-wrapper">
													<h3 className="uui-heading-xsmall">
														<strong>
															Efficient Collaboration and Communication
														</strong>
													</h3>
													<div className="uui-space-xxsmall-3"></div>
													<div className="uui-text-size-medium-3">
														Bugbusterslabs prioritizes efficient security
														collaboration through clear guidelines, active
														researcher engagement, feedback and report
														collaboration, teamwork with internal security
														units, and continuous program improvement.
													</div>
													<div className="uui-space-xsmall-2"></div>
												</div>
											</div>
											<div
												id="w-node-_802e68f2-87d4-467e-2ed3-e04f04f8bd8e-4bc1c432"
												className="uui-layout68_item"
											>
												<div className="uui-layout68_item-text-wrapper">
													<h3 className="uui-heading-xsmall">
														<strong>Seamless Integration</strong>
													</h3>
													<div className="uui-space-xxsmall-3"></div>
													<div className="uui-text-size-medium-3">
														Our platform seamlessly integrates within an
														organization&#x27;s security ecosystem and
														processes. It incorporates essential bug bounty
														programs to enhance vulnerability management and
														overall security practices.
													</div>
													<div className="uui-space-xsmall-2"></div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<h3 className="uui-heading-xsmall">
									<strong>
										<br />
										AI - Chat Bot{" "}
									</strong>
								</h3>
								<div className="uui-text-size-medium-3">
									Delivering Customised Insights and Predictive analytics for
									Researchers and Clients through our AI Technology.
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>

			{/* <header>
				<div className="top-heading text-center">
					<h3>Get Started with the Bugbusterslabs Platform</h3>
				</div>

				<div className="app-container">
					
					<div className="poster-container">
						<img src={SolutionImg} alt="Poster" />
					</div>

          <div className="form-container">
						<h2>Chat with a Security Expart</h2>
						<p>
							We'll work to understand your business and provide a customize
							demo focused your unique security needs
						</p>
						<form onSubmit={handleSubmit}>
							<input
								type="text"
								name="fname"
								placeholder="First Name"
								value={formData.fname}
								onChange={handleChange}
								required
							/>
							<input
								type="text"
								name="lname"
								placeholder="Last Name"
								value={formData.lname}
								onChange={handleChange}
								required
							/>
							<input
								type="email"
								name="email"
								placeholder="Work Email"
								value={formData.email}
								onChange={handleChange}
								required
							/>
							<input
								type="text"
								name="cname"
								placeholder="Company Name"
								value={formData.cname}
								onChange={handleChange}
								required
							/>
							<input
								type="text"
								name="jobTitle"
								placeholder="Job Title"
								value={formData.jobTitle}
								onChange={handleChange}
								required
							/>
							<div className="BB-PhNo">
                <select name="countryCode" id="countryCode">
                  <option value="">--</option>
									<option value="+91">IN</option>
									<option value="+1">US</option>
									<option value="+2">UK</option>
                </select>
              <input
								type="tel"
								name="phNo"
								placeholder="Phone Number"
								value={formData.phNo}
								onChange={handleChange}
								required
							/>
              </div> 
							
							<select name="county" id="county" >
								<option value={formData.country} >---Country---</option>
                <option value="India" >India</option>
                <option value='US' >US</option>
                <option value="UK" >UK</option>
                <option value="Canada" >Canada</option>
							</select>

							<select name="bugBount" id="bugBount">
								<option value={formData.BugBounty}>---Bug Bounty Program----</option>
                <option value="Type A">Type A</option>
                <option value="Type B">Type B</option>
                <option value="Type C">Type C</option>
							</select>

							<button className="bugbountySubmitBtn" type="submit">Submit</button>
						</form>
					</div>


				</div>
				<div>
					<footer className="BB-footer">
						<div>
							<h5>TECHNOLOGY PARTNER</h5>
						</div>
						<div className="BB-footer-logo">
							<li>
								<ul>
									{" "}
									<img src={TwilloLogo} alt="#" />{" "}
								</ul>
								<ul>
									<img src={MicrosoftLogo} alt="#" />
								</ul>
								<ul>
									<img src={AzureLogo} alt="#" />
								</ul>
								<ul>
									<p>etc..</p>
								</ul>
								
							</li>
						</div>
					</footer>
				</div>
			</header>*/}

			<Footer />
		</>
	);
}
