import React, { useEffect } from "react";
import HTTPService from "../../../utils/axios";
import { useLocation, useNavigate } from "react-router-dom";
import { customToast } from "../../../App";
import { toast } from "react-toastify";

const RazorpayPayment = () => {
  const { invokeApi } = HTTPService();
  const location = useLocation();
  const navigate = useNavigate();
  const { data } = location.state || {};
  const handleValidatePayment = (data) => {
    invokeApi({
      method: "POST",
      url: "transactions/razorpay-validate-order",
      data
    }).then((res) => {
      navigate("/customer/payment/wallet");
    }).catch((error) => {
      navigate("/customer/payment/wallet");
      customToast.error("Unable to add Money!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    });
  }
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      if (data)
        handlePayment(data);
    };

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const handlePayment = (props) => {
    const options = {
      key: props.razorpay_key,
      currency: "INR",
      name: "Bugbusterslabs",
      description: "Add Balance",
      image: "https://example.com/your_logo",
      order_id: props.id,
      handler: function (response) {
        // call api here
        handleValidatePayment(response)
      },
      prefill: {
        name: props.name,
        email: props.email,
        contact: props.contact,
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#3399cc",
      },
    };
    const rzp = new window.Razorpay(options);
    rzp.on("payment.failed", function (response) {
      navigate("/customer/payment/wallet");
      customToast.error(response.error.reason, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    });

    rzp.open();
  };

  return <div style={{ display: "flex", justifyContent: 'center', alignItems: 'center', height: 'calc(100vh - 0px)' }}>
    <div style={{ fontSize: '16px' }}>Payment in Process...</div>
  </div>;
};

export default RazorpayPayment;
