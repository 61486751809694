import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
	Navigate,
	Outlet,
	Route,
	Routes,
	useLocation,
	useNavigate,
} from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import CircularLoader from "./BackEnd/CircularLoader/CircularLoader";
import ForgotPassword from "./BackEnd/common/ForgotPassword/ForgotPassword";
import ForgotPasswordSuccess from "./BackEnd/common/ForgotPassword/ForgotPasswordSuccess";
import JiraIntegrationPage from "./BackEnd/common/JiraIntegrationPage";
import SlackIntegrationPage from "./BackEnd/common/SlackIntegrationPage";
import AppContext from "./BackEnd/Context/AppContext";
import Failed from "./BackEnd/Customer-part/Payments/Failed";
import Success from "./BackEnd/Customer-part/Payments/Success";
import CustomerBasic from "./BackEnd/Customer-part/signin/CustomerBasic";
import Logout from "./BackEnd/logout/Logout";
import ResearcherBasic from "./BackEnd/Researcher-part/signin/ResearcherBasic";
import Resetpassword from "./BackEnd/resetpassword/Resetpassword";
import Basic from "./BackEnd/signin/Basic";
import Userlockscreen from "./BackEnd/userlockscreen/Userlockscreen";
import Verifymail from "./BackEnd/verifymail/Verifymail";
import Aboutus from "./FrontEnd/Aboutus";
import BugBounty from "./FrontEnd/BugBounty";
import CancleRefund from "./FrontEnd/CancleRefund";
import Contactus from "./FrontEnd/Contactus";
import CustomerSignup from "./FrontEnd/CustomerSignup";
import DarkWebMonitoring from "./FrontEnd/DarkWebMonitoring";
import ExternalAttackSurface from "./FrontEnd/ExternalAttackSurface";
import Faq from "./FrontEnd/Faq";
import Home from "./FrontEnd/Home";
import LeaderBoardPage from "./FrontEnd/LeaderBoardPage";
import Partner from "./FrontEnd/Partner";
import PenTesting from "./FrontEnd/PenTesting";
import Privacypolicy from "./FrontEnd/Privacypolicy";
import Programs from "./FrontEnd/Programs";
import RedTeaming from "./FrontEnd/RedTeaming";
import RegSucessfully from "./FrontEnd/RegSucessfully";
import ResearcherDocs from "./FrontEnd/ResearcherDocs";
import ResearcherSignup from "./FrontEnd/ResearcherSignup";
import Resources from "./FrontEnd/Resources";
import SecurityPolicy from "./FrontEnd/SecurityPolicy";
import TermsConditions from "./FrontEnd/TermsConditions";
import TryBugBounty from "./FrontEnd/TryBugBounty";
import AdminLayout from "./layout/AdminLayout";
import CustomerLayout from "./layout/CustomerLayout";
import ResearcherLayout from "./layout/ResearcherLayout";
import { setPermission, setUserInfo } from "./reducers/authSlice";
import ScrollToTop from "./ScrollToTop";
import successIcon from "./assets/success.png"
import errorIcon from "./assets/error.png"
import CmsNavigation from "./FrontEnd/CmsNavigation";
const defaultSuccessIcon = <img src={successIcon} alt="" />;
const defaultErrorIcon = <img src={errorIcon} alt="" />;

export const customToast = {
	success: (message, options) =>
		toast.success(message, { icon: defaultSuccessIcon, ...options }),
	error: (message, options) =>
		toast.error(message, { icon: defaultErrorIcon, ...options }),
};
function App() {
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useDispatch();
	const adminBaseUrl = window.location.pathname.startsWith("/admin");
	const subadminBaseUrl = window.location.pathname.startsWith("/subadmin");

	const customerBaseUrl = window.location.pathname.startsWith("/customer");
	const researcherBaseUrl = window.location.pathname.startsWith("/researcher");

	const researcherlogin = location.pathname.match("/researcher/login");
	const customerlogin = location.pathname.match("/customer/login");
	const adminlogin = location.pathname.match("/admin/login");
	const subadminlogin = location.pathname.match("/subadmin/login");

	const Customersignup = location.pathname.match("/customer/signup");
	const Researchersignup = location.pathname.match("/researcher/signup");
	const AdminForgotPass = location.pathname.match("/admin/forgotpassword");
	const ResearcherForgotPass = location.pathname.match(
		"/researcher/forgotpassword"
	);
	const CustomerForgotPass = location.pathname.match(
		"/customer/forgotpassword"
	);

	const [showLockScreen, setShowLockScreen] = useState(false);
	const [circularLoading, setCircularLoading] = useState(false);
	const [loading, setLoading] = useState(false);
	const [memberData, setMemberData] = useState({
		handlesubmit: null,
		openmodal: false,
		basicinfo: false,
		changePassword: false,
		payment: false,
		social: false,
		skill: false,
		isEdit: "",
	});
	console.log(memberData);
	// const navigate = useNavigate();
	// const [userData, setUserData] = useState(JSON.parse(localStorage.getItem("token")));

	const token = localStorage.getItem("token");
	const role = localStorage.getItem("role");

	useEffect(() => {
		const localUserInfo = localStorage.getItem("userInfo");
		const rolePermissions = localStorage.getItem("rolePermissions");
		if (localUserInfo) {
			const userParsedInfo = JSON.parse(localUserInfo);
			if (userParsedInfo)
				dispatch(
					setUserInfo({
						...userParsedInfo,
					})
				);
		}
		if (rolePermissions) {
			const rolePermissionParsedInfo = JSON.parse(rolePermissions);
			if (rolePermissionParsedInfo)
				dispatch(setPermission([...rolePermissionParsedInfo]));
		}
		setShowLockScreen(localStorage.getItem("showLockScreen") == 'true');
	}, []);
	useEffect(() => {
		let timeoutId;
		const handleUnload = () => {
			localStorage.clear();
		};

		const resetTimer = () => {
			clearTimeout(timeoutId);

			if (
				subadminlogin ||
				adminlogin ||
				subadminlogin ||
				researcherlogin ||
				customerlogin ||
				Customersignup ||
				Researchersignup ||
				AdminForgotPass ||
				ResearcherForgotPass ||
				CustomerForgotPass
			) {
				setShowLockScreen(false);
				localStorage.setItem("showLockScreen", 'false');
			}
			if (
				subadminlogin ||
				adminBaseUrl ||
				customerBaseUrl ||
				researcherBaseUrl
			) {
				timeoutId = setTimeout(() => {
					setShowLockScreen(true);
					localStorage.setItem("showLockScreen", 'true');
					showLockScreen &&
						window.addEventListener("beforeunload", handleUnload);
				}, 300 * 1000); // 300 seconds
			} else {
				setShowLockScreen(false);
				localStorage.setItem("showLockScreen", 'false');
			}
		};

		// Reset the timer whenever there is user activity
		const handleActivity = () => {
			resetTimer();
		};

		// Initial setup
		resetTimer();

		// Event listeners for user activity
		window.addEventListener("mousemove", handleActivity);
		window.addEventListener("keydown", handleActivity);

		// Cleanup function
		return () => {
			clearTimeout(timeoutId);
			window.removeEventListener("mousemove", handleActivity);
			window.removeEventListener("keydown", handleActivity);
			window.removeEventListener("beforeunload", handleUnload);
		};
	}, [
		location,
		adminBaseUrl,
		subadminBaseUrl,
		researcherBaseUrl,
		customerBaseUrl,
		researcherlogin,
		adminlogin,
		subadminlogin,
		customerlogin,
		subadminlogin,
		Customersignup,
		Researchersignup,
		showLockScreen,
	]);

	return (
		<>
			<AppContext.Provider
				value={{
					circularLoading,
					setCircularLoading,
					setShowLockScreen,
					setLoading,
					loading,
					memberData,
					setMemberData,
				}}
			>
				{showLockScreen ? (
					<Userlockscreen />
				) : (
					<>
						<ScrollToTop />
						{circularLoading && <CircularLoader size={100} />}
						<Routes>
							<Route path="/resetpassword/:type/:code/:token" element={<Resetpassword />} />
							<Route
								path={"/payment/success"}
								element={<Success />}
							/>
							<Route
								path={"/payment/failed"}
								element={<Failed />}
							/>
							<Route
								path={"/admin/forgotpassword"}
								element={<ForgotPassword />}
							/>
							<Route
								path={"/researcher/forgotpassword"}
								element={<ForgotPassword />}
							/>
							<Route
								path={"/customer/forgotpassword"}
								element={<ForgotPassword />}
							/>
							<Route
								path="/admin/forgotpasswordsuccess"
								element={<ForgotPasswordSuccess />}
							/>
							<Route
								path="/researcher/forgotpasswordsuccess"
								element={<ForgotPasswordSuccess />}
							/>
							<Route
								path="/customer/forgotpasswordsuccess"
								element={<ForgotPasswordSuccess />}
							/>

							<Route path="/admin/login" element={<Basic />} />
							<Route path="/subadmin/login" element={<Basic subAdmin={true} />} />

							<Route path="/researcher/login" element={<ResearcherBasic />} />
							<Route path="/customer/login" element={<CustomerBasic />} />
							<Route path="/customer/signup" element={<CustomerSignup />} />
							<Route path="/researcher/signup" element={<ResearcherSignup />} />
							<Route path="/researcher/logout" element={<Logout />} />
							<Route path="/admin/logout" element={<Logout />} />
							<Route path="/customer/logout" element={<Logout />} />
							<Route path="/subadmin/logout" element={<Logout />} />
							<Route path="/leaderboard" element={<LeaderBoardPage />} />

							{token && (
								<>
									<Route path="/verifymail" element={<Verifymail />} />
									{role === "admin" &&
										!adminBaseUrl &&
										(researcherBaseUrl || customerBaseUrl) ? (
										<Route
											path={location.pathname}
											element={<Navigate to="/admin/home" replace />}
										/>
									) : role === "customer" &&
										!customerBaseUrl &&
										(researcherBaseUrl || adminBaseUrl) ? (
										<Route
											path={location.pathname}
											element={<Navigate to="/customer/home" replace />}
										/>
									) : role === "researcher" &&
										!researcherBaseUrl &&
										(adminBaseUrl || customerBaseUrl) ? (
										<Route
											path={location.pathname}
											element={<Navigate to="/researcher/home" replace />}
										/>
									) : location.pathname === "/researcher/signup" ? (
										<Route
											path={location.pathname}
											element={<Navigate to="/researcher/signup" replace />}
										/>
									) : location.pathname === "/customer/signup" ? (
										<Route
											path={location.pathname}
											element={<Navigate to="/customer/signup" replace />}
										/>
									) : (
										""
									)}

									{/* admin routes */}
									<Route path="/admin/*" element={<AdminLayout />} />
									{/* <Route path="/subadmin/*" element={<AdminLayout />} /> */}

									{/* reports */}

									{/* Customer Part */}
									<Route path="/customer/*" element={<CustomerLayout />} />

									{/* researcher part */}
									<Route path="/researcher/*" element={<ResearcherLayout />} />
								</>
							)}

							{!token && adminBaseUrl ? (
								<Route
									path={location.pathname}
									element={<Navigate to="/admin/login" replace />}
								/>
							) : !token && customerBaseUrl ? (
								<Route
									path={location.pathname}
									element={<Navigate to="/customer/login" replace />}
								/>
							) : !token && researcherBaseUrl ? (
								<Route
									path={location.pathname}
									element={<Navigate to="/researcher/login" replace />}
								/>
							) : !token && subadminBaseUrl ? (
								<Route
									path={location.pathname}
									element={<Navigate to="/subadmin/login" replace />}
								/>
							) : Customersignup ? (
								<Route
									path={location.pathname}
									element={<Navigate to="/customer/signup" replace />}
								/>
							) : Researchersignup ? (
								<Route
									path={location.pathname}
									element={<Navigate to="/researcher/signup" replace />}
								/>
							) : location.pathname === "/admin/logout" ? (
								<Route
									path={location.pathname}
									element={<Navigate to="/admin/logout" replace />}
								/>
							) : location.pathname === "/researcher/logout" ? (
								<Route
									path={location.pathname}
									element={<Navigate to="/researcher/logout" replace />}
								/>
							) : location.pathname === "/customer/logout" ? (
								<Route
									path={location.pathname}
									element={<Navigate to="/customer/logout" replace />}
								/>
							) : (
								""
							)}
						</Routes>

						<Outlet />

						{/* front end Design */}

						<Routes>
							<Route path="/" element={<Home />} />
							<Route path="/bug-bounty" element={<BugBounty />} />
							<Route path="/try-bugbounty" element={<TryBugBounty />} />
							<Route
								path="/resources/docs/researcher"
								element={<ResearcherDocs />}
							/>
							<Route
								path="/resources/docs/customer"
								element={<ResearcherDocs />}
							/>
							<Route path="/resources/docs/apis" element={<ResearcherDocs />} />
							<Route path="/programs" element={<Programs />} />
							<Route path="/resources" element={<Resources />} />
							<Route path="/aboutus" element={<Aboutus />} />
							<Route path="/contactus" element={<Contactus />} />
							<Route path="/faq" element={<Faq />} />
							<Route path="/pentesting" element={<PenTesting />} />
							<Route
								path="/externalattacksurface"
								element={<ExternalAttackSurface />}
							/>
							<Route path="/redteaming" element={<RedTeaming />} />
							<Route
								path="/darkwebmonitoring"
								element={<DarkWebMonitoring />}
							/>
							<Route path="/privacypolicy" element={<Privacypolicy />} />
							<Route path="/terms&condition" element={<TermsConditions />} />
							<Route path="/cancle&refund" element={<CancleRefund />} />
							<Route path="/securitypolicy" element={<SecurityPolicy />} />
							<Route path="/partner" element={<Partner />} />
							<Route path="/reg-successfully" element={<RegSucessfully />} />
							{/* <Route path="/customer/signup" element={<CustomerSignup />} />
							<Route path="/researcher/signup" element={<ResearcherSignup />} /> */}
							<Route path="/slack-integration" element={<SlackIntegrationPage />} />
							<Route path="/jira-integration" element={<JiraIntegrationPage />} />
							<Route path="/cms/:name" element={<CmsNavigation />} />

						</Routes>
					</>
				)}
				<ToastContainer />
			</AppContext.Provider>
		</>
	);
}

export default App;
