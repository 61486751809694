import React, { useContext, useEffect, useState } from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { Link, useLocation } from "react-router-dom";
import Accordion from "./components/Accordion";
import HTTPService from "../utils/axios";
import AppContext from "../BackEnd/Context/AppContext";
import { Helmet } from "react-helmet";

export default function ResearcherDocs() {
  const { setCircularLoading } = useContext(AppContext);

  const location = useLocation();
  const [sideBarData, setSideBarData] = useState([]);
  const [pageDetails, setPageDetails] = useState([]);
  const [category, setCategory] = useState([]);
  const [selectedPageContent, setSelectedPageContent] = useState("");

  const customer = location.pathname === "/resources/docs/customer";
  const researcher = location.pathname === "/resources/docs/researcher";
  const apis = location.pathname === "/resources/docs/apis";
  const { invokeApi } = HTTPService();

  useEffect(() => {
    if (customer) {
      setCircularLoading(true);
      invokeApi({
        method: "GET",
        url: "/cms_pages/?user_type=Docs-Customer&cms_status=active",
        noToken: true,
      })
        .then((data) => {
          setCircularLoading(false);
          setPageDetails(data.results);
        })
        .catch((error) => console.log(error));
    } else if (researcher) {
      setCircularLoading(true);
      invokeApi({
        method: "GET",
        url: "/cms_pages/?user_type=Docs-Researcher&cms_status=active",
        noToken: true,
      })
        .then((data) => {
          setCircularLoading(false);
          setPageDetails(data.results);
        })
        .catch((error) => console.log(error));
    } else if (apis) {
      setCircularLoading(true);
      invokeApi({
        method: "GET",
        url: "/cms_pages/?user_type=Docs-API&cms_status=active",
        noToken: true,
      })
        .then((data) => {
          setCircularLoading(false);
          setPageDetails(data.results);
        })
        .catch((error) => console.log(error));
    }
    invokeApi({
      method: "GET",
      url: "/faq_category/",
      noToken: true
    }).then((data) => setCategory(data.results.filter(el => el.cat_status != "in_active")))
      .catch((error) => console.log(error));
  }, [customer, researcher, apis]);

  useEffect(() => {
    const subCategoriesMap = {};
    const mainCategoriesMap = {};

    category.forEach((cat) => {
      if (cat.category_type === "sub_category") {
        subCategoriesMap[cat.id] = cat;
      } else if (cat.category_type === "main_category") {
        mainCategoriesMap[cat.id] = {
          ...cat,
          subcategories: [],
        };
      }
    });

    Object.values(subCategoriesMap).forEach((subCat) => {
      if (mainCategoriesMap[subCat.parent_category_id]) {
        mainCategoriesMap[subCat.parent_category_id].subcategories.push({
          ...subCat,
          pages: [],
        });
      }
    });

    pageDetails.forEach((page) => {
      const subCategory = subCategoriesMap[page.sub_category_id];
      if (subCategory) {
        const mainCategory = mainCategoriesMap[subCategory.parent_category_id];
        if (mainCategory) {
          const subCatIndex = mainCategory.subcategories.findIndex(
            (subCat) => subCat.id === subCategory.id
          );
          if (subCatIndex !== -1) {
            mainCategory.subcategories[subCatIndex].pages.push({
              pageTitle: page.page_title,
              pageContent: page.page_content,
            });
          }
        }
      }
    });

    const transformedArray = Object.values(mainCategoriesMap)
      .filter((mainCat) =>
        mainCat.subcategories.some((subCat) => subCat.pages.length > 0)
      )
      .map((mainCat) => ({
        mainCategory: mainCat.category_title,
        sequence: mainCat.sequence,
        subcategory: mainCat.subcategories
          .filter((subCat) => subCat.pages.length > 0)
          .map((subCat) => ({
            subCategoryTitle: subCat.category_title,
            sequence: subCat.sequence,
            pages: subCat.pages,
          })),
      }));

    setSideBarData(transformedArray);

    if (transformedArray.length > 0) {
      const firstMainCat = transformedArray[0];
      if (firstMainCat.subcategory.length > 0) {
        const firstSubCat = firstMainCat.subcategory[0];
        if (firstSubCat.pages.length > 0) {
          setSelectedPageContent(firstSubCat.pages[0].pageContent);
        }
      }
    }
  }, [pageDetails, category]);

  useEffect(() => {
    setSelectedPageContent("");
  }, [location.pathname]);

  const handlePageClick = (content) => {
    setSelectedPageContent(content);
  };

  return (
    <>
      <Helmet>
        {customer && <title>Customer Documentation: Customer Resources</title>}
        {customer && <meta name="description" content="Customer documentation & explore our comprehensive customer documentation for valuable insights and resources to optimize your experience with Bug Busters Labs." />}


        {researcher && <title>Researcher Documentation: Researcher Resources</title>}
        {researcher && <meta name="description" content="Researcher Documentation find essential resources for researchers at Bug Busters Labs, including guides and documentation to support your cybersecurity efforts." />

        }
        {apis && <title>API Documentation | API Documentation - Bug Busters Labs</title>}
        {apis && <meta name="description" content="API Documentation: Get detailed API documentation from Bug Busters Labs to seamlessly integrate our cybersecurity solutions into your applications." />}
      </Helmet>
      <Header />
      <div className="container researcherDocContainer">
        <div className="row my-4">
          <div className="col-lg-3 sidebar-rec-doc">
            {sideBarData &&
              sideBarData
                .sort((a, b) => a.sequence - b.sequence)
                .map((item, index) => (
                  <div key={index}>
                    <h5 style={{ fontSize: "1rem", lineHeight: 1.2 }}>
                      {item.mainCategory}
                    </h5>
                    <ul>
                      <li>
                        {item?.subcategory
                          ?.sort((a, b) => a.sequence - b.sequence)
                          .map((subCat, subIndex) => (
                            <Accordion
                              key={subIndex}
                              title={subCat.subCategoryTitle}
                              dropdowntitle={
                                subCat.pages &&
                                subCat.pages.map((page, pageIndex) => (
                                  <li key={pageIndex}>
                                    <Link
                                      to="#"
                                      className="mb-2 productElementItem"
                                      onClick={() =>
                                        handlePageClick(page.pageContent)
                                      }
                                    >
                                      {page.pageTitle}
                                    </Link>
                                  </li>
                                ))
                              }
                            />
                          ))}
                      </li>
                    </ul>
                  </div>
                ))}
          </div>
          <div className="col-lg-9 px-5 pageContentDiv">
            {/* <h3 className="text-start">Overview</h3> */}
            {/* <p>{selectedPageContent}</p> */}
            <div dangerouslySetInnerHTML={{ __html: selectedPageContent }} />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
