import React, { useEffect, useState } from "react";
import Dashboard from "../BackEnd/components/Header-Components/Dashboard";
import Management from "../BackEnd/components/Header-Components/Management";
import Payments from "../BackEnd/components/Header-Components/Payments";
import Integrations from "../BackEnd/components/Header-Components/Integrations";
import Reports from "../BackEnd/components/Header-Components/Reports";
import ManageModule from "../BackEnd/components/Header-Components/ManageModule";
import MasterModule from "../BackEnd/components/Header-Components/MasterModule";
import { Route, Routes } from "react-router-dom";
import Resetpassword from "../BackEnd/resetpassword/Resetpassword";
import Maindashboard from "../BackEnd/dashboard/Maindashboard";
import ResearchersDashboard from "../BackEnd/dashboard/researcher/ResearchersDashboard";
import Customerdashboard from "../BackEnd/dashboard/customer/Customerdashboard";
import SubmissionDashboard from "../BackEnd/dashboard/submission/SubmissionDashboard";
import AddUser from "../BackEnd/user/AddUser";
import AddSubmission from "../BackEnd/dashboard/submission/AddSubmission";
import EditSubmission from "../BackEnd/dashboard/submission/EditSubmission";
import RoleAndPermissionDashboard from "../BackEnd/dashboard/roleandpermission/RoleAndPermissionDashboard";
import AddNewRoleAndPermission from "../BackEnd/dashboard/roleandpermission/AddNewRoleAndPermission";
import Editsubadmin from "../BackEnd/editsubadmin/Editsubadmin";
import Basicinfo from "../BackEnd/common/Basicinfo";
import Addskills from "../BackEnd/common/Addskills";
import Social from "../BackEnd/common/Social";
import Editresearcher from "../BackEnd/dashboard/researcher/Editresearcher";
import Userlockscreen from "../BackEnd/userlockscreen/Userlockscreen";
import Addcustomer from "../BackEnd/dashboard/customer/Addcustomer";
import Addresearcher from "../BackEnd/dashboard/researcher/Addresearcher";
import Companydashboard from "../BackEnd/dashboard/company/Companydashboard";
import Addcompany from "../BackEnd/dashboard/company/Addcompany";
import Companyedit from "../BackEnd/dashboard/company/Companyedit";
import Viewprofile from "../BackEnd/dashboard/company/Viewprofile";
import Viewcustomer from "../BackEnd/dashboard/customer/Viewcustomer";
import Viewresearcher from "../BackEnd/dashboard/researcher/Viewresearcher";
import Viewuser from "../BackEnd/dashboard/Viewuser";
import AddProgram from "../BackEnd/dashboard/program/AddProgram";
import EditProgram from "../BackEnd/dashboard/program/EditProgram";
import AdminMainProgramTab from "../BackEnd/ProgramTab/AdminMainProgramTab";
import AdminProgramSummary from "../BackEnd/ProgramTab/AdminProgramSummary";
import AdminProgramResearcher from "../BackEnd/ProgramTab/AdminProgramResearcher";
import AdminProgramSubmission from "../BackEnd/ProgramTab/AdminProgramSubmission";
import AdminProgramRewards from "../BackEnd/ProgramTab/AdminProgramRewards";
import AdminProgramInsights from "../BackEnd/ProgramTab/AdminProgramInsights";
import AdminProgramReports from "../BackEnd/ProgramTab/AdminProgramReports";
import AdminProgramSettings from "../BackEnd/ProgramTab/AdminProgramSettings";
import ProgramDashbord from "../BackEnd/dashboard/program/ProgramDashbord";
import NavigationDashboard from "../BackEnd/dashboard/manage-module/navigation/NavigationDashboard";
import AddNavigation from "../BackEnd/dashboard/manage-module/navigation/AddNavigation";
import CmsPagesDashboard from "../BackEnd/dashboard/manage-module/CmsPages/CmsPagesDashboard";
import AddCmsPage from "../BackEnd/dashboard/manage-module/CmsPages/AddCmsPage";
import EditCmsPage from "../BackEnd/dashboard/manage-module/CmsPages/EditCmsPage";
import BaseValue from "../BackEnd/dashboard/master-module/Base-Value/BaseValue";
import AddBaseValue from "../BackEnd/dashboard/master-module/Base-Value/AddBaseValue";
import EditBaseValue from "../BackEnd/dashboard/master-module/Base-Value/EditBaseValue";
import FaqCategoryDashboard from "../BackEnd/dashboard/master-module/faqCategory/FaqCategoryDashboard";
import AddFaqCategory from "../BackEnd/dashboard/master-module/faqCategory/AddFaqCategory";
import EditFaqCategory from "../BackEnd/dashboard/master-module/faqCategory/EditFaqCategory";
import AddSubCategory from "../BackEnd/dashboard/master-module/faqCategory/AddSubCategory";
import EditFaqSubCategory from "../BackEnd/dashboard/master-module/faqCategory/EditFaqSubCategory";
import Faqa from "../BackEnd/dashboard/master-module/faqQ&A/Faqa";
import EditQA from "../BackEnd/dashboard/master-module/faqQ&A/EditQA";
import EarningDashbord from "../BackEnd/EarningDashboard/EarningDashbord";
import ReportDashboard from "../BackEnd/reports/ReportDashboard";
import BadgesDashboardadd from "../BackEnd/Wallet/BadgesDashboardadd";
import DepartmentDesignation from "../BackEnd/DepartmentDesignation/DepartmentDesignation";
import AddDesignation from "../BackEnd/DepartmentDesignation/AddDesignation";
import WalletDashboard from "../BackEnd/Wallet/WalletDashboard";
import BadgesDashboard from "../BackEnd/Wallet/BadgesDashboard";
import SubadminDashboard from "../BackEnd/dashboard/SubadminDashboard";
import Paymentinfo from "../BackEnd/common/Paymentinfo";
import Editcustomer from "../BackEnd/dashboard/customer/Editcustomer";
import AddQuestion from "../BackEnd/dashboard/master-module/faqQ&A/AddQuestion";
import Header from "../BackEnd/components/Header/Header";
import Footer from "../BackEnd/components/Footer/Footer";
import { useSelector } from "react-redux";
import { getUserInfo } from "../reducers/authSlice";
import { getObjectKeys } from "../utils/helperFunctions";
import EditNavigation from "../BackEnd/dashboard/manage-module/navigation/EditNavigation";
import ProgramTracking from "../BackEnd/common/ProgramTracking";
import ViewBaseVAlue from "../BackEnd/dashboard/master-module/Base-Value/ViewBaseVAlue";
import ViewBadges from "../BackEnd/Wallet/ViewBadges";
import ViewSubmission from "../BackEnd/dashboard/submission/ViewSubmission";
import ViewFaqa from "../BackEnd/dashboard/master-module/faqQ&A/ViewFaqa";
import Verifymail from "../BackEnd/verifymail/Verifymail";
import ViewNavigation from "../BackEnd/dashboard/manage-module/navigation/ViewNavigation";
import ViewCMS from "../BackEnd/dashboard/manage-module/CmsPages/ViewCMS";
import ViewFaqCategory from "../BackEnd/dashboard/master-module/faqCategory/ViewFaqCategory";
import AdminProgramDetails from "../BackEnd/ProgramTab/AdminProgramDetails";
import ProgramReport from "../BackEnd/common/ReportsTypes/ProgramReport";
import ResearcherReport from "../BackEnd/common/ReportsTypes/ResearcherReport";
import SubmisisonReport from "../BackEnd/common/ReportsTypes/SubmissionReport";
import ResearcherProgramReport from "../BackEnd/common/ReportsTypes/ResearcherProgramReport";
import VTxTaxonomy from "../BackEnd/dashboard/master-module/VTxTaxonomy/VTxTaxonomy";
import ViewVTxTaxonomy from "../BackEnd/dashboard/master-module/VTxTaxonomy/ViewVTxTaxonomy";
import EditVTxTaxonomy from "../BackEnd/dashboard/master-module/VTxTaxonomy/EditVTxTaxonomy";
import Slack from "../BackEnd/common/integrations/Slack/Slack";
import Jira from "../BackEnd/common/integrations/Jira/JIra";
import JiraProject from "../BackEnd/common/integrations/Jira/JIraProjects";
import Notifications from "../BackEnd/components/Notifications";
import AddSubCustomer from "../BackEnd/common/supportCustomer/AddSubCustomer";
import SubCustomerDashboard from "../BackEnd/common/supportCustomer/SubCustomerDashboard";
import ViewSubCustomer from "../BackEnd/common/supportCustomer/ViewSubCustomer";
import EditSubCustomer from "../BackEnd/common/supportCustomer/EditSubCustomer";
import KycProfile from "../BackEnd/common/KycProfile";
import AdminPayments from "../BackEnd/dashboard/integrations/payments/AdminPayments";

// {
// 	"name": "Twilio (SMS)",
// 	"link": "#",
// 	"icon": "ni ni-chat-round text-gradient text-primary"
//   },
//   {
// 	"name": "Email",
// 	"link": "#",
// 	"icon": "ni ni-email-83 text-gradient text-primary"
//   },
const AdminLayout = (props) => {
	const { userInfo, permissions } = useSelector(getUserInfo)
	const userType = userInfo.role ? userInfo.role : "admin";

	const permissionBadges = permissions?.find((perm) => perm.module === "Badges");
	const permissionEarnings = permissions?.find((perm) => perm.module === "Earnings & Transactions");
	const permissionWallet = permissions?.find((perm) => perm.module === "Wallet");

	const permissionSlack = permissions?.find((perm) => perm.module === "Integration");
	const permissionJira = permissions?.find((perm) => perm.module === "Integration");

	const permissionReport = permissions?.find((perm) => perm.module === "Reports");

	const permissionCMSPage = permissions?.find((perm) => perm.module === "CMS Pages");
	const permissionNavigation = permissions?.find((perm) => perm.module === "Navigation");

	const permissionBaseValues = permissions?.find((perm) => perm.module === "Base Values");
	const permissionFAQ = permissions?.find((perm) => perm.module === "FAQ Category");
	const permissionFAQCat = permissions?.find((perm) => perm.module === "FAQ Q&A");

	const permissionPrograms = permissions?.find((perm) => perm.module === "Programs");
	const permissionSubmissions = permissions?.find((perm) => perm.module === "Submissions");
	const permissionCustomers = permissions?.find((perm) => perm.module === "Customers");
	const permissionResearchers = permissions?.find((perm) => perm.module === "Researchers");

	const overAllPermissions = {
		'Wallet': permissionWallet,
		'Earnings and Transactions': permissionEarnings,
		'Badges': permissionBadges,

		'Slack Integration': permissionJira,
		'Jira Integration': permissionSlack,

		'Reports Dashboard': permissionReport,

		'CMS Pages': permissionCMSPage,
		'Navigation': permissionNavigation,

		'Base Values': permissionBaseValues,
		'FAQ Category': permissionFAQ,
		'FAQ Q & A': permissionFAQCat,

		'Programs': permissionPrograms,
		'Submissions': permissionSubmissions,
		'Customers': permissionCustomers,
		'Researchers': permissionResearchers,
	}


	return (
		<>
			<Header returnhome="/admin/home">
				<Dashboard Dashboardlink="/admin/home" />
				{(overAllPermissions['Programs']?.view || overAllPermissions['Submissions']?.view ||
					overAllPermissions['Customers']?.view || overAllPermissions['Researchers']?.view ||
					userType === 'admin') && <Management usertype={userType} overAllPermissions={overAllPermissions} />
				}
				{(overAllPermissions['Wallet']?.view || overAllPermissions['Earnings and Transactions']?.view ||
					overAllPermissions['Badges']?.view || userType === 'admin') &&
					<Payments usertype={userType} overAllPermissions={overAllPermissions} />
				}
				{(overAllPermissions['Slack Integration']?.view || overAllPermissions['Jira Integration']?.view ||
					userType === 'admin') &&
					<Integrations usertype={userType} overAllPermissions={overAllPermissions} />
				}
				{(overAllPermissions['Reports Dashboard']?.view || userType === 'admin') &&
					<Reports usertype={userType} overAllPermissions={overAllPermissions} />
				}
				{(overAllPermissions['CMS Pages']?.view || overAllPermissions['Navigation']?.view ||
					userType === 'admin') &&
					<ManageModule usertype={userType} overAllPermissions={overAllPermissions} />
				}
				{(overAllPermissions['Base Values']?.view || overAllPermissions['FAQ Category']?.view ||
					overAllPermissions['FAQ Q & A']?.view || userType === 'admin') &&
					<MasterModule usertype={userType} overAllPermissions={overAllPermissions} />
				}
			</Header>
			<div className="bg-gray-100">
				<main
					className="mt-0 main-content position-relative border-radius-lg "
					style={{ height: "auto" }}
				>
					<Routes>
						<Route path="/notifications" element={<Notifications />} />
						<Route path="/verifymail" element={<Verifymail />} />
						<Route path="/resetpassword" element={<Resetpassword />} />
						<Route path="/home" element={<Maindashboard />} />
						<Route path="/researchers" element={<ResearchersDashboard />} />
						<Route path="/customers" element={<Customerdashboard />} />
						<Route path="/users" element={<SubadminDashboard />} />
						<Route path="/user/add" element={<AddUser />} />
						<Route path="/submissions" element={<SubmissionDashboard />} />
						<Route path="/submissions/new" element={<AddSubmission />} />
						<Route path="/submissions/edit/:id" element={<EditSubmission />} />
						<Route path="/submissions/view/:id" element={<ViewSubmission />} />

						{/* role and permission */}
						<Route
							path="/rolesandpermission"
							element={<RoleAndPermissionDashboard />}
						/>
						<Route
							path="/rolesandpermission/add"
							element={<AddNewRoleAndPermission />}
						/>
						<Route
							path="/rolesandpermission/edit/:id"
							element={<AddNewRoleAndPermission />}
						/>
						{/*  user */}
						<Route exact path="/user/edit/:userId/" element={<Editsubadmin />}>
							<Route path="basicinfo" element={<Basicinfo />} />
							<Route path="addskill" element={<Addskills />} />
							{/* <Route path="paymentinformation"  element={<Paymentinfo />} /> */}
							<Route path="social" element={<Social />} />
						</Route>
						<Route
							exact
							path="/user/edit/:userId/:type"
							element={<Editsubadmin />}
						>
							<Route path="basicinfo" element={<Basicinfo />} />
							<Route path="addskill" element={<Addskills />} />
							{/* <Route path="paymentinformation"  element={<Paymentinfo />} /> */}
							<Route path="social" element={<Social />} />
						</Route>
						{/* researcher */}
						<Route
							exact
							path="/researcher/edit/:userId"
							element={<Editresearcher />}
						>
							<Route path="basicinfo" element={<Basicinfo />} />
							<Route path="addskill" element={<Addskills />} />
							<Route path="paymentinformation" element={<Paymentinfo />} />
							<Route path="social" element={<Social />} />
						</Route>
						<Route
							exact
							path="/customer/edit/:userId"
							element={<Editcustomer />}
						>
							<Route path="basicinfo" element={<Basicinfo />} />
							<Route path="addskill" element={<Addskills />} />
							<Route path="paymentinformation" element={<Paymentinfo />} />
							<Route path="social" element={<Social />} />
							<Route path="kyc" element={<KycProfile />} />
						</Route>
						<Route path="/lockscreen" element={<Userlockscreen />} />
						<Route path="/customer/add" element={<Addcustomer />} />
						<Route path="/researcher/add" element={<Addresearcher />} />
						<Route path="/company/dashboard" element={<Companydashboard />} />
						<Route path="/company/add" element={<Addcompany />} />
						<Route path="/company/edit/:userId" element={<Companyedit />} />
						<Route path="/company/view/:userId" element={<Viewprofile />} />
						<Route path="/customer/view/:userId" element={<Viewcustomer />} />
						<Route
							path="/researcher/view/:userId"
							element={<Viewresearcher />}
						/>
						<Route path="/user/view/:userId" element={<Viewuser />} />
						<Route path="/user/view/:userId/:type" element={<Viewuser />} />
						<Route path="/program/add" element={<AddProgram />} />
						<Route path="/program/edit/:id" element={<EditProgram />} />
						<Route path="/program/:id/" element={<AdminMainProgramTab />}>
							<Route path="summary" element={<AdminProgramSummary />} />
							<Route path="researcher" element={<AdminProgramResearcher />} />
							<Route path="details" element={<AdminProgramDetails />} />
							<Route path="submission" element={<AdminProgramSubmission />} />
							<Route path="rewards" element={<AdminProgramRewards />} />
							<Route path="insights" element={<AdminProgramInsights />} />
							<Route path="reports" element={<AdminProgramReports />} />
							<Route path="settings" element={<AdminProgramSettings />} />
							<Route path="tracking" element={<ProgramTracking />} />
						</Route>
						<Route path="/program/dashboard" element={<ProgramDashbord />} />
						{/* manage module */}
						<Route path="/cms-navigation" element={<NavigationDashboard />} />
						<Route path="/cms-navigation/add" element={<AddNavigation />} />
						<Route
							path="/cms-navigation/view/:id"
							element={<ViewNavigation />}
						/>
						<Route
							path="/cms-navigation/edit/:id"
							element={<EditNavigation />}
						/>
						<Route path="/cms-pages" element={<CmsPagesDashboard />} />
						<Route path="/cms-page/add" element={<AddCmsPage />} />
						<Route path="/cms-page/edit/:id" element={<EditCmsPage />} />
						<Route path="/cms-page/view/:id" element={<ViewCMS />} />

						{/* master module */}
						<Route path="/base-values" element={<BaseValue />} />
						<Route path="/base-value/add" element={<AddBaseValue />} />
						<Route
							exact
							path="/base-value/view/:id"
							element={<ViewBaseVAlue />}
						/>
						<Route
							exact
							path="/base-value/edit/:id"
							element={<EditBaseValue />}
						/>
						<Route path="/faq-category" element={<FaqCategoryDashboard />} />
						<Route path="/faq-category/add" element={<AddFaqCategory />} />
						<Route
							path="/faq-category/edit/:id"
							element={<EditFaqCategory />}
						/>
						<Route
							path="/faq-category/view/:id"
							element={<ViewFaqCategory />}
						/>
						<Route
							path="/faq-sub-category/view/:id"
							element={<ViewFaqCategory />}
						/>
						<Route path="/faq-sub-category/add" element={<AddSubCategory />} />
						<Route
							path="/faq-sub-category/edit/:id"
							element={<EditFaqSubCategory />}
						/>
						<Route path="/faq/dashboard" element={<Faqa />} />
						<Route path="/faq/view/:id" element={<ViewFaqa />} />
						<Route path="/faq/add" element={<AddQuestion />} />
						<Route path="/faq/edit/:id" element={<EditQA />} />
						<Route path="/vtxtaxonomy/dashboard" element={<VTxTaxonomy />} />
						<Route path="/vtxtaxonomy/view/:id" element={<ViewVTxTaxonomy />} />
						<Route path="/vtxtaxonomy/edit/:id" element={<EditVTxTaxonomy />} />
						{/* earning Dashboard */}
						<Route path="/payments/earning" element={<EarningDashbord />} />
						<Route path="/reports-dashboard" element={<ReportDashboard />} />
						<Route path="/reports-dashboard/program/:id" element={<ProgramReport />} />
						<Route path="/reports-dashboard/researcher/:id" element={<ResearcherReport />} />
						<Route path="/reports-dashboard/submission/:id" element={<SubmisisonReport />} />
						<Route path="/reports-dashboard/researcher-program/:researcherId/:programId" element={<ResearcherProgramReport />} />
						{/* wallet */}
						<Route path="/payments/wallet" element={<WalletDashboard />} />
						<Route path="/badges/dashboard" element={<BadgesDashboard />} />
						<Route path="/badges/add" element={<BadgesDashboardadd />} />
						<Route path="/badges/edit/:id" element={<BadgesDashboardadd />} />
						<Route path="/badges/view/:id" element={<ViewBadges />} />
						<Route
							path="/department-designation"
							element={<DepartmentDesignation />}
						/>
						<Route
							path="/department-designation/add-designation"
							element={<AddDesignation />}
						/>
						<Route
							path="/department-designation/edit-designation/:id"
							element={<AddDesignation />}
						/>

						<Route path="/integrations/slack" element={<Slack />} />
						<Route path="/integrations/jira" element={<Jira />} />
						<Route path="/integrations/jira/:cloudName" element={<JiraProject />} />
						<Route path="/integrations/payments" element={<AdminPayments />} />

						<Route path="/supportcustomers" element={<SubCustomerDashboard />} />
						<Route path="/supportcustomers/add" element={<AddSubCustomer />} />
						<Route path="/supportcustomers/view/:userId" element={<ViewSubCustomer />} />
						<Route path="/supportcustomers/edit/:userId" element={<EditSubCustomer />} >
							<Route path="basicinfo" element={<Basicinfo />} />
						</Route>
					</Routes>
				</main>
				<Footer />
			</div>
		</>
	);
};

export default React.memo(AdminLayout);
