import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import faqdata from "./data/faqdata.json";
import { HashLink } from "react-router-hash-link";

import useHttp from "../BackEnd/Hooks/useHttp";

import { toast } from "react-toastify";
import { lineOverFlowDot } from "../utils/helperFunctions";
import { Helmet } from "react-helmet";

export default function Faq() {
  const [openAccordion, setOpenAccordion] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState(
    "59ae24e3-e192-4f25-991f-80770e553d0b"
  );
  const accordionClick = (k) => {
    setOpenAccordion(k);
    setIsOpen(!isOpen);
  };

  const { GetRequest, PutRequest, DeleteRequest } = useHttp();

  const [newfaqdata, setNewfaqdata] = useState();
  // const [error,setError]=useState(null);

  const API = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem("token");

  const fetchFaq = async (stsId) => {
    const { data, error } = await GetRequest(
      API + "faq_questionanswer",
      {},
      {
        // Authorization: "Bearer  " + token,
        // "Content-Type": "multipart/form-data",
        // Authorization: `Bearer ${token}`,
        "Content-Type": "json/HTML",
      }
    );
    if (error) {
      toast.error("Something went wrong!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    } else {
      console.log(data);
      setNewfaqdata(data);
    }
  };

  useEffect(() => {
    fetchFaq();
  }, []);

  return (
    <>
      <Helmet>
        <title>FAQ: Frequently Asked Questions - Bug Busters Labs</title>
        <meta name="description" content="FAQ: Find answers to common questions about Bug Busters Labs' services, programs, and policies in our comprehensive FAQ section." />

        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": [
              {
                "@type": "Question",
                "name": "What is a Bug Bounty Platform?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "<p>A Bug Bounty Platform is an online platform that connects organizations with ethical hackers and security researchers who can identify and report security vulnerabilities in the organization's software, websites, or digital assets.</p>"
                }
              },
              {
                "@type": "Question",
                "name": "How does a Bug Bounty Platform work?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "<p>Bug Bounty Platforms provide a centralized marketplace where organizations can post their bug bounty programs, specify the scope, rewards, and rules, and invite security researchers to participate. Researchers then attempt to find and report vulnerabilities within the specified scope.</p>"
                }
              },
              {
                "@type": "Question",
                "name": "Who can participate in Bug Bounty Platforms?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "<p>Bug Bounty Platforms are open to a diverse range of participants, including independent security researchers, ethical hackers, and cybersecurity professionals. Many platforms have specific requirements and may request background checks or certifications.</p>"
                }
              },
              {
                "@type": "Question",
                "name": "What types of vulnerabilities can I find on a Bug Bounty Platform?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "<p>Bug Bounty Platforms typically host programs that seek various types of security vulnerabilities, such as: Cross-site scripting (XSS), SQL injection, Remote code execution, Privilege escalation, Authentication bypass, Information disclosure.</p>"
                }
              },
              {
                "@type": "Question",
                "name": "How are rewards determined on Bug Bounty Platforms?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "<p>The reward for a reported vulnerability often depends on several factors, including the severity, impact, and exploitability of the vulnerability. Bugbusterslabs Bug Bounty Platform provides guidelines and severity ratings to help assess the rewards.</p>"
                }
              },
              {
                "@type": "Question",
                "name": "What are the steps involved in a penetration testing engagement?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "<p>Planning and scoping, Reconnaissance and information gathering, Vulnerability analysis and assessment, Exploitation and gaining access, Post-exploitation and privilege escalation, Reporting and documentation, Remediation and follow-up.</p>"
                }
              },
              {
                "@type": "Question",
                "name": "How often should penetration testing be performed?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "<p>The frequency of penetration testing depends on various factors, including the organization’s size, industry, regulatory requirements, and the rate of changes to the IT environment. Generally, it is recommended to conduct penetration testing at least once a year, but critical systems or high-risk environments might require more frequent testing.</p>"
                }
              },
              {
                "@type": "Question",
                "name": "Is there a registration process for Bug Bounty Platforms?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "<p>Yes, participants typically need to register on Bugbusterslabs Bug Bounty Platform, provide necessary information, and agree to the platform's terms and conditions. On specific requirements we might also require you to complete a verification process.</p>"
                }
              },
              {
                "@type": "Question",
                "name": "What is the responsible disclosure policy on Bug Bounty Platforms?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "<p>Bugbusterslabs Bug Bounty Platform enforces a responsible disclosure policy, which means you should report vulnerabilities to the organization and follow our guidelines before making any information public. This ensures that issues are addressed before malicious actors can exploit them.</p>"
                }
              },
              {
                "@type": "Question",
                "name": "Can I publicly disclose vulnerabilities I've found on a Bug Bounty Platform?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "<p>In general, Bug Bounty Platforms have specific rules regarding the public disclosure of vulnerabilities found during a program. Always adhere to the platform's guidelines, which may include non-disclosure agreements (NDAs) and embargo periods.</p>"
                }
              },
              {
                "@type": "Question",
                "name": "Are there legal protections for participants on Bug Bounty Platforms?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "<p>Bugbusterslabs Bug Bounty Platform has legal agreements that provide certain protections to participants, such as safe harbor clauses, which ensure that researchers won't be sued for their testing activities within the program's scope. It's crucial to review these legal agreements carefully.</p>"
                }
              },
              {
                "@type": "Question",
                "name": "How can I get started with Bug Bounty Platforms?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "<p>To get started with Bug Bounty Platforms: Sign up on our Bug Bounty Platform, review available programs and their scopes, start learning about common vulnerabilities, test within the program's scope, and report valid vulnerabilities following the program's guidelines.</p>"
                }
              },
              {
                "@type": "Question",
                "name": "Can I dispute a decision made by a Bug Bounty Platform or program?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "<p>If you disagree with a decision made by our Bug Bounty Platform or program, our platform has a dispute resolution process that allows you to discuss the matter and provide additional information for consideration.</p>"
                }
              }
            ]
          })}
        </script>

      </Helmet>
      <Header />
      <section className="faq-tab mt-5">
        <div className="container">
          <div className="fawtab w-70 d-flex mx-auto justify-content-center mt-3">
            <HashLink
              // to="#BugBountyPlatform"
              className="hoverbg"
              onClick={() => {
                setSelectedTab("59ae24e3-e192-4f25-991f-80770e553d0b");
              }}
            >
              <div className="p-4 border border-left-0 d-flex align-items-center">
                <i
                  class="fa-solid fa-lg fa-bug pe-2"
                  style={{ color: "#7f56d9", fontSize: "22px" }}
                ></i>
                Bug Bounty Platform
              </div>
            </HashLink>
            <HashLink
              // to="#PenetrationTesting"
              className="hoverbg"
              onClick={() => {
                setSelectedTab("9b47827c-06b6-4590-a605-87bbd57b8fbe");
              }}
            >
              <div className="p-4 border border-right-0 d-flex align-items-center">
                <i
                  class="fa-solid fa-lg fa-person-chalkboard pe-2"
                  style={{ color: "#7f56d9", fontSize: "22px" }}
                ></i>
                Penetration Testing
              </div>
            </HashLink>
            <HashLink
              // to="#ExternalAttackSurfaceManagement"
              className="hoverbg"
              onClick={() => {
                setSelectedTab("221f29ea-cdd1-4f74-b42b-bf73391bccbc");
              }}
            >
              <div className="p-4 border border-left-0 d-flex align-items-center">
                <i
                  class="fa-solid fa-lg fa-people-roof pe-2"
                  style={{ color: "#7f56d9", fontSize: "22px" }}
                ></i>

                {lineOverFlowDot("External Attack Surface Management", 20)}
              </div>
            </HashLink>
          </div>
        </div>
      </section>

      <section className="uui-section_faq01">
        <div className="uui-page-padding-3">
          <div className="uui-container-large-3">
            <div
              className="uui-padding-vertical-xhuge-2"
              id="PenetrationTesting"
            >
              <div className="uui-text-align-center-3">
                <div className="uui-max-width-large-3 align-center">
                  <div className="uui-heading-subheading-2">FAQ</div>
                  <h2 className="uui-heading-medium-2">
                    {selectedTab == "9b47827c-06b6-4590-a605-87bbd57b8fbe"
                      ? "Penetration Testing"
                      : selectedTab == "221f29ea-cdd1-4f74-b42b-bf73391bccbc"
                        ? "External Attack Surface Management"
                        : "Bug Bounty Platform"}
                  </h2>
                  <div className="uui-space-xsmall-2"></div>
                </div>
              </div>
              {/* <div className="uui-faq01_component">
								<div className="uui-faq01_list">
									{faqdata.penetration.map((item, index) => {
										return (
											<>
												<div
													className="uui-faq01_accordion"
													onClick={() => accordionClick(item.id)}
												>
													<div
														data-w-id="f9ed9c1e-e2e1-3864-2dfb-ec87fdc5e42f"
														className="uui-faq01_question"
													>
														<div className="uui-faq01_heading">
															{item.qs}
															<br />
														</div>
														<div className="uui-faq01_icon-wrapper">
															<div className="accordion-icon_component">
																<div className="accordion-icon_horizontal-line"></div>
																<div
																	className="accordion-icon_vertical-line"
																	style={
																		isOpen === true && openAccordion === item.id
																			? {
																					transform: "rotate(90deg)",
																					transition: ".3s all",
																			  }
																			: { transition: ".3s all" }
																	}
																></div>
															</div>
														</div>
													</div>
													<div
														className={
															isOpen === true && openAccordion === item.id
																? "uui-faq01_answer d-block"
																: "uui-faq01_answer d-none"
														}
													>
														<div className="uui-text-size-medium-3">
															{item.ans}
														</div>
														<div className="uui-space-medium"></div>
													</div>
												</div>
											</>
										);
									})}
								</div>
							</div> */}

              <div className="uui-faq01_component">
                <div className="uui-faq01_list">
                  {newfaqdata &&
                    newfaqdata.results &&
                    newfaqdata.results
                      ?.filter((item) => item.sub_category_id === selectedTab)
                      .sort((a, b) => a.sequence - b.sequence)
                      .map((item, index) => {
                        return (
                          <>
                            <div
                              className="uui-faq01_accordion"
                              onClick={() => accordionClick(item.faq_qa_id)}
                            >
                              <div
                                data-w-id="f9ed9c1e-e2e1-3864-2dfb-ec87fdc5e42f"
                                className="uui-faq01_question"
                              >
                                <div className="uui-faq01_heading">
                                  {item.question}
                                  <br />
                                </div>
                                <div className="uui-faq01_icon-wrapper">
                                  <div className="accordion-icon_component">
                                    <div className="accordion-icon_horizontal-line"></div>
                                    <div
                                      className="accordion-icon_vertical-line"
                                      style={
                                        isOpen === true &&
                                          openAccordion === item.faq_qa_id
                                          ? {
                                            transform: "rotate(90deg)",
                                            transition: ".3s all",
                                          }
                                          : { transition: ".3s all" }
                                      }
                                    ></div>
                                  </div>
                                </div>
                              </div>
                              <div
                                className={
                                  isOpen === true &&
                                    openAccordion === item.faq_qa_id
                                    ? "uui-faq01_answer d-block"
                                    : "uui-faq01_answer d-none"
                                }
                              >
                                <div
                                  className="uui-text-size-medium-3"
                                  dangerouslySetInnerHTML={{
                                    __html: item.answer,
                                  }}
                                ></div>
                                <div className="uui-space-medium"></div>
                              </div>
                            </div>
                          </>
                        );
                      })}
                </div>
              </div>
            </div>

            {/* <div
							className="uui-padding-vertical-xhuge-2"
							id="BugBountyPlatform"
						>
							<div className="uui-text-align-center-3">
								<div className="uui-max-width-large-3 align-center">
									<h2 className="uui-heading-medium-2">Bug Bounty Platform</h2>
									<div className="uui-space-xsmall-2"></div>
								</div>
							</div>
							<div className="uui-faq01_component">
								<div className="uui-faq01_list">
									{faqdata.bugbounty.map((item, index) => {
										return (
											<>
												<div
													className="uui-faq01_accordion accordStyle"
													onClick={() => accordionClick(item.id)}
												>
													<div
														data-w-id="f9ed9c1e-e2e1-3864-2dfb-ec87fdc5e42f"
														className="uui-faq01_question"
													>
														<div className="uui-faq01_heading">
															{item.qs}
															<br />
														</div>
														<div className="uui-faq01_icon-wrapper">
															<div className="accordion-icon_component">
																<div className="accordion-icon_horizontal-line"></div>
																<div
																	className="accordion-icon_vertical-line"
																	style={
																		isOpen === true && openAccordion === item.id
																			? {
																					transform: "rotate(90deg)",
																					transition: ".3s all",
																			  }
																			: { transition: ".3s all" }
																	}
																></div>
															</div>
														</div>
													</div>
													<div
														className={
															isOpen === true && openAccordion === item.id
																? "uui-faq01_answer d-block"
																: "uui-faq01_answer d-none"
														}
													>
														<div className="uui-text-size-medium-3">
															<div
																dangerouslySetInnerHTML={{ __html: item.ans }}
															/>
														</div>
														<div className="uui-space-medium"></div>
													</div>
												</div>
											</>
										);
									})}
								</div>
							</div>
						</div>
						<div
							className="uui-padding-vertical-xhuge-2"
							id="ExternalAttackSurfaceManagement"
						>
							<div className="uui-text-align-center-3">
								<div className="uui-max-width-large-3 align-center">
									<h2 className="uui-heading-medium-2">
										External Attack Surface Management
									</h2>
									<div className="uui-space-xsmall-2"></div>
								</div>
							</div>
							<div className="uui-faq01_component">
								<div className="uui-faq01_list">
									{faqdata.external.map((item, index) => {
										return (
											<>
												<div
													className="uui-faq01_accordion"
													onClick={() => accordionClick(item.id)}
												>
													<div
														data-w-id="f9ed9c1e-e2e1-3864-2dfb-ec87fdc5e42f"
														className="uui-faq01_question"
													>
														<div className="uui-faq01_heading">
															{item.qs}
															<br />
														</div>
														<div className="uui-faq01_icon-wrapper">
															<div className="accordion-icon_component">
																<div className="accordion-icon_horizontal-line"></div>
																<div
																	className="accordion-icon_vertical-line"
																	style={
																		isOpen === true && openAccordion === item.id
																			? {
																					transform: "rotate(90deg)",
																					transition: ".3s all",
																			  }
																			: { transition: ".3s all" }
																	}
																></div>
															</div>
														</div>
													</div>
													<div
														className={
															isOpen === true && openAccordion === item.id
																? "uui-faq01_answer d-block"
																: "uui-faq01_answer d-none"
														}
													>
														<div className="uui-text-size-medium-3">
															<div
																dangerouslySetInnerHTML={{ __html: item.ans }}
															/>
														</div>
														<div className="uui-space-medium"></div>
													</div>
												</div>
											</>
										);
									})}
								</div>
							</div>
						</div> */}
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
