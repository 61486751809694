import React, { useEffect, useState } from "react";
import "./css/Chatbox.css";
import option4 from "./images/option4.png";
import useHttp from "../../BackEnd/Hooks/useHttp";

const getOptions = (type) => {
	switch (type) {
		case "CUSTOMER":
			return [
				{ title: "FAQ", icon: "fa fa-question-circle" },
				{ title: "Report visualisation", icon: "fa-solid fa-chart-column" },
				{ title: "Program Insight", icon: "fa-solid fa-brain" },
			];
		case "RESEARCHER":
			return [
				{ title: "FAQ", icon: "fa fa-question-circle" },
				{ title: "Program Insight", icon: "fa-solid fa-brain" },
			];
		default:
			return [
				{ title: "FAQ", icon: "fa fa-question-circle" },
				{ title: "Contact Sales", icon: "fa-solid fa-phone" },
				{ title: "Support", icon: "fa-solid fa-envelope" },
			];
	}
}

const apiEndpoints = {
	"FAQ": 'bot/faq',
	"Program Insight": 'bot/insight',
	"Report visualisation": 'bot/visualize',
}
const staticMessage = {
	"Contact Sales": "We appreciate your inquiry. To connect with our sales team, please email us at 'sales@bugbusterslabs.com'. One of our representatives will promptly reach out to you.",
	"Support": "We're grateful for your contact. To reach our support team, please send an email to 'support@bugbusterslabs.com'. A representative will promptly assist you."
}

export default function ChatBox({ type }) {
	const API = process.env.REACT_APP_API_URL;
	const [isOpen, setIsOpen] = useState(false);
	// const [openChat, setOpenChat] = useState(true);
	const [isHover, setIsHover] = useState(false);
	const [query, setQuery] = useState("");
	const [preDefinedType, setPreDefinedType] = useState();
	const [isNotificationOpen, setIsNotificationOpen] = useState(false);
	const [queryLoading, setQueryLoading] = useState(false);
	const [chats, setChats] = useState([]);
	const token = localStorage.getItem("token");

	const { PostRequest } = useHttp();

	const sendQuery = async () => {
		setQueryLoading(true);
		const element = document.getElementById("chatBottomMessage");
		setChats((prev) => [
			...prev,
			{
				type: "user",
				message: query,
			},
		]);
		setTimeout(() => {
			element.scrollIntoView();
		}, 10);
		setQuery("");
		let headers = {};
		if (token && ["Program Insight", "Report visualisation"].includes(preDefinedType))
			headers = {
				Authorization: "Bearer  " + token,
				"Content-Type": "multipart/form-data",
			}

		const { data, error } = await PostRequest(`${API}${apiEndpoints[preDefinedType]}/`, {
			query,
		}, headers);
		if (error) {
			console.log(error);
			setChats((prev) => [
				...prev,
				{
					type: "bot",
					message: data?.answer ?? "Please try again later...",
					image_path: data?.image_path,
				},
			]);
			setQueryLoading(false);
		} else {

			setChats((prev) => [
				...prev,
				{
					type: "bot",
					message: data?.answer,
					image_path: data?.image_path,
				},
			]);
			setTimeout(() => {
				element.scrollIntoView();
			}, 10);
			setQueryLoading(false);
		}
	};

	const onKeyDown = (e) => {
		if (e.keyCode === 13) {
			sendQuery();
		}
	};

	const handlePreDefinedtype = (type) => {
		setPreDefinedType(type);
	};

	useEffect(() => {
		if (preDefinedType && ["Contact Sales", "Support"].includes(preDefinedType)) {
			setChats((prev) => [
				...prev,
				{
					type: "user",
					message: preDefinedType,
				},
				{
					type: "bot",
					message: staticMessage[preDefinedType],
				},
			]);
		} else {
			setChats((prev) => [
				...prev,
				{
					type: "user",
					message: preDefinedType,
				},
			]);
		}
	}, [preDefinedType])

	return (
		<div className="ultimateChatbox">
			{isOpen ? (
				<div className="mainChatbox">
					<div
						className={
							isOpen ? "chatBoxFirst  visible" : "chatBoxFirst bt1int "
						}
					>
						<div className="chatBoxFirstTopPart">
							<div className="d-flex align-items-center">
								<div style={{ height: "100%", width: "28%" }}>
									<img src={option4} alt="" />
								</div>
								<div>
									<p
										style={{
											color: "#fff",
											fontWeight: "600",
											marginBottom: "0px",
										}}
									>
										Hi there! <span style={{ fontSize: "25px" }}>👋</span>
									</p>
								</div>
							</div>
							<div className="d-flex align-items-center">
								<div
									onClick={() => setIsNotificationOpen(!isNotificationOpen)}
									className="setNotificationStateBtn"
								>
									<i
										className="fa-xs fa-solid fa-ellipsis-vertical"
										style={{ cursor: "pointer" }}
									></i>
									{isNotificationOpen ? (
										<div
											onClick={() => setIsNotificationOpen(false)}
											className="turnOfNotification"
										>
											<i className="fa-solid fa-bell-slash"></i>
											<span>Turn off notifications</span>
										</div>
									) : (
										""
									)}
								</div>
								<div
									onClick={() => {
										setIsOpen(false);
										// setOpenChat(true);
									}}
									className="minimizeChatBtn"
								>
									<i className="fa-solid fa-chevron-down"></i>
								</div>
							</div>
						</div>

						{/* <div className="chatBoxSecPart">
						<p>We are offline right now, we will get back to you via e-mail:</p>
					</div>
					<div style={{ display: "flex", justifyContent: "center" }}>
						<button className="PrevMsgBtn">
							<div style={{ marginRight: "5px" }}>
								<i className="fa-solid fa-clock-rotate-left"></i>
							</div>
							<div>Previous Messages</div>
						</button>
					</div> */}

						<div className="chatBoxThirdPart">
							<div className="msgTop">
								<div className="botChat">
									<div className="thirdboxFirst ">
										<div className="thirdBoxFirstInrP">
											<p>Hi!👋 What brings you here today?</p>
											<p>We'll be happy to assist you!</p>
										</div>
										{getOptions(type).map((option, index) => (
											<div
												key={"option" + index}
												className="thirdBoxSecInrP buttonPreDefinedType"
												onClick={() => handlePreDefinedtype(option.title)}
											>
												<div>
													<i className={option.icon}></i>
												</div>
												<div>
													<p>{option.title}</p>
												</div>
											</div>
										))}
									</div>
								</div>
								{/* <div className="customerChat">
								<div className="customerChatFst">
									<div>
										<i className="fa-solid fa-phone"></i>
									</div>
									<div>
										<p>Contact Sales</p>
									</div>
								</div>
							</div>
							<div className="botChat">
								<div className="thirdboxFirst ">
									<div className="thirdBoxFirstInrP">
										<p>
											We're glad you're interested in reaching out to our sales
											team. Please take a moment to review the details of our
											available plans beforehand.
										</p>
									</div>

									<div className="thirdBoxSecInrP">
										<div>
											<i className="fa-solid fa-magnifying-glass fa-rotate-90"></i>
										</div>
										<div>
											<p>Pricing details</p>
										</div>
									</div>
									<div className="thirdBoxSecInrP">
										<div>
											<i className="fa-solid fa-phone"></i>
										</div>
										<div>
											<p>Contact Sales</p>
										</div>
									</div>
								</div>
							</div>
							<div className="botChat">
								<div className="thirdboxFirst ">
									<div className="thirdBoxFirstInrP">
										<p>
											The Communicator plan gives you access to our premium
											features that can help you manage communication with your
											customers more efficiently. Subscribing to the
											Communicator plan gives you access to the following:
											<ul>
												<li>Live Typing</li>
												<li>The Visitors' list</li>
												<li>Viewed Pages</li>
												<li>Adding more operators</li>
												<li>Visitors Notes</li>
												<li style={{ listStyle: "none" }}>And much more!</li>
											</ul>
										</p>
									</div>
								</div>
							</div>
							<div className="botChat">
								<div className="thirdboxFirst ">
									<div className="thirdBoxFirstInrP">
										<p>
											Great to hear you're interested in Tidio. Before we start,
											can you provide your details?
											<input
												type="text"
												className="inpNEW"
												placeholder="Name"
											/>
											<input
												type="email"
												className="inpNEW"
												placeholder="Email"
											/>
											<input
												type="text"
												className="inpNEW"
												placeholder="Website URL"
											/>
											<div style={{ marginTop: "10px", display: "flex" }}>
												<input type="checkbox" name="" id="" />
												<div style={{ marginLeft: "6px" }}>
													By checking this box, you agree to receive marketing
													communications from Tidio. We follow our Privacy
													Policy. Opt out anytime.
												</div>
											</div>
										</p>
										<div className="d-flex justify-content-end">
											<button className="InpNEWSubmitBtn">Submit</button>
										</div>
									</div>
								</div>
							</div> */}

								{chats?.map((chat, index) => (
									<div
										key={"chat-" + index}
										className={chat.type === "bot" ? "botChat" : "customerChat"}
									>
										<div
											className={
												chat.type === "bot"
													? "thirdboxFirst"
													: "customerInnerMessage"
											}
										>
											<div
												className={
													chat.type === "bot" ? "thirdBoxFirstInrP" : ""
												}
											>
												{chat.message}

												{
													chat.image_path &&
													<a href={chat.image_path} target="_blank">
														<img src={chat.image_path} width="100%" />
													</a>
												}
											</div>
										</div>
									</div>
								))}
								{queryLoading && (
									<div className="chatbotLoading">
										<div className="dot"></div>
										<div className="dot"></div>
										<div className="dot"></div>
									</div>
								)}
								<div id="chatBottomMessage"></div>
							</div>

							<div className="bottomInpDiv">
								<div>
									{["FAQ", "Program Insight", "Report visualisation"].includes(preDefinedType) && <input
										className="sndMsgInp"
										type="text"
										value={query}
										onChange={(e) => setQuery(e.target.value)}
										placeholder="Enter your message..."
										onKeyDown={onKeyDown}
									/>}
									{/* <div>
									<i className="fa-regular fa-face-smile"></i>
								</div> */}
								</div>
								<div className="d-flex justify-content-center">
									<p className="poweredBy">
										POWERED BY <span>BUGBUSTERSLABS</span>
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			) : (
				""
			)}

			{isOpen === false ? (
				<div
					onClick={() => setIsOpen(true)}
					onMouseEnter={() => setIsHover(true)}
					onMouseLeave={() => setIsHover(false)}
					className="chatMsgIcn"
				>
					{isHover ? (
						<i className="fa-solid fa-pen"></i>
					) : (
						<i className="fa-solid fa-message"></i>
					)}
				</div>
			) : (
				<button
					disabled={query.length === 0}
					onClick={sendQuery}
					onMouseEnter={() => setIsHover(true)}
					onMouseLeave={() => setIsHover(false)}
					className="chatMsgIcn"
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="25px"
						height="25px"
						viewBox="0 0 64 64"
						id="send"
					>
						<g clip-path="url(#a)">
							<path
								d=" M 8.216 36.338 L 26.885 32.604 C 28.552 32.271 28.552 31.729 26.885 31.396 L 8.216 27.662 C 7.104 27.44 6.021 26.356 5.799 25.245 L 2.065 6.576 C 1.731 4.908 2.714 4.133 4.259 4.846 L 61.228 31.139 C 62.257 31.614 62.257 32.386 61.228 32.861 L 4.259 59.154 C 2.714 59.867 1.731 59.092 2.065 57.424 L 5.799 38.755 C 6.021 37.644 7.104 36.56 8.216 36.338 Z "
								style={{ fill: isHover ? "#7f56da" : "white" }}
							/>
						</g>
					</svg>
				</button>
			)}
		</div>
	);
}
