import React from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";
import "./components/css/policy.css";
import { Helmet } from "react-helmet";

export default function TermsConditions() {
	return (
		<>
			<Helmet>
				<title>Terms & Conditions|Terms and Conditions - Bug Busters Labs</title>
				<meta name="description" content="Terms & Conditions: Review the Terms and Conditions for using Bug Busters Labs services, outlining your rights and responsibilities." />
			</Helmet>
			<Header />
			<section className="uui-section_faq01 privacy">
				<div className="uui-page-padding-3">
					<div className="uui-container-large-3">
						<div
							className="uui-padding-vertical-xhuge-3"
							style={{ paddingTop: "3rem" }}
						>
							<div className="uui-text-align-center-3">
								<div className="uui-max-width-large-3 align-center">
									<h2 className="uui-heading-medium-2 privacyPolicyText">
										Terms & Conditions
									</h2>
								</div>

								<div
									class="uui-text-size-large-7 text-start mt-5 mb-4 "
									style={{ color: "#000", fontSize: "16px" }}
								>
									Effective Date: 13-Feb-2024
								</div>
								<h3
									class="uui-heading text-start text-dark"
									style={{ fontSize: "30px", fontWeight: "600" }}
								>
									Acceptance of Terms :
								</h3>
								<div
									class="uui-text-size-large-7 text-start mt-2 mb-4"
									style={{ color: "#000", fontSize: "16px" }}
								>
									These Terms of Service govern your website use at
									<a
										style={{ marginLeft: "4px", marginRight: "4px" }}
										href="https://bugbusterslabs.com"
									>
										https://bugbusterslabs.com
									</a>
									and any related products/services Bugbusterslabs provides.
									<br />
									<br />
									By accessing or using Bugbusterslabs Private Limited (referred
									to as "Oensive Security Service" or "Bugbounty Platform"), you
									are acknowledging and agreeing to abide by these Terms and
									Conditions. If you disagree with any aspect of the Terms,
									please don't use the Service.
								</div>
								<h3
									class="uui-heading text-start text-dark"
									style={{ fontSize: "30px", fontWeight: "600" }}
								>
									Use of the Service:
								</h3>
								<div
									class="uui-text-size-large-7 text-start mt-2 mb-4"
									style={{ color: "#000", fontSize: "16px" }}
								>
									<ul>
										<li>
											To use the Service, you need to be at least 18 years old.
										</li>
										<li>
											Please know that you are responsible for keeping your
											account details confidential.
										</li>
										<li>
											Just so you learn, you'll need to provide precise,
											up-to-date, and thorough information when registering.
										</li>
									</ul>
								</div>
								<h3
									class="uui-heading text-start text-dark"
									style={{ fontSize: "30px", fontWeight: "600" }}
								>
									User Conduct:
								</h3>
								<div
									class="uui-text-size-large-7 text-start mt-2 mb-4"
									style={{ color: "#000", fontSize: "16px" }}
								>
									<ul>
										<li>I just wanted to inform you that</li>
										<li>
											By using the Service, you agree that you aren't
											participating in any unlawful or prohibited activities.
										</li>
										<li>
											It would be best if you refrained from disrupting the
											proper operation of the Service.
										</li>
										<li>
											You are required to adhere to all relevant laws and
											regulations.
										</li>
									</ul>
								</div>
								<h3
									class="uui-heading text-start text-dark"
									style={{ fontSize: "30px", fontWeight: "600" }}
								>
									Intellectual Property:
								</h3>
								<div
									class="uui-text-size-large-7 text-start mt-2 mb-4"
									style={{ color: "#000", fontSize: "16px" }}
								>
									<ul>
										<li>
											The content and materials provided on the Service belong
											to Bugbusterslabs Private Limited and are safeguarded by
											relevant copyright, trademark, and intellectual property
											regulations.
										</li>
									</ul>
								</div>
								<h3
									class="uui-heading text-start text-dark"
									style={{ fontSize: "30px", fontWeight: "600" }}
								>
									Termination:
								</h3>
								<div
									class="uui-text-size-large-7 text-start mt-2 mb-4"
									style={{ color: "#000", fontSize: "16px" }}
								>
									<ul>
										<li>
											Bugbusterslabs Private Limited reserves the right to
											suspend or terminate your account if these Terms are
											violated.
										</li>
									</ul>
								</div>
								<h3
									class="uui-heading text-start text-dark"
									style={{ fontSize: "30px", fontWeight: "600" }}
								>
									Disclaimer of Warranties:
								</h3>
								<div
									class="uui-text-size-large-7 text-start mt-2 mb-4"
									style={{ color: "#000", fontSize: "16px" }}
								>
									<ul>
										<li>
											The service is offered "as is" and "as available" without
											express or implied warranties.
										</li>
									</ul>
								</div>
								<h3
									class="uui-heading text-start text-dark"
									style={{ fontSize: "30px", fontWeight: "600" }}
								>
									Limitation of Liability:
								</h3>
								<div
									class="uui-text-size-large-7 text-start mt-2 mb-4"
									style={{ color: "#000", fontSize: "16px" }}
								>
									<ul>
										<li>
											Bugbusterslabs Private Limited and its affliates,
											directors, officers, employees, and agents are not
											responsible for any direct, indirect, incidental, special,
											or consequential damages that may arise from using or
											being unable to use the Service.
										</li>
									</ul>
								</div>
								<h3
									class="uui-heading text-start text-dark"
									style={{ fontSize: "30px", fontWeight: "600" }}
								>
									Governing Law:
								</h3>
								<div
									class="uui-text-size-large-7 text-start mt-2 mb-4"
									style={{ color: "#000", fontSize: "16px" }}
								>
									<ul>
										<li>
											These Terms are governed by and interpreted under the laws
											of Tamil Nadu, India.
										</li>
									</ul>
								</div>
								<h3
									class="uui-heading text-start text-dark"
									style={{ fontSize: "30px", fontWeight: "600" }}
								>
									Changes to Terms:
								</h3>
								<div
									class="uui-text-size-large-7 text-start mt-2 mb-4"
									style={{ color: "#000", fontSize: "16px" }}
								>
									<ul>
										<li>
											Bugbusterslabs Private Limited reserves the right to
											update or modify these Terms without notice, and such
											changes become effective upon posting on the Service.
										</li>
									</ul>
								</div>
								<h3
									class="uui-heading text-start text-dark"
									style={{ fontSize: "30px", fontWeight: "600" }}
								>
									Contact Information:
								</h3>
								<div
									class="uui-text-size-large-7 text-start mt-2 mb-4"
									style={{ color: "#000", fontSize: "16px" }}
								>
									<ul>
										<li>
											Please don't hesitate to contact
											<a
												style={{ marginLeft: "4px", marginRight: "4px" }}
												href="mailto:legal@bugbusterslabs.com"
											>
												legal@bugbusterslabs.com
											</a>
											with questions about these terms.
										</li>
									</ul>
								</div>

								<div
									class="uui-text-size-large-7 text-start mt-2 mb-4"
									style={{ color: "#000", fontSize: "16px" }}
								>
									By using the Service, I see that you have read, understood,
									and agreed to these Terms and Conditions. Thank you for using
									Bugbusterslabs Private Limited.
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<Footer />
		</>
	);
}
