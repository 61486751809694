import React from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";
import redTeamImg from "./components/images/RedTeaming.png"
import redDiaImg from "./components/images/RedTeamDiagram.png"
import { Helmet } from "react-helmet";

export default function RedTeaming() {
  return (
    <>
      <Helmet>
        <title>Advanced Red Teaming Services | Test Your Network For Cyberattack </title>
        <meta name="description" content="Strengthen your defenses with our expert red teaming services, simulating real-world attacks." />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "Product",
            "url": "https://www.bugbusterslabs.com/red-teaming",
            "name": "Red Teaming",
            "description": "Strengthen your defenses with our expert red teaming services, simulating real-world attacks.",
            "review": {
              "@type": "Review",
              "reviewRating": {
                "@type": "Rating",
                "ratingValue": 4,
                "bestRating": 5
              },
              "author": {
                "@type": "Person",
                "name": "Johnson"
              }
            },
            "aggregateRating": {
              "@type": "AggregateRating",
              "ratingValue": 4.6,
              "reviewCount": 571
            }
          })}
        </script>
      </Helmet>
      <Header />
      <div class="uui-page-padding-3">
        <header class="uui-section_heroheader13-3">
          <div class="uui-page-padding-9">
            <header class="uui-section_heroheader05">
              <div class="uui-page-padding-17">
                <div class="uui-container-large-14">
                  <div class="uui-padding-vertical-xhuge-15">
                    <div class="w-layout-grid uui-heroheader05_component">
                      <div class="uui-heroheader05_content">
                        <div class="uui-space-small-11"></div>
                        <h1 class="uui-heading-xlarge-9">Red Teaming</h1>
                        <div class="uui-space-small-11"></div>
                        <div class="uui-text-size-xlarge-9">
                          Emulate
                          <span class="text-span-5">real-world attacks</span> to
                          evaluate
                          <span class="text-span-6">security resilience</span>.
                        </div>
                        <div class="uui-space-large-8"></div>
                      </div>
                      <img
                        src={redTeamImg}
                        loading="lazy"
                        id="w-node-eaad19bd-ed2c-6afb-d93b-b56ad5435669-ad493674"
                        sizes="(max-width: 479px) 100vw, (max-width: 767px) 87vw, (max-width: 991px) 81vw, 33vw"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </header>
            <div class="uui-container-large-8"></div>
          </div>
        </header>
        <header class="uui-section_heroheader01">
          <div class="uui-page-padding-3">
            <div class="uui-container-large-3"></div>
          </div>
        </header>
        <div class="uui-container-large-3"></div>
        <section class="uui-section_layout01">
          <div class="uui-page-padding-5">
            <div class="uui-container-large-5"></div>
          </div>
        </section>
        <section class="uui-section_layout01-2">
          <div class="uui-page-padding-17">
            <section class="uui-section_layout08-2">
              <div class="uui-page-padding-20">
                <div class="uui-container-large-17">
                  <div class="uui-padding-vertical-xhuge-18">
                    <div class="w-layout-grid uui-layout08_component-2">
                      <div class="uui-layout08_content-2">
                        <div class="uui-heading-subheading-12">Benefits</div>
                        <div class="uui-layout08_item-list-2">
                          <div
                            id="w-node-_21805c69-7ff6-4cd4-c442-7485c2e929f9-ad493674"
                            class="uui-layout08_item-2"
                          >
                            <div class="uui-layout08_item-icon-wrapper-2">
                              <div class="uui-icon-1x1-xsmall-11 w-embed">
                                <svg
                                  width="currentWidth"
                                  height="currentHeight"
                                  viewbox="0 0 28 28"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M0 14C0 6.26801 6.26801 0 14 0V0C21.732 0 28 6.26801 28 14V14C28 21.732 21.732 28 14 28V28C6.26801 28 0 21.732 0 14V14Z"
                                    fill="#F4EBFF"
                                  ></path>
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M19.9457 8.62169L11.5923 16.6834L9.37568 14.315C8.96734 13.93 8.32568 13.9067 7.85901 14.2334C7.40401 14.5717 7.27568 15.1667 7.55568 15.645L10.1807 19.915C10.4373 20.3117 10.8807 20.5567 11.3823 20.5567C11.8607 20.5567 12.3157 20.3117 12.5723 19.915C12.9923 19.3667 21.0073 9.81169 21.0073 9.81169C22.0573 8.73836 20.7857 7.79336 19.9457 8.61002V8.62169Z"
                                    fill="#9E77ED"
                                  ></path>
                                </svg>
                              </div>
                            </div>
                            <div class="uui-layout08_item-text-wrapper-2">
                              <div class="uui-text-size-large-11">
                                Uncover hidden vulnerabilities and gaps in
                                security posture
                              </div>
                            </div>
                          </div>
                          <div
                            id="w-node-_21805c69-7ff6-4cd4-c442-7485c2e92a01-ad493674"
                            class="uui-layout08_item-2"
                          >
                            <div class="uui-layout08_item-icon-wrapper-2">
                              <div class="uui-icon-1x1-xsmall-11 w-embed">
                                <svg
                                  width="currentWidth"
                                  height="currentHeight"
                                  viewbox="0 0 28 28"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M0 14C0 6.26801 6.26801 0 14 0V0C21.732 0 28 6.26801 28 14V14C28 21.732 21.732 28 14 28V28C6.26801 28 0 21.732 0 14V14Z"
                                    fill="#F4EBFF"
                                  ></path>
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M19.9457 8.62169L11.5923 16.6834L9.37568 14.315C8.96734 13.93 8.32568 13.9067 7.85901 14.2334C7.40401 14.5717 7.27568 15.1667 7.55568 15.645L10.1807 19.915C10.4373 20.3117 10.8807 20.5567 11.3823 20.5567C11.8607 20.5567 12.3157 20.3117 12.5723 19.915C12.9923 19.3667 21.0073 9.81169 21.0073 9.81169C22.0573 8.73836 20.7857 7.79336 19.9457 8.61002V8.62169Z"
                                    fill="#9E77ED"
                                  ></path>
                                </svg>
                              </div>
                            </div>
                            <div class="uui-layout08_item-text-wrapper-2">
                              <div class="uui-text-size-large-11">
                                Strengthen cybersecurity defenses by
                                implementing targeted enhancements
                              </div>
                            </div>
                          </div>
                          <div
                            id="w-node-_21805c69-7ff6-4cd4-c442-7485c2e92a09-ad493674"
                            class="uui-layout08_item-2"
                          >
                            <div class="uui-layout08_item-icon-wrapper-2">
                              <div class="uui-icon-1x1-xsmall-11 w-embed">
                                <svg
                                  width="currentWidth"
                                  height="currentHeight"
                                  viewbox="0 0 28 28"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M0 14C0 6.26801 6.26801 0 14 0V0C21.732 0 28 6.26801 28 14V14C28 21.732 21.732 28 14 28V28C6.26801 28 0 21.732 0 14V14Z"
                                    fill="#F4EBFF"
                                  ></path>
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M19.9457 8.62169L11.5923 16.6834L9.37568 14.315C8.96734 13.93 8.32568 13.9067 7.85901 14.2334C7.40401 14.5717 7.27568 15.1667 7.55568 15.645L10.1807 19.915C10.4373 20.3117 10.8807 20.5567 11.3823 20.5567C11.8607 20.5567 12.3157 20.3117 12.5723 19.915C12.9923 19.3667 21.0073 9.81169 21.0073 9.81169C22.0573 8.73836 20.7857 7.79336 19.9457 8.61002V8.62169Z"
                                    fill="#9E77ED"
                                  ></path>
                                </svg>
                              </div>
                            </div>
                            <div class="uui-layout08_item-text-wrapper-2">
                              <div class="uui-text-size-large-11">
                                Stay ahead of evolving attack techniques and
                                adapt security strategies
                              </div>
                            </div>
                          </div>
                          <div
                            id="w-node-_21805c69-7ff6-4cd4-c442-7485c2e92a11-ad493674"
                            class="uui-layout08_item-2"
                          >
                            <div class="uui-layout08_item-icon-wrapper-2">
                              <div class="uui-icon-1x1-xsmall-11 w-embed">
                                <svg
                                  width="currentWidth"
                                  height="currentHeight"
                                  viewbox="0 0 28 28"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M0 14C0 6.26801 6.26801 0 14 0V0C21.732 0 28 6.26801 28 14V14C28 21.732 21.732 28 14 28V28C6.26801 28 0 21.732 0 14V14Z"
                                    fill="#F4EBFF"
                                  ></path>
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M19.9457 8.62169L11.5923 16.6834L9.37568 14.315C8.96734 13.93 8.32568 13.9067 7.85901 14.2334C7.40401 14.5717 7.27568 15.1667 7.55568 15.645L10.1807 19.915C10.4373 20.3117 10.8807 20.5567 11.3823 20.5567C11.8607 20.5567 12.3157 20.3117 12.5723 19.915C12.9923 19.3667 21.0073 9.81169 21.0073 9.81169C22.0573 8.73836 20.7857 7.79336 19.9457 8.61002V8.62169Z"
                                    fill="#9E77ED"
                                  ></path>
                                </svg>
                              </div>
                            </div>
                            <div class="uui-layout08_item-text-wrapper-2">
                              <div class="uui-text-size-large-11">
                                Practice incident response procedures for
                                effective real-world breaches
                              </div>
                            </div>
                          </div>
                          <div
                            id="w-node-fc54ff66-7c69-6940-08a7-5f038137cf67-ad493674"
                            class="uui-layout08_item-2"
                          >
                            <div class="uui-layout08_item-icon-wrapper-2">
                              <div class="uui-icon-1x1-xsmall-11 w-embed">
                                <svg
                                  width="currentWidth"
                                  height="currentHeight"
                                  viewbox="0 0 28 28"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M0 14C0 6.26801 6.26801 0 14 0V0C21.732 0 28 6.26801 28 14V14C28 21.732 21.732 28 14 28V28C6.26801 28 0 21.732 0 14V14Z"
                                    fill="#F4EBFF"
                                  ></path>
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M19.9457 8.62169L11.5923 16.6834L9.37568 14.315C8.96734 13.93 8.32568 13.9067 7.85901 14.2334C7.40401 14.5717 7.27568 15.1667 7.55568 15.645L10.1807 19.915C10.4373 20.3117 10.8807 20.5567 11.3823 20.5567C11.8607 20.5567 12.3157 20.3117 12.5723 19.915C12.9923 19.3667 21.0073 9.81169 21.0073 9.81169C22.0573 8.73836 20.7857 7.79336 19.9457 8.61002V8.62169Z"
                                    fill="#9E77ED"
                                  ></path>
                                </svg>
                              </div>
                            </div>
                            <div class="uui-layout08_item-text-wrapper-2">
                              <div class="uui-text-size-large-11">
                                Raise cybersecurity awareness and preparedness
                                among employees
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <img
                        src={redDiaImg}
                        loading="lazy"
                        id="w-node-_5c6fd7de-71f2-778e-b61f-c2d9e96678dc-ad493674"
                        sizes="(max-width: 479px) 100vw, (max-width: 767px) 87vw, (max-width: 991px) 81vw, 38vw"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <div class="uui-container-large-14"></div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
}
