import React from 'react'
import Header from "./components/Header";
import Footer from "./components/Footer";
import "./components/css/bugbusterslabs.webflow.css";
import "./components/css/policy.css"
import { Helmet } from 'react-helmet';

const SecurityPolicy = () => {
	return (
		<>
			<Helmet>
				<title>Security Policy: Bug Busters Labs Security Policy| Your Safety First</title>
				<meta name="description" content="Security Policy: Discover Bug Busters Labs' Security Policy, detailing our commitment to protecting your data and maintaining robust security measures." />
			</Helmet>
			<Header />
			<section className="uui-section_faq01 privacy">
				<div className="uui-page-padding-3">
					<div className="uui-container-large-3">
						<div
							className="uui-padding-vertical-xhuge-3"
							style={{ paddingTop: "3rem" }}
						>
							<div className="uui-text-align-center-3">
								<div className="uui-max-width-large-3 align-center">
									<h2 className="uui-heading-medium-2 privacyPolicyText">
										Security Policy
									</h2>
								</div>
								{/* <div className="uui-space-xsmall-7"></div> */}

								<h3
									className=" text-start text-dark "
									style={{ fontSize: "30px", fontWeight: "500" }}
								>
									Security
								</h3>
								<div
									className="uui-text-size-large-7 text-start mt-2 mb-4"
									style={{ color: "#000", fontSize: "16px" }}
								>
									Bugbusterslabs offers Software as a Service (SaaS) solutions
									globally to address cybersecurity issues for users. Security
									is a fundamental aspect of our services, evident in our team,
									procedures, and products. This section delves into data,
									operational, and physical security to illustrate how we
									deliver top-notch security to our clients.
								</div>
								<h3
									className=" text-start text-dark "
									style={{ fontSize: "30px", fontWeight: "500" }}
								>
									Overview
								</h3>
								<div
									className="uui-text-size-large-7 text-start mt-2 mb-4 "
									style={{ fontSize: "16px", color: "#000" }}
								>
									Our security strategy encompasses the following components:
									<br />
									<br />
									<ul>
										<li>Organizational security</li>
										<li>Physical security</li>
										<li>Infrastructure security</li>
										<li>Data security</li>
										<li>Identity and access control</li>
										<li>Operational security</li>
										<li>Incident management</li>
										<li>Responsible disclosures</li>
										<li>Vendor management</li>
										<li>Customer controls for security</li>
									</ul>
								</div>
								<h3
									className=" text-start text-dark "
									style={{ fontSize: "30px", fontWeight: "500" }}
								>
									Organizational security
								</h3>
								<div
									className="uui-text-size-large-7 text-start mt-2 mb-4 "
									style={{ fontSize: "16px", color: "#000" }}
								>
									We are implementing an Information Security Management System
									(ISMS) that considers our security goals, risks, and solutions
									involving all stakeholders. Our approach includes rigorous
									policies and procedures that cover customer data security,
									availability, processing, integrity, and confidentiality.
								</div>
								<h3
									className=" text-start text-dark "
									style={{ fontSize: "30px", fontWeight: "500" }}
								>
									Employee background checks
								</h3>
								<div
									className="uui-text-size-large-7 text-start mt-2 mb-4 "
									style={{ fontSize: "16px", color: "#000" }}
								>
									Every employee undergoes a background verification process by
									reputable external agencies to confirm their criminal records,
									past employment history, and educational background. Once this
									verification is completed, employees are only assigned tasks
									that could pose user risks.
								</div>
								<h3
									className=" text-start text-dark "
									style={{ fontSize: "30px", fontWeight: "500" }}
								>
									Security Awareness
								</h3>
								<div
									className="uui-text-size-large-7 text-start mt-2 mb-4 "
									style={{ fontSize: "16px", color: "#000" }}
								>
									Each employee signs a confidentiality agreement and an
									acceptable use policy upon joining. They then receive
									information security, privacy, and compliance training.
									Subsequently, their understanding is assessed through tests
									and quizzes to identify areas that require additional
									training. They also receive tailored security training based
									on their roles.
									<br />
									<br />
									We continuously educate our employees on information security,
									privacy, and compliance within our internal community. Regular
									check-ins help keep them informed about the organization's
									security practices. Internal events are also held to promote
									awareness and foster innovation in security and privacy.
								</div>
								<h3
									className=" text-start text-dark "
									style={{ fontSize: "30px", fontWeight: "500" }}
								>
									Dedicated security and privacy teams
								</h3>
								<div
									className="uui-text-size-large-7 text-start mt-2 mb-4 "
									style={{ fontSize: "16px", color: "#000" }}
								>
									We have specialized security and privacy teams responsible for
									overseeing and executing our security and privacy initiatives.
									These teams design and uphold our defense mechanisms,
									establish safety review procedures, and continuously monitor
									our networks for any signs of unusual behavior. Additionally,
									they offer specialized consulting services and support to our
									engineering teams in specific domains.
								</div>
								<h3
									className=" text-start text-dark "
									style={{ fontSize: "30px", fontWeight: "500" }}
								>
									Internal audit and compliance
								</h3>
								<div
									className="uui-text-size-large-7 text-start mt-2 mb-4 "
									style={{ fontSize: "16px", color: "#000" }}
								>
									We are establishing a compliance team at Bugbusterslabs to
									assess procedures and policies and ensure they comply with
									standards. The team will identify necessary controls,
									processes, and systems to meet these standards. Additionally,
									they will conduct regular internal audits and coordinate
									independent audits and third-party assessments.
								</div>
								<h3
									className=" text-start text-dark "
									style={{ fontSize: "30px", fontWeight: "500" }}
								>
									Endpoint security
								</h3>
								<div
									className="uui-text-size-large-7 text-start mt-2 mb-4 "
									style={{ fontSize: "16px", color: "#000" }}
								>
									All workstations provided to Bugbusterslabs sta are equipped
									with the latest operating system versions and anti-virus
									software. Our security guidelines set them up, requiring
									proper configuration, regular updates, and monitoring by
									Bugbusterslabs endpoint management solutions. These
									workstations prioritize security measures by encrypting data,
									utilizing robust passwords, and automatically locking when
									inactive. Additionally, mobile devices used for work are
									integrated into the management system to uphold our security
									protocols.
								</div>

								<h3
									className=" text-start text-dark "
									style={{ fontSize: "30px", fontWeight: "500" }}
								>
									Physical security
								</h3>

								<h3
									className=" text-start text-dark "
									style={{ fontSize: "30px", fontWeight: "500" }}
								>
									At Workplace
								</h3>
								<div
									className="uui-text-size-large-7 text-start mt-2 mb-4 "
									style={{ fontSize: "16px", color: "#000" }}
								>
									We regulate access to our resources, such as buildings,
									infrastructure, and facilities, through access cards.
									Different access cards are assigned to employees,
									contraffictors, vendors, and visitors, permitting access
									tailored to their needs. The Human Resource (HR) team defines
									and upholds role-specific objectives. Access logs are kept to
									detect and resolve any irregularities.
								</div>
								<h3
									className=" text-start text-dark "
									style={{ fontSize: "30px", fontWeight: "500" }}
								>
									Monitoring
								</h3>
								<div
									className="uui-text-size-large-7 text-start mt-2 mb-4 "
									style={{ fontSize: "16px", color: "#000" }}
								>
									We monitor all entry and exit activities across our facilities
									and data centers using CCTV cameras in compliance with local
									regulations. Backup footage is retained for a specific
									duration based on each location's needs.
								</div>
								<h3
									className=" text-start text-dark "
									style={{ fontSize: "30px", fontWeight: "500" }}
								>
									Infrastructure security
								</h3>
								<h3
									className=" text-start text-dark "
									style={{ fontSize: "30px", fontWeight: "500" }}
								>
									Network security
								</h3>
								<div
									className="uui-text-size-large-7 text-start mt-2 mb-4 "
									style={{ fontSize: "16px", color: "#000" }}
								>
									Our network security and monitoring methods are meticulously
									crafted to oer multiple layers of protection.
									<ul>
										<li>
											Firewalls are utilized to block unauthorized access and
											unwanted network traffic.
										</li>
										<li>
											Sensitive data is safeguarded by segregating our systems
											into distinct networks.
										</li>
										<li>
											Development and testing systems are housed separately from
											Bugbusterslabs' production infrastructure.
										</li>
										<li>
											A network engineer regularly and strictly monitors
											firewall access, with daily reviews of any modifications.
										</li>
										<li>
											Bi-annual updates and revisions of rules are conducted to
											ensure the firewall's effectiveness.
										</li>
										<li>
											Our Network Operations Center team diligently monitors the
											infrastructure and applications for anomalies or
											suspicious activities.
										</li>
										<li>
											Our in-house tool monitors essential parameters and
											promptly notifies us of irregular or suspicious activities
											in our production environment.
										</li>
									</ul>
								</div>
								<h3
									className=" text-start text-dark "
									style={{ fontSize: "30px", fontWeight: "500" }}
								>
									Network redundancy
								</h3>
								<div
									className="uui-text-size-large-7 text-start mt-2 mb-4 "
									style={{ fontSize: "16px", color: "#000" }}
								>
									All platform components are redundant, utilizing a distributed
									grid architecture to protect our system and services from
									potential server failures. In the event of a server failure,
									users can continue without disruption, as their data,
									Bugbusterslabs, and Third-party services will remain
									accessible. <br />
									<br />
									Additionally, we implement various switches, routers, and
									security gateways to guarantee redundancy at the device level,
									preventing single points of failure within the internal
									network.
								</div>
								<h3
									className=" text-start text-dark "
									style={{ fontSize: "30px", fontWeight: "500" }}
								>
									DDoS prevention
								</h3>
								<div
									className="uui-text-size-large-7 text-start mt-2 mb-4 "
									style={{ fontSize: "16px", color: "#000" }}
								>
									We utilize technologies from reputable service providers to
									safeguard our servers against DDoS attacks. These technologies
									provide various DDoS mitigation features to deter disruptions
									from malicious traffic while enabling legitimate traffic to
									flow smoothly. As a result, our websites, applications, and
									APIs remain highly accessible and effcient.
								</div>
								<h3
									className=" text-start text-dark "
									style={{ fontSize: "30px", fontWeight: "500" }}
								>
									Server hardening
								</h3>
								<div
									className="uui-text-size-large-7 text-start mt-2 mb-4 "
									style={{ fontSize: "16px", color: "#000" }}
								>
									All servers dedicated to development and testing undergo
									hardening measures such as deactivating unused ports and
									accounts, eliminating default passwords, and more. The
									foundational Operating System (OS) image includes
									pre-installed server hardening features, ensuring uniformity
									across all servers.
								</div>
								<h3
									className=" text-start text-dark "
									style={{ fontSize: "30px", fontWeight: "500" }}
								>
									Intrusion detection and prevention
								</h3>
								<div
									className="uui-text-size-large-7 text-start mt-2 mb-4 "
									style={{ fontSize: "16px", color: "#000" }}
								>
									Our intrusion detection system monitors host-based signals on
									individual devices and network-based signals from monitoring
									points within our servers. It logs administrative access,
									privileged commands, and system calls on all servers in our
									production network. Security engineers receive warnings of
									potential incidents based on rules and machine intelligence
									derived from this data. Additionally, at the application
									layer, our proprietary WAF operates using both whitelist and
									blacklist rules.
									<br />
									<br />A multi-layered security approach is in place at the
									Internet Service Providers (ISP) level. This includes
									scrubbing, network routing, rate limiting, and filtering to
									address attacks from the network to the application layer.
									This comprehensive system ensures clean traffic, a reliable
									proxy service, and swift reporting of any attacks.
								</div>

								<h3
									className=" text-start text-dark "
									style={{ fontSize: "30px", fontWeight: "500" }}
								>
									Data security
								</h3>
								<h3
									className=" text-start text-dark "
									style={{ fontSize: "30px", fontWeight: "500" }}
								>
									Secure by design
								</h3>
								<div
									className="uui-text-size-large-7 text-start mt-2 mb-4 "
									style={{ fontSize: "16px", color: "#000" }}
								>
									All alterations and new additions adhere to a change
									management policy to ensure that all changes to the
									application are authorized before going live. Our Software
									Development Life Cycle (SDLC) mandates implementing secure
									coding practices and scrutinizing code modifications for
									security issues utilizing code analysis tools, vulnerability
									scanners, and reviews by our community of ethical hackers.
									<br />
									<br />
									Based on OWASP standards, our robust security structure is
									embedded in the application layer to mitigate threats such as
									SQL injection, Cross-Site Scripting, and application layer DOS
									attacks.
								</div>
								<h3
									className=" text-start text-dark "
									style={{ fontSize: "30px", fontWeight: "500" }}
								>
									Data isolation
								</h3>
								<div
									className="uui-text-size-large-7 text-start mt-2 mb-4 "
									style={{ fontSize: "16px", color: "#000" }}
								>
									Our framework manages and allocates cloud space for our
									clients. Our secure protocols keep each customer's service
									data isolated from others, ensuring data confidentiality.
									<br />
									<br />
									When you utilize our services, your service data is stored on
									our servers. Remember, your data belongs to you, not
									Bugbusterslabs. Rest assured, we do not disclose this data to
									any third party without your permission.
								</div>
								<h3
									className=" text-start text-dark "
									style={{ fontSize: "30px", fontWeight: "500" }}
								>
									Encryption
								</h3>
								<p
									style={{
										color: "#000",
										fontSize: "16px",
										fontWeight: "600",
										textAlign: "left",
									}}
								>
									In transit:
								</p>
								<div
									className="uui-text-size-large-7 text-start mt-2 mb-4 "
									style={{ fontSize: "16px", color: "#000" }}
								>
									<ul>
										<li>
											We utilize robust encryption protocols to safeguard
											customer data sent to our servers via public networks.
										</li>
										<li>
											All connections to our servers must employ Transport Layer
											Security (TLS 1.2/1.3) encryption with solid ciphers,
											ensuring secure web access, API access, mobile app usage,
											and IMAP/POP/SMTP email client access.
										</li>
										<li>
											This security measure authenticates both parties and
											encrypts the transmitted data, ensuring a secure
											connection.
										</li>
										<li>
											For emails, our services utilize opportunistic TLS by
											default, encrypting and securely delivering emails to
											prevent eavesdropping between mail servers.
										</li>
										<li>
											Our encrypted connections fully support Perfect Forward
											Secrecy (PFS), ensuring that previous communications
											remain encrypted even if compromised in the future.
										</li>
										<li>
											We have implemented the HTTP Strict Transport Security
											header (HSTS) across all web connections, directing modern
											browsers to connect only via secure connections, even if a
											URL leads to an insecure page on our site.
										</li>
										<li>
											Moreover, we designate all authentication cookies on the
											web as secure for added protection.
										</li>
									</ul>
								</div>

								<p
									style={{
										color: "#000",
										fontSize: "16px",
										fontWeight: "600",
										textAlign: "left",
									}}
								>
									At rest:
								</p>

								<div
									className="uui-text-size-large-7 text-start mt-2 mb-4 "
									style={{ fontSize: "16px", color: "#000" }}
								>
									Customer data at rest is safeguarded through 256-bit Advanced
									Encryption Standard (AES). The specific data encrypted at rest
									depends on the services you select. Our in-house Key
									Management Service (KMS) manages and controls the keys. For
									added security, we encrypt the data encryption keys using
									master keys. These master and data encryption keys are stored
									separately in different servers with restricted access.
								</div>

								<h3
									className=" text-start text-dark "
									style={{ fontSize: "30px", fontWeight: "500" }}
								>
									Encryption
								</h3>

								<div
									className="uui-text-size-large-7 text-start mt-2 mb-4 "
									style={{ fontSize: "16px", color: "#000" }}
								>
									We retain your data as long as you use Bugbusterslabs
									Services. When you close your user account, your data will be
									removed from the active database during the next biannual
									clean- up. This deleted data will also be eliminated from
									backups after three months. If your unpaid account remains
									inactive for 90 consecutive days, we may terminate it after
									letting you know in advance and offering you the opportunity
									to back up your data.
									<br />
									<br />A certified and authorized vendor handles the disposal
									of inoperable devices. Before removal, we categorize and
									securely store them. Any data stored on the devices is wiped
									before disposal. Failed hard drives are degaussed and then
									physically shredded. For failed Solid State Devices (SSDs), we
									crypto-erase and shred them.
								</div>

								<h3
									className=" text-start text-dark "
									style={{ fontSize: "30px", fontWeight: "500" }}
								>
									Identity and Access control
								</h3>
								<h3
									className=" text-start text-dark "
									style={{ fontSize: "30px", fontWeight: "500" }}
								>
									Single Sign-On (SSO)
								</h3>
								<div
									className="uui-text-size-large-7 text-start mt-2 mb-4 "
									style={{ fontSize: "16px", color: "#000" }}
								>
									Bugbusterslabs provides a single sign-on (SSO) feature,
									allowing users to access multiple services with one login page
									and authentication details. Users can only sign in to
									Bugbusterslabs services through our integrated Identity and
									Access Management (IAM) system. Additionally, we offer support
									for SAML for single sign-on, enabling customers to link their
									company's identity provider, such as LDAP or ADFS, when
									accessing Bugbusterslabs services.
									<br />
									<br />
									SSO streamlines the login process, ensures compliance, offers
									efficient access control and reporting, and minimizes the risk
									of weak passwords due to password fatigue.
								</div>
								<h3
									className=" text-start text-dark "
									style={{ fontSize: "30px", fontWeight: "500" }}
								>
									Multi-Factor Authentication
								</h3>
								<div
									className="uui-text-size-large-7 text-start mt-2 mb-4 "
									style={{ fontSize: "16px", color: "#000" }}
								>
									Multi-factor authentication adds an extra security level by
									requiring an additional verification that the user needs in
									addition to the password. This can significantly lower the
									chance of unauthorized access if a user's password is exposed.
									Multi-factor authentication can be set up using Zoho One-Auth.
									Currently, it supports modes such as biometric Touch ID or
									Face ID, Push Notification, QR code, and Time-based OTP.
								</div>
								<h3
									className=" text-start text-dark "
									style={{ fontSize: "30px", fontWeight: "500" }}
								>
									Administrative access
								</h3>
								<div
									className="uui-text-size-large-7 text-start mt-2 mb-4 "
									style={{ fontSize: "16px", color: "#000" }}
								>
									We utilize technical access controls and internal protocols to
									prevent unauthorized employee access to user data. Following
									the principles of least privilege and role-based permissions
									helps reduce the chances of data exposure.
									<ul>
										<li>
											Access to production environments is controlled through a
											central directory and verified using a mix of robust
											passwords, two-factor authentication, and
											passphrase-protected SSH keys.
										</li>
										<li>
											Moreover, access is only granted through a separate
											network with stringent regulations and fortified devices.
										</li>
										<li>
											Additionally, all operations are logged and subject to
											regular auditing for monitoring purposes.
										</li>
									</ul>
								</div>
								<h3
									className=" text-start text-dark "
									style={{ fontSize: "30px", fontWeight: "500" }}
								>
									Operational security
								</h3>
								<h3
									className=" text-start text-dark "
									style={{ fontSize: "30px", fontWeight: "500" }}
								>
									Logging and Monitoring
								</h3>
								<div
									className="uui-text-size-large-7 text-start mt-2 mb-4 "
									style={{ fontSize: "16px", color: "#000" }}
								>
									We assess and analyze data from various sources, including
									internal network traffic, device usage, and terminal
									activities. The information is documented in event, audit,
									fault, administrator, and operator logs. These logs are
									automatically monitored and scrutinized to detect
									irregularities like unusual employee account activities or
									unauthorized attempts to view customer data. We securely store
									these logs on a server isolated from full system access to
									ensure centralized access control and availability.
									<br />
									<br />
									Customers using Bugbusterslabs services can access detailed
									audit logs that track all user- performed update and delete
									operations.
								</div>
								<h3
									className=" text-start text-dark "
									style={{ fontSize: "30px", fontWeight: "500" }}
								>
									Vulnerability management
								</h3>
								<div
									className="uui-text-size-large-7 text-start mt-2 mb-4 "
									style={{ fontSize: "16px", color: "#000" }}
								>
									Bugbusterslabs has a dedicated vulnerability management
									process that actively scans for security threats using a
									combination of certified third-party scanning tools, in-house
									tools, automated and manual penetration testing efforts, and
									crowd-sourced ethical hackers to identify and report
									vulnerabilities in our platform. Furthermore, our security
									team actively reviews inbound security reports and monitors
									public mailing lists, blog posts, and wikis to spot security
									incidents that might affect the company's infrastructure.
									<br />
									<br />
									Once a vulnerability needing fixing is pinpointed, we log it,
									prioritize it based on severity, and assign it to a
									responsible individual. We also assess the related risks and
									monitor the vulnerability until it is resolved by patching the
									affected systems or implementing necessary safeguards.
								</div>
								<h3
									className=" text-start text-dark "
									style={{ fontSize: "30px", fontWeight: "500" }}
								>
									Malware and spam protection
								</h3>
								<div
									className="uui-text-size-large-7 text-start mt-2 mb-4 "
									style={{ fontSize: "16px", color: "#000" }}
								>
									All user files are scanned using our automated scanning
									system, specifically built to prevent malware from spreading
									within the Bugbusterslabs ecosystem. Our custom anti-malware
									engine is regularly updated with information from external
									threat intelligence sources, checking files against
									blacklisted signatures and malicious patterns.
									<br />
									<br />
									Once a vulnerability needing fixing is pinpointed, we log it,
									prioritize it based on severity, and assign it to a
									responsible individual. We also assess the related risks and
									monitor the vulnerability until it is resolved by patching the
									affected systems or implementing necessary safeguards.
								</div>
								<h3
									className=" text-start text-dark "
									style={{ fontSize: "30px", fontWeight: "500" }}
								>
									Backup
								</h3>
								<div
									className="uui-text-size-large-7 text-start mt-2 mb-4 "
									style={{ fontSize: "16px", color: "#000" }}
								>
									Every day, our databases undergo incremental and weekly full
									backups using backup tools provided by our Cloud provider to
									transfer the data to our data centers. The backup data is
									stored in the exact location in our data centers and encrypted
									with the AES-256 bit algorithm in tar.gz format. All backed-up
									data is retained for a period of three months. If a customer
									requires data recovery during this time, we will restore their
									data and provide secure access. The time taken for data
									restoration depends on the data's size and the recovery
									process's complexity.
									<br />
									<br />
									We employ a redundant array of independent disks (RAID) in the
									backup servers for added security of our backed-up data. All
									backups are scheduled and monitored regularly. If a failure
									occurs, a re-run is initiated immediately and resolved. The
									integrity and validation checks of the full backups are
									carried out automatically.
								</div>
								<h3
									className=" text-start text-dark "
									style={{ fontSize: "30px", fontWeight: "500" }}
								>
									Disaster recovery and business continuity
								</h3>
								<div
									className="uui-text-size-large-7 text-start mt-2 mb-4 "
									style={{ fontSize: "16px", color: "#000" }}
								>
									Application data is securely stored on robust storage systems
									duplicated across various data centers. The primary data
									center's information is mirrored in real-time at a secondary
									location. Should the primary center encounter an issue, the
									secondary center seamlessly takes over, ensuring operations
									continue without significant delays. Both sites have multiple
									Internet Service Providers for added reliability.
									<br />
									<br />
									We employ physical safeguards like power backups, temperature
									regulation, and fire prevention systems to maintain business
									continuity. These measures enhance our resilience. Apart from
									data redundancy, we have established a business continuity
									plan for critical functions such as support and infrastructure
									management.
								</div>
								<h3
									className=" text-start text-dark "
									style={{ fontSize: "30px", fontWeight: "500" }}
								>
									Incident Management
								</h3>
								<h3
									className=" text-start text-dark "
									style={{ fontSize: "30px", fontWeight: "500" }}
								>
									Reporting
								</h3>
								<div
									className="uui-text-size-large-7 text-start mt-2 mb-4 "
									style={{ fontSize: "16px", color: "#000" }}
								>
									Our dedicated incident management team lets you know of any
									incidents in your environment and the necessary actions to be
									taken. We diligently track and resolve incidents with the
									proper corrective measures. In relevant cases, we'd like to
									gather and provide you with essential evidence in the form of
									application and audit logs related to the incidents.
									Additionally, we establish controls to prevent similar
									situations from happening again.
									<br />
									<br />
									Please feel free to report security or privacy incidents to us
									at incidents@bugbusterslabs.com; we will respond promptly and
									with high priority. General incidents are communicated to
									users through our blogs, forums, and social media. In the case
									of cases specific to individual users or organizations, we let
									the concerned party know via email using the primary email
									address of the Organization administrator registered with us.
								</div>
								<h3
									className=" text-start text-dark "
									style={{ fontSize: "30px", fontWeight: "500" }}
								>
									Breach notification
								</h3>
								<div
									className="uui-text-size-large-7 text-start mt-2 mb-4 "
									style={{ fontSize: "16px", color: "#000" }}
								>
									As data controllers, we comply with the General Data
									Protection Regulation (GDPR) by promptly reporting any
									breaches to the relevant Data Protection Authority within 96
									hours of discovery. Also, based on specific requirements, we
									let our customers know if necessary. As data processors, we
									immediately notify the relevant data controllers without any
									delay.
								</div>
								<h3
									className=" text-start text-dark "
									style={{ fontSize: "30px", fontWeight: "500" }}
								>
									Responsible Disclosures
								</h3>
								<div
									className="uui-text-size-large-7 text-start mt-2 mb-4 "
									style={{ fontSize: "16px", color: "#000" }}
								>
									As a bug bounty platform and service provider, we provide a
									vulnerability reporting program called "Bug Bounty" to engage
									with the community of researchers, acknowledging and
									appreciating the efforts of security researchers. We commit to
									collaborating with the community to validate, replicate,
									address, and apply suitable solutions for the identified
									vulnerabilities.
								</div>
								<h3
									className=" text-start text-dark "
									style={{ fontSize: "30px", fontWeight: "500" }}
								>
									Managing Vendors and Third-Party Suppliers
								</h3>
								<div
									className="uui-text-size-large-7 text-start mt-2 mb-4 "
									style={{ fontSize: "16px", color: "#000" }}
								>
									We assess and approve our vendors according to our vendor
									management policy. When bringing new vendors on board, we
									analyze their service delivery processes and carry out risk
									assessments. To uphold our security standards, we establish
									agreements demanding vendors follow the confidentiality,
									availability, and integrity commitments we promise our
									customers. We regularly review their controls to ensure the
									organization's processes and security measures are operating
									effectively.
								</div>
								<h3
									className=" text-start text-dark "
									style={{ fontSize: "30px", fontWeight: "500" }}
								>
									Empowering Customers Through Security Measures.
								</h3>
								<div
									className="uui-text-size-large-7 text-start mt-2 mb-4 "
									style={{ fontSize: "16px", color: "#000" }}
								>
									Bugbusterslabs outlined customer security measures and
									provided tips for enhancing personal security, including using
									strong passwords, enabling multi-factor authentication,
									updating devices and apps, being cautious with data sharing,
									categorizing information, monitoring account activities, and
									managing roles. Customers are advised to stay alert for
									phishing and malware threats. For more details, you can
									explore Bugbusterslabs' guide on shared responsibility for
									cloud security.
								</div>
								<h3
									className=" text-start text-dark "
									style={{ fontSize: "30px", fontWeight: "500" }}
								>
									Conclusion
								</h3>
								<div
									className="uui-text-size-large-7 text-start mt-2 mb-4 "
									style={{ fontSize: "16px", color: "#000" }}
								>
									Security is a top priority at Bugbusterslabs. If you want more
									information, please refer to FAQs or contact
									security@bugbusterslabs.com.
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<Footer />
		</>
	);
}

export default SecurityPolicy
