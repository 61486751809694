import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { customToast } from '../../../../App';
import { getUserInfo } from '../../../../reducers/authSlice';
import HTTPService from '../../../../utils/axios';
import CircularLoader from '../../../CircularLoader/CircularLoader';
import CommonTable from '../../../common/Table/CommonTable';
import Breadcrumb from '../../../components/breadcrumb/Breadcrumb';
import AppContext from '../../../Context/AppContext';

const Breadcrumbname = {
  home: "/admin/home",
  Integrations: "",
  "Payment Gateways": "/admin/integrations/payments",
};

const tableColumn = [
  { label: "Name", align: 'start' },
  { label: "Status", align: 'center' },
  "Action",
  "Default",
];
const AdminPayments = () => {
  const { invokeApi } = HTTPService();
  const { loading, setLoading } = useContext(AppContext);
  const { userInfo, permissions } = useSelector(getUserInfo);
  const [paymentGateways, setPaymentGateways] = useState({})
  const [totalPages, setTotalPages] = useState(1)
  const [searchValue, setSearchValue] = useState("");
  const [searchPlaceHolder, setSearchPlaceHolder] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [selectedGateway, setSelectedGateway] = useState(null);
  const [action, setAction] = useState("");

  const fetchPaymentGateways = () => {
    setLoading(true);
    invokeApi({
      method: "GET",
      url: `/payment-gateway`,
    }).then(res => {
      setLoading(false);
      setPaymentGateways(res)
      setTotalPages(res.totalPages ?? 1)
    }).catch(error => {
      setLoading(false);
    })
  }

  useEffect(() => {
    if (userInfo?.user_data?.id) {
      fetchPaymentGateways()
    }
  }, [userInfo?.user_data?.id])

  const handleAction = () => {
    const url = `payment-gateway/${selectedGateway.id}/`;

    invokeApi({
      method: "PATCH",
      url,
      data: {
        ...selectedGateway,
        [action === "toggleEnable" ? 'is_enabled' : 'is_default']: !selectedGateway[action === "toggleEnable" ? 'is_enabled' : 'is_default']
      }
    })
      .then(() => {
        fetchPaymentGateways();
        setShowModal(false);
        customToast.success(
          action === "toggleEnable"
            ? `Successfully ${selectedGateway?.is_enabled ? "Disabled" : "Enabled"}!`
            : "Set as Default!",
          { position: toast.POSITION.TOP_RIGHT }
        );
      })
      .catch(() => {
        setShowModal(false);
        customToast.error("Something went wrong!", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  return (
    <div>
      {loading &&
        <CircularLoader size={100} />}
      <div className="container-fluid py-4 pb-0">
        <div className="row">
          <div className="col-lg-6 ps-3">
            <Breadcrumb names={Breadcrumbname} />
          </div>
        </div>

        <div className="row my-4">
          <div className="col-12">
            <CommonTable
              column={tableColumn}
              data={paymentGateways?.results}
              totalPages={totalPages ?? 1}
              itemPerPg={25}
              setTable={1}
              card={7}
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              searchPlaceHolder={searchPlaceHolder}
              setSearchPlaceHolder={setSearchPlaceHolder}
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              searchBy={[]}
              totalData={paymentGateways?.total_records}
            >
              <>
                {paymentGateways?.results &&
                  paymentGateways?.results.map((item, index) => (
                    <tr key={index}>
                      <td className="text-start">
                        <p className="text-sm  cursor-pointer mb-0 px-2" style={{ color: "#5A78F0" }}  >
                          {item.name}
                        </p>
                      </td>
                      <td className="text-center">
                        {item.is_enabled ? "Enabled" : "Disabled"}
                      </td>
                      <td className="text-center">
                        <button
                          className={`btn btn-sm mx-1 ${!item.is_enabled ? "btn-primary bg-primary " : "btn-danger "}`}
                          onClick={() => {
                            setSelectedGateway(item);
                            setAction("toggleEnable");
                            setShowModal(true);
                          }}
                        >
                          {item.is_enabled ? "Disable" : "Enable"}
                        </button>
                      </td>
                      <td>
                        <button
                          className={`btn btn-sm btn-secondary mx-1 ${item.is_default ? "btn-primary bg-primary " : "btn-danger "}`}
                          onClick={() => {
                            setSelectedGateway(item);
                            setAction("setDefault");
                            setShowModal(true);
                          }}
                        >
                          {item.is_default ? "Default" : "Set Default"}
                        </button>
                      </td>
                    </tr>
                  ))}
              </>
            </CommonTable>
          </div>
        </div>
      </div>

      {showModal && (
        <div className="changeStatus" style={{ zIndex: 2 }}>
          <div className="changeStatusBody">
            <div className="changeStatusBodyTop">
              <i className="fa-solid fa-circle-exclamation fa-rotate-180"></i>
              <p>{action === "toggleEnable" ? "Toggle Enable/Disable" : "Set Default"}</p>
            </div>
            <div className="p-4 text-center">
              <p style={{ fontSize: '18px' }}>Are you sure you want to {action === "toggleEnable" ? (selectedGateway?.is_enabled ? "Disable" : "Enable") : "set this as the Default Payment Gateway"}?</p>
            </div>
            <div className="text-center pb-3">
              <button
                onClick={() => setShowModal(false)}
                className="changeStatusBtnClose"
              >
                CLOSE
              </button>
              <button
                onClick={() => handleAction()}
                className="changeStatusBtnSubmit"
              >
                SUBMIT
              </button>
            </div>
          </div>
        </div>
      )}

    </div>
  )
}

export default AdminPayments;