import React, { useEffect, useState } from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";
import "./Program.card.css";
import { toast } from "react-toastify";
import Breadcrumb from "../BackEnd/components/breadcrumb/Breadcrumb";
import { lineOverFlowDot } from "../utils/helperFunctions";
import profile1 from "./Images/alex-suprun-ZHvM3XIOHoE-unsplash.jpg";
import profile2 from "./Images/alexander-hipp-iEEBWgY_6lA-unsplash.jpg";
import profile3 from "./Images/christopher-campbell-rDEOVtE7vOs-unsplash.jpg";
import profile4 from "./Images/jurica-koletic-7YVZYZeITc8-unsplash.jpg";
import logo1 from "./Images/logos/face-logo-8252748_640.png";
import logo2 from "./Images/logos/logo-2078018_640.png";
import logo3 from "./Images/logos/logo-2150297_640.png";
import HTTPService from "../utils/axios";
import ProfileImage from "../components/ProfileImage";
import PrgmSkeletonLoader from "../BackEnd/components/SkeletonLoader/PrgmSkeletonLoader";
import { Helmet } from "react-helmet";

export default function Programs() {
  const [programs, setPrograms] = useState();
  const [loading, setLoading] = useState(false);
  const { invokeApi } = HTTPService();
  const Breadcrumbname = {
    Programs: "/programs",
    BugBounty: "",
  };
  const fetchProgram = () => {
    setLoading(true);
    invokeApi({
      method: "GET",
      url: `programs/`,
      params: {
        program_status: "approved",
      },
    })
      .then((response) => {
        setLoading(false);
        setPrograms(response);
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  useEffect(() => {
    fetchProgram();
  }, []);
  const cards = [
    {
      id: 1,
      program_id: "PRBB098987",
      image: logo1,
      title: "Bug Bounty Demo  Program Title ",
      description:
        "Afterpay Bug Bounty Programe  Keeping Afterpay secure for our customers and merchants",
      status: "Active",
      rate: "$100 - $1000",
    },
  ];
  return (
    <>
      <Helmet>
        <title>Programs | Bug Busters Labs: Cybersecurity Programs for All Needs</title>
        <meta name="description" content="Programs: Discover Bug Busters Labs' innovative cybersecurity programs tailored for businesses and individuals to enhance their security posture." />
      </Helmet>
      <Header />

      <header>
        <div className="header-option">
          <div className="col-6 ps-4">
            <Breadcrumb names={Breadcrumbname} />
          </div>
        </div>
        <div className="underline"></div>
        <div className="frontProgramCards-container">
          {loading && <PrgmSkeletonLoader card={6} />}
          {programs &&
            programs.results &&
            programs.results
              .filter((program) => program.private_program === "false")
              .map((card, index) => (
                <div
                  className="col-3 p-2 responsePrgmCard"
                  key={card.program_id}
                >
                  <div className="frontProgramCard">
                    <div className="row prgmCardItem">
                      <div className="col-3 d-flex justify-content-center align-items-center">
                        <div className="frontPrgmLogo">
                          <ProfileImage src={card.logo} alt="" />
                        </div>
                      </div>
                      <div
                        className="col-9"
                        style={{ alignContent: "center", paddingLeft: 0 }}
                      >
                        <div className="col-12">
                          {/* <p className="PTitle">{lineOverFlowDot(card.program_title,85)}</p> */}
                          <p className="PTitle">{card.program_title}</p>
                        </div>

                        <div className="col-12">
                          <span className="pId">{card.program_id}</span>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div>
                        <span className="pDescriptionText">Description</span>
                      </div>
                      <div>
                        <p className="pDescriptionDetails">
                          <span
                            dangerouslySetInnerHTML={{
                              __html: card.project_description,
                            }}
                          ></span>
                        </p>
                      </div>
                    </div>
                    <div className="row mt-3 prgmCardItem">
                      <div className="col-8 d-flex">
                        <div className="col-4 researcherProfiles">
                          <div className="profileResearcher1">
                            <img src={profile1} alt="" />
                          </div>
                          <div className="profileResearcher2">
                            <img src={profile2} alt="" />
                          </div>
                          <div className="profileResearcher3">
                            <img src={profile3} alt="" />
                          </div>
                          <div className="profileResearcher4">
                            <img src={profile4} alt="" />
                          </div>
                          <div className="profileResearcher5">
                            <span>{card.submission_count}</span>
                          </div>
                        </div>
                        <div style={{ alignContent: "center", paddingLeft: '20px' }}>
                          <span>Responses</span>
                        </div>
                      </div>
                      <div
                        className="col-4"
                        style={{ alignContent: "center", paddingLeft: 0 }}
                      >
                        <div className="pFrontStatus">
                          <span>{card.program_status}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
        </div>
      </header>

      <Footer />
    </>
  );
}
