import React from "react";
import Header from "./components/Header";
import Signup from "./Signup";
import Footer from "./components/Footer";
import { Helmet } from "react-helmet";

const ResearcherSignup = () => {
	return (
		<>
			<Helmet>
				<title>Researcher Singup | AI Sentinel VDP Researcher Login - Bug Busters Labs</title>
			</Helmet>
			<Header />
			<Signup loginLink="/researcher/login" />
			<Footer />
		</>
	);
};

export default ResearcherSignup;
